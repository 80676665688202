<template>
  <div class="tenant-managmement">
    <v-layout row wrap justify-center>
      <v-layout row wrap justify-center>
        <v-flex xs12 sm12 lg10>
          <v-layout class="pt-5" row wrap>
            <v-flex grow pa-1 xs12 sm5 md6>
              <v-layout align-start row wrap class="re-mm-24">
                <v-flex xs12 md4>
                  <h2
                    class="display-2 padding-left"
                    style="margin-inline-start: 0"
                  >
                    {{ $t("Tenant Management") }}
                  </h2>
                </v-flex>
                <v-flex xs12 md8> </v-flex>
              </v-layout>
            </v-flex>
            <v-flex shrink pa-1 xs12 sm7 md6 class="re-rel-pos text-xs-right">
              <v-btn
                v-if="hasPermission('contracts.add_contract')"
                dark
                @click="$router.push({ name: 'add_contract' })"
                class="re-orangtbtn re-ml-4"
              >
                <v-icon>add</v-icon> {{ $t("Add Contract") }}
              </v-btn>
              <v-btn
                v-if="hasPermission('tenants.add_tenant')"
                dark
                class="re-orangtbtn re-ml-4"
                @click="$router.push({ name: 'add_tenant' })"
                ><v-icon>add</v-icon> {{ $t("Add Tenant") }}
              </v-btn>
              <v-btn fab small outline v-on="on">
                <v-icon>history</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
          <v-layout class="re-mm-24">
            <v-flex>
              <v-breadcrumbs :items="breadItemsSetLang()" class="pl-4">
                <template v-slot:divider>
                  <v-icon>fiber_manual_record</v-icon>
                </template>
              </v-breadcrumbs>
            </v-flex>
          </v-layout>
          <table-loader v-if="isLoading" />
          <v-card v-show="!isLoading">
            <v-toolbar class="re-toolbar re-wrap">
              <div class="re-flex">
                {{ $t("Rows Per Page") }}
                <v-radio-group v-model="row" row class="re-radios"
                  ><v-radio :label="$t('All')" value="show-all"></v-radio>
                </v-radio-group>
              </div>
              <div class="re-flex">
                <v-checkbox
                  v-model="allExpanded"
                  :label="$t('Expand')"
                  :false-value="null"
                  :true-value="true"
                />
              </div>
              <v-spacer />

              <v-text-field
                hide-details
                :placeholder="$t('Search')"
                outline
                class="search-field"
                v-model="searchFilter.search"
                single-line
              >
                <template v-slot:prepend>
                  <img src="@/assets/search.svg" />
                </template>
              </v-text-field>
              <div class="text-xs-center">
                <v-menu offset-y>
                  <template v-slot:activator="{ on }">
                    <v-btn dark v-on="on">
                      {{ $t("Actions") }}
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-tile
                      v-for="(action, index) in mainActions"
                      :key="index"
                      @click="handleMainAction(action)"
                      :class="
                        !hasPermission(action.permission) ? 'display-none' : ''
                      "
                    >
                      <v-list-tile-title>
                        <v-icon>{{ action.iconName }}</v-icon>
                        {{ action.title }}
                      </v-list-tile-title>
                    </v-list-tile>
                  </v-list>
                  <input
                    type="file"
                    style="display: none"
                    ref="uploadXLSFile"
                    accept=".xls,.xlsx"
                    @change="handleImportXLSFile"
                  />
                </v-menu>
              </div>
              <contract-list-filter
                v-model="filter"
                filterType="is_tenant"
                @fetchResult="
                  pagination.page = 1;
                  fetchTenants();
                "
              />
            </v-toolbar>
            <v-layout style="overflow: auto" class="container__div">
              <div class="tenant-management__table">
                <v-data-table
                  hide-headers
                  hide-actions
                  :items="tenants"
                  :expand="expand"
                  :item-key="allExpanded ? 'expandId' : 'id'"
                  :no-data-text="$t('No data available')"
                  :headers="headers"
                  ref="dTable"
                >
                  <template v-slot:items="props">
                    <tr
                      :class="{ 'active-class': props.expanded }"
                      class="outer-row"
                    >
                      <td>
                        <div style="width: fit-content">
                          <v-menu offset-y>
                            <template v-slot:activator="{ on }">
                              <img
                                class="actions"
                                v-on="on"
                                src="@/assets/dotedVertical.svg"
                              />
                            </template>
                            <v-list>
                              <v-list-tile
                                v-for="(action, index) in actions"
                                :class="
                                  !hasPermission(action.permission)
                                    ? 'display-none'
                                    : ''
                                "
                                :key="index"
                                @click="
                                  $router.push({
                                    name: action.route,
                                    params: {
                                      id: props.item.id,
                                      tenant_id: props.item.id,
                                      isTenant: true,
                                      ...(action.route == 'add_contract' && {
                                        goBack: true
                                      })
                                    }
                                  })
                                "
                              >
                                <v-list-tile-title>{{
                                  action.title
                                }}</v-list-tile-title>
                              </v-list-tile>
                            </v-list>
                          </v-menu>
                        </div>
                      </td>
                      <td>
                        <span class="icons">
                          <img src="@/assets/contact.svg" />
                        </span>

                        <span>
                          {{
                            $i18n.locale === "ar"
                              ? props.item.tenant_name_ar
                              : props.item.tenant_name_en
                          }}
                        </span>
                      </td>
                      <td>
                        <span>{{ $t("Civil ID") }}</span>
                        <span>
                          {{ props.item.tenant_info.civil_id_number }}
                        </span>
                      </td>
                      <td>
                        <span>{{ $t("Commercial Registration") }}</span>
                        <span>
                          {{
                            props.item.tenant_info
                              .commercial_registration_number || "-"
                          }}
                        </span>
                      </td>
                      <td>
                        <span>{{ $t("ID") }}</span>
                        <span>{{ props.item.id }}</span>
                      </td>
                      <td>
                        <span class="icons">
                          <img src="@/assets/phone.svg" />
                        </span>

                        <span>{{ props.item.tenant_info.phone_number }}</span>
                      </td>
                      <td>
                        <span class="icons">
                          <img src="@/assets/email.svg" />
                        </span>

                        <span>{{ props.item.tenant_info.email }}</span>
                      </td>
                      <td>
                        <span>
                          {{ parseResidentType(props.item.type) }}
                        </span>
                      </td>
                      <td>
                        <button @click="props.expanded = !props.expanded">
                          <img
                            v-if="props.expanded"
                            src="@/assets/downArrow.svg"
                          />
                          <img v-else src="@/assets/upArrow.svg" />
                        </button>
                      </td>
                    </tr>
                  </template>
                  <template v-slot:expand="props">
                    <v-card flat>
                      <v-card-text>
                        <v-data-table
                          :headers="headers"
                          :custom-sort="sortData"
                          :items="props.item.contract_info"
                          :expand="expand"
                          :no-data-text="$t('No data available')"
                          item-key="contract_number"
                          hide-actions
                          class="nested-datatable"
                        >
                          <template v-slot:items="props">
                            <tr>
                              <td class="text-xs-center">
                                {{ props.item.property_name }}
                              </td>
                              <td class="text-xs-center">
                                {{ props.item.units_numbers }}
                              </td>
                              <td class="text-xs-center">
                                {{ props.item.contract_number }}
                              </td>
                              <td class="text-xs-center">
                                {{ props.item.reference || "-" }}
                              </td>
                              <td
                                class="text-xs-center ongoing"
                                :class="{
                                  expired:
                                    props.item.status.includes('termina') ||
                                    props.item.status.includes('Termina') ||
                                    props.item.status.includes('deacti') ||
                                    props.item.status.includes('Deacti')
                                }"
                              >
                                {{ props.item.status }}
                              </td>
                              <td class="text-xs-center">
                                {{
                                  defaultFormatted(props.item.start_date) || "-"
                                }}
                              </td>

                              <td class="text-xs-center">
                                {{
                                  defaultFormatted(props.item.end_date) || "-"
                                }}
                              </td>
                              <td class="text-xs-center">
                                {{ reformatAmount(props.item.amount || 0) }}
                              </td>
                              <td>
                                <v-menu offset-y>
                                  <template v-slot:activator="{ on }">
                                    <img
                                      v-on="on"
                                      class="actions"
                                      src="@/assets/dotedVertical.svg"
                                    />
                                  </template>
                                  <v-list>
                                    <v-list-tile
                                      v-for="(action, index) in actions2"
                                      :disabled="
                                        disableAction(props.item, action)
                                      "
                                      :class="
                                        !hasPermission(action.permission)
                                          ? 'display-none'
                                          : ''
                                      "
                                      :key="index"
                                      @click="
                                        handleNavigation(
                                          action,
                                          props.item,
                                          props
                                        )
                                      "
                                    >
                                      <v-list-tile-title>{{
                                        action.title
                                      }}</v-list-tile-title>
                                    </v-list-tile>
                                  </v-list>
                                </v-menu>
                              </td>

                              <td class="text-xs-center">
                                <button
                                  @click="props.expanded = !props.expanded"
                                >
                                  <img
                                    v-if="props.expanded"
                                    src="@/assets/downArrow.svg"
                                  />
                                  <img v-else src="@/assets/upArrow.svg" />
                                </button>
                              </td>
                            </tr>
                          </template>
                          <template v-slot:expand="props">
                            <v-card flat>
                              <v-card-text>
                                <v-data-table
                                  :headers="headers1"
                                  :items="[props.item]"
                                  :no-data-text="$t('No data available')"
                                  hide-actions
                                  class="most-inner-datatable"
                                >
                                  <template v-slot:items="props">
                                    <tr>
                                      <td class="text-xs-center">
                                        {{
                                          defaultFormatted(
                                            props.item.payment_start_date
                                          ) || "-"
                                        }}
                                      </td>
                                      <td class="text-xs-center">
                                        {{
                                          defaultFormatted(
                                            props.item.termination_date
                                          ) || "-"
                                        }}
                                      </td>
                                      <td class="text-xs-center">
                                        {{
                                          reformatAmount(
                                            props.item.original_amount || 0
                                          )
                                        }}
                                      </td>
                                      <td>
                                        <v-checkbox
                                          class="justify-center"
                                          v-model="props.item.is_suspended"
                                          disabled="true"
                                        />
                                      </td>
                                      <td class="text-xs-center">
                                        {{
                                          reformatAmount(
                                            props.item.insurance_amount || 0
                                          )
                                        }}
                                      </td>
                                      <td class="text-xs-center">
                                        {{
                                          props.item.payment_frequency || "-"
                                        }}
                                      </td>
                                      <td class="text-xs-center">
                                        {{
                                          props.item.advanced_payments_count ||
                                          "-"
                                        }}
                                      </td>
                                      <td>
                                        <v-checkbox
                                          class="justify-center"
                                          v-model="
                                            props.item
                                              .is_payment_links_expiration_enabled
                                          "
                                          :false-value="true"
                                          :true-value="false"
                                          disabled
                                        />
                                      </td>
                                      <td>
                                        <v-checkbox
                                          class="justify-center"
                                          v-model="
                                            props.item
                                              .is_payment_made_in_sequence
                                          "
                                          disabled="true"
                                        />
                                      </td>
                                      <td class="text-xs-center">
                                        <div v-if="!props.item.attachment">
                                          -
                                        </div>
                                        <v-icon
                                          v-else
                                          @click="downloadImage(props.item)"
                                        >
                                          download
                                        </v-icon>
                                      </td>
                                      <td class="text-xs-center">
                                        {{
                                          reformatAmount(
                                            props.item.ob_not_paid_amt || 0
                                          )
                                        }}
                                      </td>
                                      <td class="text-xs-center">
                                        {{ props.item.ob_not_paid_count || 0 }}
                                      </td>
                                    </tr>
                                  </template>
                                </v-data-table>
                              </v-card-text>
                            </v-card>
                            <v-card flat>
                              <v-card-text>
                                <v-data-table
                                  :headers="headers2"
                                  :items="[props.item]"
                                  :no-data-text="$t('No data available')"
                                  hide-actions
                                  class="most-inner-datatable"
                                >
                                  <template v-slot:items="props">
                                    <tr>
                                      <td class="text-xs-center">
                                        {{
                                          reformatAmount(
                                            props.item.ob_paid_amt || 0
                                          )
                                        }}
                                      </td>
                                      <td class="text-xs-center">
                                        {{ props.item.ob_paid_count || 0 }}
                                      </td>
                                      <td class="text-xs-center">
                                        {{
                                          reformatAmount(
                                            props.item.invoice_not_paid_amt || 0
                                          )
                                        }}
                                      </td>
                                      <td class="text-xs-center">
                                        {{
                                          props.item.invoice_not_paid_count || 0
                                        }}
                                      </td>
                                      <td class="text-xs-center">
                                        {{
                                          reformatAmount(
                                            props.item.invoice_paid_amt || 0
                                          )
                                        }}
                                      </td>
                                      <td class="text-xs-center">
                                        {{ props.item.invoice_paid_count || 0 }}
                                      </td>
                                      <td class="text-xs-center">
                                        {{
                                          reformatAmount(
                                            props.item.remaining_invoice_amt ||
                                              0
                                          )
                                        }}
                                      </td>
                                      <td class="text-xs-center">
                                        {{
                                          props.item.remaining_invoice_count ||
                                          0
                                        }}
                                      </td>
                                    </tr>
                                  </template>
                                </v-data-table>
                              </v-card-text>
                            </v-card>
                          </template>
                        </v-data-table>
                      </v-card-text>
                    </v-card>
                  </template>
                </v-data-table>
              </div>
            </v-layout>
          </v-card>
          <div class="overflow-hidden text-xs-center pt-4" v-if="!isLoading">
            <v-pagination
              v-model="pagination.page"
              :length="pages"
              :total-visible="10"
              class="re-pagination"
              @input="fetchTenants(false)"
            />
          </div>
        </v-flex>
      </v-layout>
    </v-layout>
    <invoices-dialogue
      v-model="showInvoiceTab"
      :title="$t('Contract Invoices')"
      :selectedInvoices="selectedContractInvoices"
      :isDialogLoading="invoiceDialog"
      :isUpdatingInvices="isUpdatingInvices"
      :isSelectedPropertySupportsVat="isSelectedPropertySupportsVat"
      @updateInvoices="updateInvoices"
      @closeDialog="closeDialog"
    />
    <InvoiceNotificationListDialogue
      :showDialog="showInvoiceListDialog"
      :isDialogLoading="isLoadingInvoices"
      :invoices="notificationInvoices"
      @acceptDialog="resumeContract()"
      @cancelDialog="resetContractData()"
    />
    <TenantsXlSValidationDialog
      :showDialog="showTenantsXlSDialog"
      :isLoading="isLoadingTenantsXlSFile"
      :notValidRecords="notValidTenantsXLSRecordsList"
      :single-error-message="singleTenantsXLSErrorMessage"
      @closeDialog="closeTenantsXlSDialog()"
      @refetchTenants="fetchTenants()"
    />
    <template>
      <div class="text-xs-center">
        <confirmation-dialog
          v-model="alert"
          :label="$t('Confirmation')"
          :message="$t(`Are you sure that you want to ${action} the contract?`)"
          @acceptDialog="acceptDialog"
          @closeDialog="resetActionDetails"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { isEmpty, debounce } from "lodash";
import Vue from "vue";
import { mapGetters } from "vuex";

import ConfirmationDialog from "../../components/dialog/ConfirmationDialog.vue";
import InvoiceNotificationListDialogue from "../../components/dialog/InvoiceNotificationListDialogue.vue";
import InvoicesDialogue from "../../components/dialog/InvoicesDialogue.vue";
import TenantsXlSValidationDialog from "../../components/dialog/TenantsXlSValidationDialog.vue";
import { ContractEnums } from "../../enums";
import {
  downloadImage,
  getFileExtension,
  defaultFormat,
  reformatAmountWithDecimals,
  reformatInvoicesListWithDecimals,
  formatDateForPicker,
  downloadFile
} from "../../helper";
import { transformContractFilter } from "../../helper/filters";
import contractServices from "../../services/plugins/contract";
import invoiceServices from "../../services/plugins/invoices.js";
import paymentService from "../../services/plugins/payment";
import propertiesService from "../../services/plugins/properties";
import tenantServices from "../../services/plugins/tenant";
import TableLoader from "../../views/loaders/TableLoader.vue";

import breadItemsSetLang from "@/mixins/breadItemsSetLang";
import checkUserPermission from "@/mixins/checkUserPermission";
import paginationMixin from "@/mixins/paginationMixin";
import generatedReport from "@/services/plugins/generated_report";
import ContractListFilter from "@/views/contracts/List/ContractListFilter.vue";
import "@/assets/tenantStyles.css";

export default {
  name: "TenantManagement",
  mixins: [breadItemsSetLang, paginationMixin, checkUserPermission],
  props: ["on"],
  components: {
    TableLoader,
    InvoicesDialogue,
    ContractListFilter,
    InvoiceNotificationListDialogue,
    ConfirmationDialog,
    TenantsXlSValidationDialog
  },
  data() {
    return {
      selectedContract: null,
      dialog: false,
      defaultPageSize: 5,
      row: "",
      expand: false,
      filter: {
        status: "running"
      },
      filterMenu: false,
      loadProperties: null,
      properties: [],
      selectedProperties: "",
      isSuspended: "",
      tenants: [],
      allExpanded: false,
      showInvoiceTab: false,
      contractData: {},
      isLoading: false,
      count: 10,
      isLoadingOptions: false,

      generatedInvoices: [],
      missedInvoices: [],
      openBalanceInvoices: [],
      selectedContractInvoices: [],
      isSelectedPropertySupportsVat: false,
      invoiceDialog: false,
      isDialogLoading: false,
      isLoadingTenantsXlSFile: false,
      isUpdatingInvices: false,
      searchTenants: null,
      notificationInvoices: [],
      notValidTenantsXLSRecordsList: [],
      singleTenantsXLSErrorMessage: "",
      showInvoiceListDialog: false,
      showTenantsXlSDialog: false,
      contractToResume: null,
      action: "",
      alert: false
    };
  },
  watch: {
    "$i18n.locale": function () {
      this.fetchTenants();
    },
    "searchFilter.search": function () {
      this.pagination.page = 1;
      this.searchTenants(true);
    },
    row() {
      this.fetchTenants();
    },
    orderBy() {
      this.fetchTenants();
    },
    allExpanded(val) {
      if (val) {
        this.$set(this.$refs.dTable.expanded, Vue.prototype.$merchant, true);
      } else {
        for (const tenant of this.tenants) {
          this.$set(this.$refs.dTable.expanded, tenant.id, false);
        }
      }
    }
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("Property"),
          value: "property",
          align: "center",
          sortable: false
        },
        {
          text: this.$t("Unit"),
          value: "unit",
          align: "center",
          sortable: false
        },
        {
          text: this.$t("Contract No."),
          value: "contract_no",
          align: "center",
          sortable: false
        },
        {
          text: this.$t("Contract Ref"),
          value: "contract_ref",
          align: "center",
          sortable: false
        },
        {
          text: this.$t("Status"),
          value: "status",
          align: "center",
          sortable: false
        },
        {
          text: this.$t("Start Date"),
          value: "start_date",
          align: "center",
          sortable: false
        },
        {
          text: this.$t("End Date"),
          value: "end_date",
          align: "center",
          sortable: false
        },
        {
          text: this.$t("Paid Amount"),
          value: "paid_amount",
          align: "center",
          sortable: false
        },
        {
          text: "",
          align: "left",
          sortable: false
        },
        {
          text: "",
          align: "left",
          sortable: false
        }
      ];
    },
    headers1() {
      return [
        {
          text: this.$t("Payment Start Date"),
          align: "center",
          sortable: false
        },
        {
          text: this.$t("Termination Date"),
          align: "center",
          sortable: false
        },
        {
          text: this.$t("Original Amount"),
          align: "center",
          sortable: false
        },
        {
          text: this.$t("Is Suspended"),
          align: "center",
          sortable: false
        },
        {
          text: this.$t("Insurance Amount"),
          align: "center",
          sortable: false
        },
        {
          text: this.$t("Payment Period"),
          align: "center",
          sortable: false
        },
        {
          text: this.$t("Advance Payments"),
          align: "center",
          sortable: false
        },
        {
          text: this.$t("No Expiry"),
          align: "center",
          sortable: false
        },
        {
          text: this.$t("Payment in Sequence"),
          align: "center",
          sortable: false
        },
        {
          text: this.$t("Download Link"),
          align: "center",
          sortable: false
        },
        {
          text: this.$t("OB NOT PAID AMOUNT"),
          align: "center",
          sortable: false
        },
        {
          text: this.$t("OB NOT PAID COUNT"),
          align: "center",
          sortable: false
        }
      ];
    },
    headers2() {
      return [
        {
          text: this.$t("OB PAID AMOUNT"),
          align: "center",
          sortable: false
        },
        {
          text: this.$t("OB PAID COUNT"),
          align: "center",
          sortable: false
        },
        {
          text: this.$t("INVOICE NOT PAID AMOUNT"),
          align: "center",
          sortable: false
        },
        {
          text: this.$t("INVOICE NOT PAID COUNT"),
          align: "center",
          sortable: false
        },
        {
          text: this.$t("INVOICE PAID AMOUNT"),
          align: "center",
          sortable: false
        },
        {
          text: this.$t("INVOICE PAID COUNT"),
          align: "center",
          sortable: false
        },
        {
          text: this.$t("REMAINING INVOICE AMOUNT"),
          align: "center",
          sortable: false
        },
        {
          text: this.$t("REMAINING INVOICE COUNT"),
          align: "center",
          sortable: false
        }
      ];
    },
    actions() {
      return [
        {
          title: this.$t("Edit Tenant"),
          route: "edit_tenant",
          permission: "tenants.change_tenant"
        },
        {
          title: this.$t("New Contract"),
          route: "add_contract",
          permission: "contracts.add_contract"
        }
      ];
    },
    actions2() {
      return [
        {
          id: 2,
          title: this.$t("Renew Contract"),
          value: "renew",
          route: "renew_contract",
          permission: "contracts.renew_contract"
        },
        {
          id: 3,
          title: this.$t("Terminate Contract"),
          value: "terminate",
          route: "terminate_contract",
          permission: "contracts.terminate_contract"
        },
        {
          title: this.$t("Manual Payment"),
          value: "manual",
          route: "manual_payment",
          permission: "payments.add_manual_payment"
        },
        {
          title: this.$t("Suspend Contract"),
          value: "suspend",
          permission: "contracts.suspend_contract"
        },
        {
          title: this.$t("Resume Contract"),
          value: "resume",
          permission: "contracts.resume_contract"
        },
        {
          title: this.$t("Modify Contract"),
          value: "modify",
          route: "modify_payment",
          permission: "contracts.change_contract"
        },
        {
          title: this.$t("Invoices"),
          value: "invoice",
          route: "",
          permission: "payments.list_payments_for_contract"
        },
        {
          title: this.$t("Advance Payment"),
          value: "advance",
          route: "advance_payment",
          permission: "payments.add_advance_payment"
        }
      ];
    },
    ...mapGetters({
      isActivated: "config/getIsActivated"
    }),
    mainActions() {
      return [
        {
          id: 1,
          title: this.$t("Print"),
          iconName: "print",
          value: "print",
          permission: "reports.create_contracts_pdf_report"
        },
        {
          id: 2,
          title: this.$t("Download XLS Sample"),
          iconName: "download",
          value: "downloadSample",
          permission: "tenants.download_sample_file_tenantsexcelfile"
        },
        {
          id: 3,
          title: this.$t("Import XLS"),
          iconName: "backup",
          value: "importXLS",
          permission: "tenants.import_tenant_data_tenantsexcelfile"
        }
      ];
    }
  },
  created() {
    this.searchTenants = debounce(this.fetchTenants, 500);
    this.fetchTenants();
  },
  mounted() {
    this.allExpanded = true;
  },
  methods: {
    sortData(items) {
      return items.sort(
        (a, b) =>
          a.property_name.localeCompare(b.property_name) ||
          a.unit_number - b.unit_number
      );
    },
    closeDialog() {
      this.selectedContractInvoices = [];
      this.generatedInvoices = [];
      this.missedInvoices = [];
      this.openBalanceInvoices = [];
      this.isSelectedPropertySupportsVat = false;
    },
    async printTenantContracts() {
      this.isLoading = true;
      try {
        let data = {
          file_format: "pdf",
          tenant: this.filter.tenant,
          property: this.filter.property,
          civil_id: this.filter.civilId,
          reference: this.filter.reference,
          is_suspended: this.filter.is_suspended ? true : undefined,
          status: this.filter.status,
          property_class: this.filter.propertyClassification,
          report_profile: this.filter.profile
        };

        data = {
          ...data,
          ...this.setPaginationParams()
        };

        await this.$processReq(generatedReport.printTenant(data));
        this.isLoading = false;
        this.$root.$emit("snackbar", {
          text: this.$t("PDF is being generated"),
          color: "success"
        });
      } catch (err) {
        this.isLoading = false;
        console.log("Enable to print tenants", err);
      }
    },
    async fetchGeneratedInvoices(contract) {
      try {
        let data = {
          is_open_balance: 0,
          is_missed: 0
        };
        const resp = await this.$processReq(
          invoiceServices.fetchSelectedInvoices(data, contract.contract_number),
          false
        );

        const invoices = resp.data.body || [];
        this.generatedInvoices = reformatInvoicesListWithDecimals(invoices).map(
          (invoice) => {
            return {
              ...invoice,
              is_paid: invoice.status === "Paid" ? true : false,
              payment_date: formatDateForPicker(invoice.payment_date),
              allowed_date: formatDateForPicker(invoice.payment_date),
              bank: invoice.bank?.id || null,
              cash_treasury: invoice.cash_treasury?.id || null,
              is_payment_made_in_sequence: contract.is_payment_made_in_sequence,
              is_blocked: invoice.is_advanced_payment_while_contracting,
              is_generated: true
            };
          }
        );

        this.selectedContractInvoices = [
          ...this.openBalanceInvoices,
          ...this.missedInvoices,
          ...this.generatedInvoices
        ];
      } catch (err) {
        console.log("Enable to fetch normally generated invoices", err);
      }
    },
    async fetchOpenBalanceInvoices(contract) {
      try {
        let data = {
          is_open_balance: 1
        };
        const resp = await this.$processReq(
          invoiceServices.fetchSelectedInvoices(data, contract.contract_number),
          false
        );

        const invoices = resp.data.body || [];
        this.openBalanceInvoices = reformatInvoicesListWithDecimals(
          invoices
        ).map((invoice) => {
          return {
            ...invoice,
            is_paid: invoice.status === "Paid" ? true : false,
            payment_date: formatDateForPicker(invoice.payment_date),
            allowed_date: formatDateForPicker(invoice.payment_date),
            bank: invoice.bank?.id || null,
            cash_treasury: invoice.cash_treasury?.id || null,
            is_payment_made_in_sequence: contract.is_payment_made_in_sequence,
            is_blocked: invoice.is_advanced_payment_while_contracting
          };
        });
      } catch (err) {
        console.log("Enable to fetch open balance invoices", err);
      }
    },
    async fetchMissedInvoices(contract) {
      try {
        let data = {
          is_missed: 1
        };
        const resp = await this.$processReq(
          invoiceServices.fetchSelectedInvoices(data, contract.contract_number),
          false
        );

        const invoices = resp.data.body || [];
        this.missedInvoices = reformatInvoicesListWithDecimals(invoices).map(
          (invoice) => ({
            ...invoice,
            is_paid: invoice.status === "Paid" ? true : false,
            payment_date: formatDateForPicker(invoice.payment_date),
            allowed_date: formatDateForPicker(invoice.payment_date),
            bank: invoice.bank?.id || null,
            cash_treasury: invoice.cash_treasury?.id || null,
            is_payment_made_in_sequence: contract.is_payment_made_in_sequence,
            is_blocked: invoice.is_advanced_payment_while_contracting
          })
        );
      } catch (err) {
        console.log("Enable to fetch missed invoices", err);
      }
    },
    async updateInvoices() {
      this.isUpdatingInvices = true;
      try {
        let invoices = this.selectedContractInvoices.map((invoice) => {
          invoice = {
            ...invoice,
            status: invoice.is_paid ? "Paid" : "Expired"
          };

          delete invoice.is_paid;
          delete invoice.actual_paid_amount;
          delete invoice.allowed_date;
          delete invoice.is_blocked;
          return invoice;
        });

        invoices = invoices.filter((invoice) => !invoice.is_generated);

        const data = {
          reason: ContractEnums.TenantContractInvoice,
          modified_payments: invoices
        };

        const response = await this.$processReq(
          invoiceServices.updateInvoices(data),
          false
        );
        this.$root.$emit("snackbar", {
          text: this.$t(
            `${response.data?.body?.success || "Payments has been updated"}`
          ),
          color: "success"
        });
        this.showInvoiceTab = false;
      } catch (err) {
        this.showInvoiceTab = false;
        console.log("Unable to update invoices invoices", err);
      }
      this.isUpdatingInvices = false;
      this.fetchTenants();
    },
    async handleNavigation(action, item, props) {
      this.isLoading = true;
      if (action.value === "renew" || action.value === "terminate") {
        const param = {
          contract_id: item.contract_number,
          action: action.id
        };
        try {
          await this.$processReq(
            contractServices.fetchContractValidation(param),
            false
          );
        } catch (err) {
          console.log(err, "Error: Unable to fetch contract validation - ");
          this.isLoading = false;
          return;
        }
      }
      if (action.value === "invoice") {
        this.invoiceDialog = true;
        const propertyDetails = await this.fetchPropertyById(item.property_id);
        this.isSelectedPropertySupportsVat =
          propertyDetails.is_vat_enabled || false;
        await this.fetchOpenBalanceInvoices(item);
        await this.fetchMissedInvoices(item);
        await this.fetchGeneratedInvoices(item);
        this.invoiceDialog = false;
        this.showInvoiceTab = true;
      }
      if (action.value === "suspend" || action.value === "resume") {
        this.alert = true;
        this.action = action.value;
        this.contractToResume = item;
        this.isLoading = false;
        return;
      }
      this.isLoading = false;
      if (action.value !== "invoice") {
        this.$router.push({
          name: action.route,
          params: {
            contract_id: item.contract_number,
            property: {
              unit_id: item.unit_id,
              property_id: item.property_id
            },
            tenant_id: props.item.id,
            tenant_name: props.item.tenant_name,
            is_suspended: props.item.is_suspended
          }
        });
      }
    },
    disableAction(item, action) {
      if (item.status === "Terminated" || item.status === "deactivated") {
        if (
          action.value === "invoice" ||
          (action.value === "manual" && item.is_cash_payment_allowed)
        ) {
          return false;
        }
        return true;
      }
      if (item.status === "vacant") {
        return true;
      }

      if (action.value === "modify" && !item.contract_number) {
        return true;
      }

      if (action.value === "manual" && !item.is_cash_payment_allowed) {
        return true;
      }

      if (item.is_suspended && action.value === "suspend") {
        return true;
      }
      if (!item.is_suspended && action.value === "resume") {
        return true;
      }

      if (action.value === "invoice" && !this.isActivated) {
        return true;
      }

      return false;
    },
    async downloadImage(contract) {
      try {
        this.isLoading = true;
        this.dialog = false;
        const resp = await contractServices.downloadImage(contract.attachment);
        downloadImage(
          resp.data,
          `${contract.property_id}-${contract.property_name}-${
            contract.unit_number
          }${getFileExtension(resp.data.type)}`
        );
        this.$root.$emit("snackbar", {
          text: this.$t("Image have been downloaded successfully"),
          color: "success"
        });
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log("Error: Unable to download image - ", error);
      }
    },
    async fetchContractById(contract_id) {
      try {
        const res = await this.$processReq(
          contractServices.fetchContractById(contract_id),
          false
        );

        const result = res.data.body;
        return {
          ...result,
          property: result.property.id,
          tenant: result.tenant.id
        };
      } catch (err) {
        console.log("Error: Unable to fetch contract by ID - ", err);
      }
    },
    async fetchTenants(blockLoading) {
      try {
        this.filterMenu = false;
        if (!blockLoading) {
          this.isLoading = true;
        }
        let params = {
          property_id: this.filter.property,
          civil_id: this.filter.civilId || null,
          property_class: this.filter.propertyClassification,
          report_profile: this.filter.profile,
          status: this.filter.status
        };

        params = {
          ...params,
          ...this.setPaginationParams()
        };

        // For reseting the status filter to the default value
        if (!Object.prototype.hasOwnProperty.call(this.filter, "status")) {
          this.filter.status = "running";
        }

        if (!isEmpty(this.filter)) {
          params = {
            ...params,
            ...transformContractFilter(this.filter)
          };
        }

        delete params.property;

        const response = await this.$processReq(
          tenantServices.getAuthorizedTenantList(params),
          false
        );

        this.tenants = response.data.body.results;
        this.tenants = this.tenants.map((tenant) => {
          return {
            ...tenant,
            expandId: Vue.prototype.$merchant
          };
        });
        this.isLoading = false;

        this.setPagination(response.data.body.count);

        this.showAllPagination();
      } catch (err) {
        this.isLoading = false;
        // this.$root.$emit("snackbar", {
        //   text: this.$t("Something went wrong while fetching tenants"),
        //   color: "error"
        // });
        console.log("Error: Unable to get tenant list - ", err);
      }
    },
    resetFilters() {
      this.filterMenu = false;
      this.selectedProperties = "";
      this.isSuspended = "";
      this.fetchTenants();
    },
    parseResidentType(type) {
      if (type === "RESIDENTIAL") {
        return this.$t("Residential");
      } else if (type === "LIMITED_LIABILITY_COMPANY") {
        return this.$t("Limited Liability Company");
      } else if (type === "LISTED_COMPANY") {
        return this.$t("Listed Company");
      }
    },
    async getInvoiceNotificationList(contract) {
      try {
        const params = {
          is_notifiable: 1
        };
        const resp = await this.$processReq(
          paymentService.getContractPayments(contract.contract_number, params),
          false
        );

        this.notificationInvoices = reformatInvoicesListWithDecimals(
          resp.data.body || []
        );
        this.isLoading = false;
        if (this.notificationInvoices?.length) {
          this.showInvoiceListDialog = true;
        } else {
          this.resumeContract();
        }
      } catch (error) {
        console.log("Unable to fetch notifiable payments", error);
      }
    },
    resetContractData() {
      this.showInvoiceListDialog = false;
      this.selectedContract = null;
    },
    async resumeContract() {
      this.isLoading = true;
      this.showInvoiceListDialog = false;
      try {
        await this.$processReq(
          contractServices.resumeContract(this.selectedContract)
        );
        this.fetchTenants();
        this.$root.$emit("snackbar", {
          text: this.$t("Contract has been resumed"),
          color: "success"
        });
      } catch (error) {
        console.log("Error: Unable to resume contract - ", error);
      }
      this.selectedContract = null;
      this.isLoading = false;
    },
    async acceptDialog() {
      try {
        this.isLoading = true;
        if (this.contractToResume.is_suspended && this.action === "resume") {
          if (
            this.contractToResume.allowed_pyament_methodes?.includes(
              "payment link"
            )
          ) {
            this.getInvoiceNotificationList(this.contractToResume);
            this.selectedContract = this.contractToResume.contract_number;
            return;
          }
          await this.$processReq(
            contractServices.resumeContract(
              this.contractToResume.contract_number
            )
          );
        } else if (!this.contractToResume.is_suspended) {
          await this.$processReq(
            contractServices.suspendContract(
              this.contractToResume.contract_number
            )
          );
        } else {
          this.$root.$emit("snackbar", {
            text: this.$t(
              `You cannot suspend this contract as it is with the status`,
              { status: this.contractToResume.status }
            ),
            color: "error"
          });
          this.isLoading = false;
          return;
        }
        this.fetchTenants();
        this.$root.$emit("snackbar", {
          text: this.$t(
            `Contract has been ${
              this.action === "suspend" ? "suspended" : "resumed"
            }`
          ),
          color: "success"
        });
        return;
      } catch (error) {
        console.log("Error: Unable to suspend contract - ", error);
      }
      this.resetActionDetails();
      this.isLoading = false;
    },
    resetActionDetails() {
      this.alert = false;
      this.action = "";
      this.contractToResume = null;
    },
    defaultFormatted(value) {
      return defaultFormat(value);
    },
    reformatAmount(amount) {
      return reformatAmountWithDecimals(amount);
    },
    async fetchPropertyById(id) {
      try {
        const response = await this.$processReq(
          propertiesService.getPropertyDetails(id),
          false
        );

        return response.data.body;
      } catch (err) {
        console.log("Error: Unable to fetch property by ID - ", err);
      }
    },
    closeTenantsXlSDialog() {
      this.showTenantsXlSDialog = false;
      this.isLoadingTenantsXlSFile = false;
      this.notValidTenantsXLSRecordsList = [];
      this.singleTenantsXLSErrorMessage = "";
      this.$refs.uploadXLSFile.value = "";
    },
    reshapeNotValidTenantsXLSRecords(ob) {
      let list = [];
      if (ob) {
        list = Object.entries(ob).map(([key, value]) => {
          return {
            record_number: key,
            ...value.row_data,
            errors: value.errors
          };
        });
      }
      return list;
    },
    async downloadTenantsXLSSample() {
      try {
        this.isLoading = true;
        const resp = await this.$processReqSilent(
          tenantServices.downloadTenantsXLSSample()
        );
        let fileName = "Sample file";
        downloadFile(resp.data, resp.data.type, fileName);
        this.isLoading = false;
        this.$root.$emit("snackbar", {
          text: this.$t("XLS file downloaded succesfully"),
          color: "success"
        });
      } catch (err) {
        this.isLoading = false;
        this.$root.$emit("snackbar", {
          text: this.$t("Something went wrong while downloading XLS file"),
          color: "error"
        });
        console.log(err, "Error: downloading XLS sample file");
      }
    },
    async handleImportXLSFile(e) {
      if (e.target.files.length) {
        let selectedFile = e.target.files[0];
        let validFilesTypes = ["xls", "xlsx"];
        let extension = selectedFile.name
          .slice(selectedFile.name.lastIndexOf(".") + 1)
          .toLowerCase();
        if (validFilesTypes.includes(extension)) {
          const formData = new FormData();
          formData.append("file", selectedFile);
          try {
            this.isLoadingTenantsXlSFile = true;
            this.showTenantsXlSDialog = true;
            await this.$processReq(
              tenantServices.uploadTenantsXLSFile(formData),
              false
            );
            this.isLoadingTenantsXlSFile = false;
            this.$root.$emit("snackbar", {
              text: this.$t("File Uploaded Successfully"),
              color: "success"
            });
          } catch (err) {
            this.isLoadingTenantsXlSFile = false;
            // Checking bad request error cases
            if (
              err.response?.status === 400 &&
              err.response.data?.errors &&
              Array.isArray(err.response.data.errors)
            ) {
              const errors = err.response.data.errors;
              /*  Checking error type whether it's structure, extention, or invalid values
               ** Based on the length of 'errors' array
               */
              if (errors.length > 1) {
                // Error type is invalid values
                let recordsElement = errors.find(
                  (el) => el.field === "errors_per_row"
                );
                if (recordsElement) {
                  this.notValidTenantsXLSRecordsList =
                    this.reshapeNotValidTenantsXLSRecords(
                      recordsElement.field_error
                    );
                }
              } else {
                // Error type might be either structure or extention, but with single message
                this.singleTenantsXLSErrorMessage = errors[0].field_error;
              }
            }
          }
        }
      }
    },
    handleMainAction(action) {
      if (action.value === "print") {
        this.printTenantContracts();
      }
      if (action.value === "downloadSample") {
        this.downloadTenantsXLSSample();
      }
      if (action.value === "importXLS") {
        this.$refs.uploadXLSFile.click();
      }
    }
  }
};
</script>
<style scoped>
::v-deep .v-btn .v-btn__content .v-icon {
  color: inherit !important;
}
::v-deep thead th:not(:first-child) {
  padding: 0px !important;
}
::v-deep .most-inner-datatable thead th {
  padding: 0 2px !important;
  font-size: 11px !important;
}
</style>
