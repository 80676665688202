<template>
  <v-layout wrap justify-center>
    <v-layout wrap justify-center>
      <v-flex xs12 sm12 lg10>
        <v-layout class="pt-5" wrap>
          <v-flex grow pa-1 xs10 sm7 md8>
            <v-layout align-start wrap>
              <h2 class="display-2 pl-4">{{ $t("Maintenance Tickets") }}</h2>
            </v-layout>
          </v-flex>
          <v-flex shrink pa-1 xs2 sm5 md4 class="re-rel-pos">
            <v-layout justify-end>
              <v-btn
                dark
                class="re-orangtbtn"
                :disabled="isLoading"
                :to="{ name: 'add_property_ticket' }"
                >+ {{ $t("Add New Ticket") }}</v-btn
              >
            </v-layout>
          </v-flex>
          <v-flex shrink pa-1 xs6 text-xs-right> </v-flex>
        </v-layout>

        <v-layout>
          <v-flex class="flex-wrap" xs12 pa-1>
            <v-breadcrumbs :items="breadItemsSetLang()" class="pl-4">
              <template #divider>
                <v-icon>fiber_manual_record</v-icon>
              </template>
            </v-breadcrumbs>
          </v-flex>
        </v-layout>

        <table-loader v-if="isLoading || isLoadingStatusOptions" />
        <v-card v-else>
          <v-toolbar class="re-toolbar re-wrap">
            <div class="re-flex">
              {{ $t("Rows Per Page") }}
              <v-radio-group v-model="row" class="re-radios">
                <v-radio :label="$t('All')" value="show-all" />
              </v-radio-group>
            </div>
            <v-spacer />
            <v-menu
              v-model="isFilterMenuOpen"
              :close-on-content-click="false"
              :nudge-width="200"
              offset-x
            >
              <template v-slot:activator="{ on }">
                <v-btn dark class="re-orangtbtn re-full" v-on="on">
                  {{ $t("Filter") }} <v-icon right>filter_list</v-icon>
                </v-btn>
              </template>

              <v-card class="pb-4">
                <v-list>
                  <v-list-tile avatar>
                    <v-list-tile-content>
                      <v-list-tile-title>{{ $t("Filter") }}</v-list-tile-title>
                    </v-list-tile-content>

                    <v-list-tile-action>
                      <v-btn icon @click="isFilterMenuOpen = false">
                        <v-icon>close</v-icon>
                      </v-btn>
                    </v-list-tile-action>
                  </v-list-tile>
                </v-list>

                <v-divider />

                <v-list>
                  <v-list-tile class="select-option">
                    <v-autocomplete
                      v-model="selectedProperties"
                      multiple
                      :label="$t('Property')"
                      clearable
                      item-text="property_name"
                      item-value="id"
                      :items="properties"
                      :no-data-text="`${
                        isLoadingPropertyOptions
                          ? $t('Loading...')
                          : $t('No data available')
                      }`"
                      @input.native="fetchProperties"
                      @click:clear="fetchProperties"
                    />
                  </v-list-tile>
                  <v-list-tile class="select-option">
                    <v-autocomplete
                      v-model="selectedStatus"
                      multiple
                      :label="$t('Status')"
                      clearable
                      item-text="display_name"
                      item-value="id"
                      :items="statusList"
                    />
                  </v-list-tile>
                </v-list>

                <v-card-actions>
                  <v-spacer />
                  <v-btn dark class="re-orangtbtn" @click="searchFilters">{{
                    $t("Search")
                  }}</v-btn>
                  <v-btn
                    flat
                    class="re-gray-btn re-box-shadow"
                    @click="resetFilters"
                    >{{ $t("Reset") }}</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-toolbar>

          <v-layout style="overflow: auto">
            <v-data-table
              hide-actions
              class="width100"
              :headers="headers"
              :items="tickets"
              :pagination.sync="sortingParams"
              :options.sync="pagination"
              :server-items-length="pagination.totalItems"
              :no-data-text="$t('No data available')"
            >
              <template v-slot:items="props">
                <tr @click="dialog = true">
                  <td class="re-orange-color text-xs-center">
                    <div>{{ props.item.id }}</div>
                  </td>
                  <td class="text-xs-center">
                    <div>{{ props.item.property.property_name }}</div>
                  </td>
                  <td class="text-xs-center">
                    <div>
                      {{
                        props.item.units.map(el => el.unit_number).join(", ")
                      }}
                    </div>
                  </td>
                  <td class="text-xs-center">
                    <div>
                      {{ props.item.fix_types.map(el => el.name).join(", ") }}
                    </div>
                  </td>
                  <td class="text-xs-center">
                    <div>
                      {{ props.item.note }}
                    </div>
                  </td>
                  <td class="text-xs-center">
                    <div>
                      {{ props.item.creator_phone_number }}
                    </div>
                  </td>
                  <td class="text-xs-center">
                    <div>
                      {{ defaultFormatted(props.item.created, true) }}
                    </div>
                  </td>
                  <td class="text-xs-center">
                    <div>
                      {{ props.item.maintenance_ticket_status.display_name }}
                    </div>
                  </td>
                  <td class="re-td text-xs-center">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">more_vert</v-icon>
                      </template>
                      <v-list>
                        <v-list-tile
                          v-if="hasPermission('maintenance.change_maintenanceticket')"
                          class="list_tile"
                          style="cursor: pointer"
                          @click="
                            $router.push({
                              name: 'edit_property_ticket',
                              params: { id: props.item.id }
                            })
                          "
                        >
                          <v-list-tile-title>{{
                            $t("Edit")
                          }}</v-list-tile-title>
                        </v-list-tile>
                      </v-list>
                    </v-menu>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-layout>
        </v-card>
        <div class="overflow-hidden text-xs-center pt-4" v-if="!isLoading">
          <v-pagination
            v-model="pagination.page"
            :length="pages"
            :total-visible="10"
            class="re-pagination"
            @input="fetchData(false)"
          ></v-pagination>
        </div>
      </v-flex>
    </v-layout>
  </v-layout>
</template>

<script>
import { debounce } from "lodash";
import { ticketStatuses } from "../../constants/maintenanceTicketStatuses";
import checkUserPermission from "@/mixins/checkUserPermission";
import paginationMixin from "@/mixins/paginationMixin";
import { defaultFormat } from "../../helper";
import TableLoader from "@/views/loaders/TableLoader.vue";
import breadItemsSetLang from "@/mixins/breadItemsSetLang";
import propertyService from "../../services/plugins/properties";
import propertyMaintenanceTicketsService from "../../services/plugins/property_maintenance";

export default {
  name: "property_maintenance_tickets_list",
  components: {
    TableLoader
  },
  mixins: [breadItemsSetLang, paginationMixin, checkUserPermission],
  data() {
    return {
      isLoading: false,
      isFilterMenuOpen: false,
      isLoadingPropertyOptions: false,
      isLoadingStatusOptions: false,
      breadCrumbs: [],
      properties: [],
      statusList: [],
      tickets: [],
      fetchProperties: null,
      selectedProperties: [],
      selectedStatus: [],
      row: ""
    };
  },
  async created() {
    this.sortingParams.sortBy = "-id";
    this.fetchProperties = debounce(this.loadProperties, 500);
    try {
      this.isLoading = true;
      await this.loadStatusList();
      await this.fetchProperties();
      await this.fetchData();
      this.isLoading = false;
    } catch (err) {
      this.isLoading = false;
      console.log(
        "Error: Unable to fetch filters options and get tickets list - ",
        err
      );
    }
  },
  watch: {
    "$i18n.locale": async function () {
      await this.loadStatusList();
      await this.fetchData();
    },
    async order() {
      if (this.row !== "show-all") {
        await this.fetchData();
      }
    },
    row() {
      this.fetchData();
    }
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("ID"),
          align: "center",
          value: "id"
        },
        {
          text: this.$t("Property"),
          align: "center",
          sortable: false
        },
        {
          text: this.$t("Units"),
          align: "center",
          sortable: false
        },
        {
          text: this.$t("Maintenance Type"),
          align: "center",
          sortable: false
        },
        {
          text: this.$t("Notes"),
          align: "center",
          sortable: false
        },
        {
          text: this.$t("Phone"),
          align: "center",
          sortable: false
        },
        {
          text: this.$t("Date"),
          align: "center",
          value: "created"
        },
        {
          text: this.$t("Status"),
          sortable: false,
          align: "center"
        },
        {
          text: this.$t("Actions"),
          sortable: false,
          align: "center"
        }
      ];
    },
    filters() {
      return {
        properties: this.selectedProperties.length
          ? this.selectedProperties.join()
          : "",
        maintenance_ticket_statuses: this.selectedStatus.length
          ? this.selectedStatus.join()
          : ""
      };
    }
  },
  methods: {
    async fetchData() {
      try {
        this.isLoading = true;
        let params = {
          ...this.filters,
          ...this.setSortingParams(),
          ...this.setPaginationParams()
        };

        const resp = await this.$processReq(
          propertyMaintenanceTicketsService.getPropertyMaintenanceTicketsList(
            params
          ),
          false
        );

        this.tickets = resp.data.body.results;
        this.isLoading = false;
        this.setPagination(resp.data.body.count);

        this.showAllPagination();
      } catch (err) {
        this.isLoading = false;
        console.log("Error: Unable to get tickets list - ", err);
      }
    },
    async loadProperties(event) {
      try {
        this.isLoadingPropertyOptions = true;

        let params = {
          limit: 10,
          offset: 0
        };

        if (event) {
          params = {
            ...params,
            search: event.target.value || ""
          };
        }
        const resp = await this.$processReq(
          propertyService.getPropertiesList(params),
          false
        );

        this.properties = resp.data.body.results;
        this.isLoadingPropertyOptions = false;
      } catch (err) {
        this.isLoadingPropertyOptions = false;
        console.log("Error: Unable to get properties list - ", err);
      }
    },
    async loadStatusList() {
      try {
        this.isLoadingStatusOptions = true;

        const resp = await this.$processReq(
          propertyMaintenanceTicketsService.getMaintenanceTicketStatusList(),
          false
        );

        this.statusList = resp.data.body.results;
        this.selectedStatus = [
          ticketStatuses.NEW,
          ticketStatuses.HELD,
          ticketStatuses.IN_PROGRESS
        ]; // the default corresponding values are [new, held, in progress]
        this.isLoadingStatusOptions = false;
      } catch (err) {
        this.isLoadingStatusOptions = false;
        console.log("Error: Unable to get Status list - ", err);
      }
    },
    resetFilters() {
      this.selectedProperties = [];
      this.selectedStatus = [
        ticketStatuses.NEW,
        ticketStatuses.HELD,
        ticketStatuses.IN_PROGRESS
      ]; // the default corresponding values are [new, held, in progress]
      this.isFilterMenuOpen = false;
      this.fetchData();
    },
    searchFilters() {
      this.isFilterMenuOpen = false;
      this.fetchData();
    },
    defaultFormatted(date, containsTime) {
      return defaultFormat(date, containsTime);
    }
  }
};
</script>

<style lang="css" scoped>
.select-option >>> .v-list__tile {
  width: 28rem;
}
.select-option >>> .v-input {
  width: 100%;
}
</style>
