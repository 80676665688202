<template>
  <v-menu
    v-model="contractDialog"
    :close-on-content-click="false"
    :nudge-width="200"
    offset-x
  >
    <template v-slot:activator="{ on }">
      <v-btn dark @click="fetchContracts" v-on="on" class="re-show">
        {{ totalContractsCount }} {{ $t("Contracts") }}
      </v-btn>
    </template>
    <v-card>
      <v-list>
        <v-data-table
          :items="contracts"
          :headers="headers"
          class="re-table"
          hide-actions
          :loading="isLoading"
        >
          <template v-slot:items="data">
            <td class="text-xs-center d-sm-table-cell">
              {{ data.item.id }}
            </td>
            <td class="text-xs-center d-sm-table-cell">
              {{ data.item.unit_numbers }}
            </td>
            <td
              class="text-xs-center d-sm-table-cell ongoing"
              :class="{
                expired:
                  data.item.status.includes('terminat') ||
                  data.item.status.includes('deactivat')
              }"
            >
              {{ data.item.status }}
            </td>
            <td class="text-xs-center d-sm-table-cell">
              {{ defaultFormat(data.item.contract_start_date) }}
            </td>
            <td class="text-xs-center d-sm-table-cell">
              {{ defaultFormat(data.item.contract_end_date) }}
            </td>
            <td class="text-xs-center d-sm-table-cell">
              {{
                data.item.termination_date
                  ? defaultFormat(data.item.termination_date)
                  : "-"
              }}
            </td>
            <td class="text-xs-center d-sm-table-cell">
              {{ data.item.tenant_name }}
            </td>
            <td class="text-xs-center d-sm-table-cell">
              {{ data.item.tenant_phone_number }}
            </td>
            <td class="text-xs-center d-sm-table-cell">
              {{ data.item.tenant_email }}
            </td>
            <td class="text-xs-center d-sm-table-cell">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">more_vert</v-icon>
                </template>
                <v-list>
                  <span v-for="(action, index) in actions" :key="index">
                    <v-list-tile
                      v-if="hasPermission(action.permission)"
                      :disabled="disableAction(data.item, action)"
                      @click="
                        $emit('handleNavigation', { action, data, property })
                      "
                    >
                      <v-list-tile-title style="cursor: pointer">{{
                        action.title
                      }}</v-list-tile-title>
                    </v-list-tile>
                  </span>
                </v-list>
              </v-menu>
            </td>
          </template>
        </v-data-table>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import { defaultFormat } from "../../helper";
import propertyService from "../../services/plugins/properties";

import checkUserPermission from "@/mixins/checkUserPermission";
export default {
  name: "property-contracts",
  props: ["on", "totalContractsCount", "propertyId", "isActivated"],
  mixins: [checkUserPermission],
  data() {
    return {
      isLoading: false,
      isFetchedOnce: false,
      contractDialog: false,
      contracts: []
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("Contract Number"),
          sortable: false
        },
        {
          text: this.$t("Units"),
          align: "center",
          sortable: false
        },
        {
          text: this.$t("Contract status"),
          sortable: false
        },
        {
          text: this.$t("Contract Start Date"),
          sortable: false
        },
        {
          text: this.$t("Contract End Date"),
          sortable: false
        },
        {
          text: this.$t("Termination Date"),
          sortable: false
        },
        { text: this.$t("Tenant Name"), align: "center", sortable: false },
        { text: this.$t("Phone"), align: "center", sortable: false },
        { text: this.$t("Email"), align: "center", sortable: false },
        { text: this.$t("Actions"), align: "center", sortable: false }
      ];
    },
    actions() {
      return [
        {
          title: this.$t("Renew Contract"),
          value: "renew",
          route: "renew_contract",
          id: 2,
          permission: "contracts.renew_contract"
        },
        {
          title: this.$t("Terminate Contract"),
          value: "terminate",
          route: "terminate_contract",
          id: 3,
          permission: "contracts.terminate_contract"
        },
        {
          title: this.$t("Manual Payment"),
          value: "manual",
          route: "manual_payment",
          permission: "payments.add_manual_payment"
        },
        {
          title: this.$t("Suspend Contract"),
          value: "suspend",
          permission: "contracts.suspend_contract"
        },
        {
          title: this.$t("Resume Contract"),
          value: "resume",
          permission: "contracts.resume_contract"
        },
        {
          title: this.$t("Modify Contract"),
          value: "modify",
          route: "modify_payment",
          permission: "contracts.change_contract"
        },
        {
          title: this.$t("Invoices"),
          value: "invoice",
          route: "",
          permission: "payments.list_payments_for_contract"
        },
        {
          title: this.$t("Advance Payment"),
          value: "advance",
          route: "advance_payment",
          permission: "payments.add_advance_payment"
        }
      ];
    }
  },
  methods: {
    defaultFormat(value) {
      return defaultFormat(value);
    },
    disableAction(item, action) {
      if (item.status === "Terminated" || item.status === "deactivated") {
        if (
          action.value === "invoice" ||
          (action.value === "manual" && item.is_cash_payment_allowed)
        ) {
          return false;
        }
        return true;
      }
      if (item.status === "vacant") {
        return true;
      }

      if (action.value === "modify" && !item.id) {
        return true;
      }

      if (action.value === "manual" && !item.is_cash_payment_allowed) {
        return true;
      }

      if (item.is_suspended && action.value === "suspend") {
        return true;
      }
      if (!item.is_suspended && action.value === "resume") {
        return true;
      }

      if (action.value === "invoice" && !this.isActivated) {
        return true;
      }

      return false;
    },
    async fetchContracts() {
      if (!this.isFetchedOnce) {
        try {
          this.isLoading = true;
          const resp = await this.$processReq(
            propertyService.getPopertyContractsList(this.propertyId),
            false
          );

          this.contracts = resp.data.body;
          this.isFetchedOnce = true;
          this.isLoading = false;
          this.contractDialog = false;
          await this.$nextTick();
          this.contractDialog = true;
        } catch (err) {
          this.isLoading = false;
          console.log("Error: Unable to get property Contracts list - ", err);
        }
      }
    }
  }
};
</script>

<style lang="css" scoped>
.re-show::after {
  right: -20px;
}
.discount_input {
  margin-top: 0px !important;
}
</style>
