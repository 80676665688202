<template>
  <div class="rent-managmement">
    <v-layout row wrap justify-center>
      <v-layout row wrap justify-center>
        <v-flex xs12 sm12 lg10>
          <rent-header />
          <cards :payment="aggregations" :isLoading="isLoading" />
          <table-loader v-if="isLoading" />
          <v-card v-show="!isLoading">
            <table-header
              :payment="payments"
              :limit="limit"
              :page="page"
              :totalCount="totalCount"
              @search="searchInput($event, true)"
              @loadSearch="searchInput"
              @changeRow="changeRow"
              :exportRent="exportRent"
              :printRent="printRent"
              :showDialog="showDialog"
              :search="searchFilter"
            />
            <v-layout style="overflow: auto" class="container__div">
              <div class="listing__table text-bold">
                <v-data-table
                  hide-actions
                  :items="payments.data_list"
                  :expand="expand"
                  item-key="property_id"
                  :no-data-text="$t('No data available')"
                  :headers="topHeader"
                >
                  <template v-slot:headers="props">
                    <tr>
                      <th
                        v-for="header in props.headers"
                        :key="header.text"
                        class="column text-xs-center"
                      >
                        <v-tooltip top v-if="!!header.hintText">
                          <template v-slot:activator="{ on }">
                            <span v-on="on">
                              {{ header.text }}
                              <span
                                v-if="!!header.vatHeaderText"
                                class="red-colored-part"
                              >
                                |
                                {{ header.vatHeaderText }}
                              </span>
                            </span>
                          </template>
                          <span v-if="!!header.hintText">
                            {{ header.hintText }}
                          </span>
                        </v-tooltip>
                        <span v-else>
                          {{ header.text }}
                          <span
                            v-if="!!header.vatHeaderText"
                            class="red-colored-part"
                          >
                            |
                            {{ header.vatHeaderText }}
                          </span>
                        </span>
                      </th>
                    </tr>
                  </template>
                  <template v-slot:items="props">
                    <tr
                      :class="{ 'active-class': props.expanded }"
                      class="outer-row"
                    >
                      <td
                        class="re-orange"
                        :class="{ 'text-xs-center': $i18n.locale === 'ar' }"
                      >
                        <strong>{{ props.item.property_name }}</strong>
                      </td>
                      <td :class="{ 'text-xs-center': $i18n.locale === 'ar' }">
                        <span class="normal-span">
                          {{
                            props.item.total_net_paid_amount
                              ? reformatAmount(props.item.total_net_paid_amount)
                              : reformatAmount(0)
                          }}
                        </span>
                        <span
                          v-if="getIsCountrySupportsVat"
                          class="red-colored-part"
                        >
                          |
                          {{
                            props.item.total_paid_amount
                              ? reformatAmount(props.item.total_paid_amount)
                              : reformatAmount(0)
                          }}
                        </span>
                      </td>
                      <td :class="{ 'text-xs-center': $i18n.locale === 'ar' }">
                        <span class="normal-span">
                          {{
                            props.item.total_pending_net_amount
                              ? reformatAmount(
                                  props.item.total_pending_net_amount
                                )
                              : reformatAmount(0)
                          }}
                        </span>
                        <span
                          v-if="getIsCountrySupportsVat"
                          class="red-colored-part"
                        >
                          |
                          {{
                            props.item.total_pending_amount
                              ? reformatAmount(props.item.total_pending_amount)
                              : reformatAmount(0)
                          }}
                        </span>
                      </td>
                      <td :class="{ 'text-xs-center': $i18n.locale === 'ar' }">
                        <span class="normal-span">
                          {{
                            props.item.total_net_amount
                              ? reformatAmount(props.item.total_net_amount)
                              : reformatAmount(0)
                          }}
                        </span>
                        <span
                          v-if="getIsCountrySupportsVat"
                          class="red-colored-part"
                        >
                          |
                          {{
                            props.item.total_amount
                              ? reformatAmount(props.item.total_amount)
                              : reformatAmount(0)
                          }}
                        </span>
                      </td>
                      <td :class="{ 'text-xs-center': $i18n.locale === 'ar' }">
                        {{
                          props.item.total_late_amount
                            ? reformatAmount(props.item.total_late_amount)
                            : reformatAmount(0)
                        }}
                      </td>
                      <td :class="{ 'text-xs-center': $i18n.locale === 'ar' }">
                        {{ props.item.total_paid_count }}
                      </td>
                      <td :class="{ 'text-xs-center': $i18n.locale === 'ar' }">
                        {{ props.item.total_pending_count }}
                      </td>
                      <td :class="{ 'text-xs-center': $i18n.locale === 'ar' }">
                        {{ props.item.total_count }}
                      </td>
                      <td class="text-xs-center">
                        {{
                          props.item.total_others_amount
                            ? reformatAmount(props.item.total_others_amount)
                            : reformatAmount(0)
                        }}
                      </td>
                      <td class="text-xs-center">
                        <button @click="props.expanded = !props.expanded">
                          <img
                            v-if="props.expanded"
                            src="@/assets/downArrow.svg"
                          />
                          <img v-else src="@/assets/upArrow.svg" />
                        </button>
                      </td>
                    </tr>
                  </template>
                  <template v-slot:expand="props">
                    <v-card flat>
                      <v-card-text>
                        <v-data-table
                          :headers="headers1"
                          :items="props.item.tenants"
                          :custom-sort="sortData"
                          :expand="expand"
                          :no-data-text="$t('No data available')"
                          item-key="id"
                          hide-actions
                          class=""
                        >
                          <template v-slot:items="data">
                            <tr>
                              <td class="color-column-70" dense>
                                <ColorShow
                                  v-if="isShowColors"
                                  :item="data.item"
                                />
                              </td>
                              <td class="text-xs-center">
                                {{ data.item.unit_number }}
                              </td>
                              <td class="text-xs-center">
                                {{ data.item.contract_id }}
                              </td>
                              <td class="text-xs-center">
                                {{ formatDates(data.item.payment_due_date) }}
                              </td>
                              <td class="text-xs-center">
                                {{ data.item.tenant_name }}
                              </td>
                              <td class="text-xs-center">
                                {{ data.item.civil_id }}
                              </td>
                              <td class="text-xs-center">
                                {{ data.item.phone_number }}
                              </td>
                              <td class="text-xs-center">
                                <rent-statuses
                                  :status="data.item.payment_status"
                                />
                              </td>

                              <td class="text-xs-center">
                                {{ reformatAmount(data.item.rent_amount) }}
                              </td>
                              <td
                                v-if="getIsCountrySupportsVat"
                                class="text-xs-center"
                              >
                                {{ reformatAmount(data.item.vat_amount) }}
                              </td>
                              <td class="text-xs-center">
                                {{ reformatAmount(data.item.net_amount) }}
                              </td>
                              <td class="text-xs-center">
                                {{ formatDates(data.item.payment_date) }}
                              </td>
                              <td
                                class="d-flex justify-space-around align-center"
                              >
                                <v-menu offset-y>
                                  <template v-slot:activator="{ on }">
                                    <img
                                      v-on="on"
                                      class="actions"
                                      src="@/assets/dotedVertical.svg"
                                    />
                                  </template>
                                  <v-list>
                                    <v-list-tile
                                      v-for="(action, index) in actions"
                                      :key="index"
                                      :class="!hasPermission(action.permission) ? 'display-none' : ''"
                                      :disabled="
                                        checkDisabled(
                                          action,
                                          data.item,
                                          props.item
                                        )
                                      "
                                      @click="
                                        perfomAction(
                                          action,
                                          data.item,
                                          props.item
                                        )
                                      "
                                    >
                                      <v-list-tile-title>{{
                                        action.title
                                      }}</v-list-tile-title>
                                    </v-list-tile>
                                  </v-list>
                                </v-menu>
                                <button
                                  class="more-details"
                                  @click="data.expanded = !data.expanded"
                                  :class="{
                                    active: data.expanded,
                                    black_color: data.item.is_grace_period
                                  }"
                                >
                                  {{ $t("More Details") }}
                                </button>
                              </td>
                            </tr>
                          </template>
                          <template v-slot:expand="props">
                            <v-card flat>
                              <v-card-text>
                                <v-data-table
                                  :headers="headers2"
                                  :items="[props.item]"
                                  :no-data-text="$t('No data available')"
                                  item-key="contract_id"
                                  hide-actions
                                  class="most-inner-datatable"
                                >
                                  <template v-slot:items="props">
                                    <tr>
                                      <td class="text-xs-center">
                                        {{
                                          props.item
                                            .receipt_voucher_reference || "-"
                                        }}
                                      </td>
                                      <td class="text-xs-center">
                                        {{
                                          props.item.is_manual_payment
                                            ? "Yes"
                                            : "No"
                                        }}
                                      </td>

                                      <td class="text-xs-center">
                                        {{
                                          props.item.is_late_payment
                                            ? "Yes"
                                            : "No"
                                        }}
                                      </td>

                                      <td class="text-xs-center">
                                        {{
                                          props.item.is_suspended ? "Yes" : "No"
                                        }}
                                      </td>
                                      <td class="text-xs-center">
                                        {{ props.item.email }}
                                      </td>
                                    </tr>
                                  </template>
                                </v-data-table>
                              </v-card-text>
                            </v-card>
                          </template>
                        </v-data-table>
                      </v-card-text>
                    </v-card>
                  </template>
                </v-data-table>
              </div>
            </v-layout>
          </v-card>
          <v-card>
            <color-details />
          </v-card>

          <div class="overflow-hidden text-xs-center pt-4">
            <v-pagination
              v-model="pagination.page"
              :length="pages"
              :total-visible="10"
              class="re-pagination"
              @input="fetchPayment(false)"
            ></v-pagination>
          </div>
        </v-flex>
      </v-layout>
    </v-layout>
    <confirmation-dialog
      v-model="dialog"
      :label="$t('Confirmation')"
      :message="
        $t('Are you sure that you want to send reminder notifications?')
      "
      @acceptDialog="notifyPayments"
      @closeDialog="dialog = false"
    />
  </div>
</template>

<script>
import { debounce } from "lodash";
import moment from "moment";
import { mapGetters } from "vuex";

import "@/assets/listingStyles.css";
import ColorDetails from "../../components/ColorDetails";
import ColorShow from "../../components/ColorDetails/ColorShow.vue";
import ConfirmationDialog from "../../components/dialog/ConfirmationDialog.vue";
import RentStatuses from "../../components/RentStatuses.vue";
import colorMixin from "../../mixins/colorMixin";
import paginationMixin from "../../mixins/paginationMixin";
import TableLoader from "../../views/loaders/TableLoader";

import { defaultFormat, reformatAmountWithDecimals } from "@/helper";
import checkUserPermission from "@/mixins/checkUserPermission";
import generatedReport from "@/services/plugins/generated_report";
import paymentService from "@/services/plugins/payment";
import Cards from "@/views/RentManagement/Cards.vue";
import RentHeader from "@/views/RentManagement/Header.vue";
import TableHeader from "@/views/RentManagement/TableHeader.vue";

export default {
  name: "RentMangement",
  mixins: [colorMixin, paginationMixin, checkUserPermission],
  props: {
    source: String
  },
  components: {
    ConfirmationDialog,
    RentHeader,
    RentStatuses,
    Cards,
    TableHeader,
    TableLoader,
    ColorDetails,
    ColorShow
  },
  data() {
    return {
      isLoading: true,
      isShowColors: true,
      dialog: false,
      payments: [],
      menu: false,
      expand: true,
      row: "",
      on: {},
      searchPayment: null,
      aggregations: {}
    };
  },
  async created() {
    this.searchPayment = debounce(this.fetchPayment, 500);
    try {
      await this.fetchPayment();
    } catch (err) {
      console.log("Error: Unable to fetch payment - ", err);
    }
  },
  watch: {
    "$i18n.locale": function () {
      this.fetchPayment();
    }
  },
  computed: {
    actions() {
      return [
        {
          title: this.$t("Manual Payment"),
          value: "manual",
          route: "manual_payment",
          permission: "payments.add_manual_payment",
        },
        {
          title: this.$t("Payment Reminder"),
          value: "reminder",
          route: "payment_reminder",
          permission: "payments.notify_single_payment",
        },
        {
          title: this.$t("Delete Invoice"),
          value: "delete",
          permission: "payments.rollback_generated_payment",
        }
      ];
    },
    topHeader() {
      return [
        {
          text: this.$t("Property"),
          align: this.$i18n.locale === "ar" ? "center" : "left",
          sortable: false
        },
        {
          text: this.$t("Net Paid"),
          vatHeaderText: this.getIsCountrySupportsVat
            ? this.$t("Total Paid")
            : "",
          align: this.$i18n.locale === "ar" ? "center" : "left",
          sortable: false
        },
        {
          text: this.$t("Net Pending"),
          vatHeaderText: this.getIsCountrySupportsVat
            ? this.$t("Total Pending")
            : "",
          align: this.$i18n.locale === "ar" ? "center" : "left",
          sortable: false
        },
        {
          text: this.$t("Net Amount"),
          vatHeaderText: this.getIsCountrySupportsVat
            ? this.$t("Total Amount")
            : "",
          align: this.$i18n.locale === "ar" ? "center" : "left",
          sortable: false
        },
        {
          text: this.$t("Late Payment"),
          align: this.$i18n.locale === "ar" ? "center" : "left",
          sortable: false
        },
        {
          text: this.$t("Paid Count"),
          align: this.$i18n.locale === "ar" ? "center" : "left",
          sortable: false
        },

        {
          text: this.$t("Pending Count"),
          align: this.$i18n.locale === "ar" ? "center" : "left",
          sortable: false
        },
        {
          text: this.$t("Total Count"),
          align: this.$i18n.locale === "ar" ? "center" : "left",
          sortable: false
        },
        {
          text: this.$t("Others Amount"),
          align: "center",
          sortable: false,
          hintText: this.$t(
            "(Grace, Open, Missed, In Advance While Contracting)"
          )
        },
        {
          text: "",
          sortable: false
        }
      ];
    },
    headers1() {
      return [
        {
          text: "",
          align: "center",
          sortable: false
        },
        {
          text: this.$t("Unit"),
          align: "center",
          sortable: false
        },
        {
          text: this.$t("Contract Id"),
          align: "center",
          sortable: false
        },
        {
          text: this.$t("Due Date"),
          align: "center",
          sortable: false
        },
        {
          text: this.$t("Tenant Name"),
          align: "center",
          sortable: false
        },
        {
          text: this.$t("Civil Id"),
          align: "center",
          sortable: false
        },
        {
          text: this.$t("Phone"),
          align: "center",
          sortable: false
        },
        {
          text: this.$t("Payment Status"),
          align: "center",
          sortable: false
        },
        {
          text: this.$t("Rent Amount"),
          align: "center",
          sortable: false
        },
        this.getIsCountrySupportsVat
          ? {
              text: this.$t("Vat Amount"),
              align: "center",
              sortable: false
            }
          : null,
        {
          text: this.$t("Net Amount"),
          align: "center",
          sortable: false
        },
        {
          text: this.$t("Payment Date"),
          align: "center",
          sortable: false
        },
        {
          text: "",
          align: "center",
          sortable: false
        }
      ].filter(item => item !== null);
    },
    headers2() {
      return [
        {
          text: this.$t("Voucher Number"),
          align: "center",
          sortable: false
        },
        {
          text: this.$t("Is Manual"),
          align: "center",
          sortable: false
        },
        {
          text: this.$t("Is Late Payment"),
          align: "center",
          sortable: false
        },
        {
          text: this.$t("Is Suspended"),
          align: "center",
          sortable: false
        },
        {
          text: this.$t("Email"),
          align: "center",
          sortable: false
        }
      ];
    },
    ...mapGetters("config", ["getIsCountrySupportsVat"])
  },
  methods: {
    sortData(arr) {
      let newarray = [];
      newarray = arr.sort((a, b) => a.unit_number - b.unit_number);
      return newarray;
    },
    formatDates(value) {
      return defaultFormat(value);
    },
    checkDisabled(item, tenant) {
      if (
        item.value === "manual" &&
        (!tenant.can_pay_manual_payment ||
          tenant.payment_status.toLowerCase() === "paid" ||
          (!tenant.is_previous_payment_paid &&
            tenant.is_payment_made_in_sequence))
      ) {
        return true;
      } else if (
        item.value === "new_payment" &&
        tenant.payment_status.toLowerCase() !== "expired"
      ) {
        return true;
      } else if (item.value === "reminder" && !tenant.can_send_reminder) {
        return true;
      } else if (
        item.value === "delete" &&
        !tenant.can_rollback_generated_payment
      ) {
        return true;
      } else if (tenant.is_grace_period) {
        return true;
      }
      return false;
    },
    async perfomAction(item, tenant) {
      if (item.value === "manual") {
        this.$router.push({
          name: item.route,
          params: {
            isRent: true,
            contract_id: tenant.contract_id,
            payment_month: tenant.payment_month,
            payment_year: tenant.payment_year
          }
        });
      }

      if (item.value === "reminder" && tenant.can_send_reminder === true) {
        try {
          this.isLoading = true;
          const response = await this.$processReq(
            paymentService.getPaymentReminder(tenant.id)
          );
          this.isLoading = false;
          this.$root.$emit("snackbar", {
            text: response.data?.body.success,
            color: "success"
          });
        } catch (err) {
          this.isLoading = false;
          console.log("Error: Unable to sent payment link reminder - ", err);
          return;
        }
      }

      if (
        item.value === "delete" &&
        tenant.can_rollback_generated_payment === true
      ) {
        try {
          this.isLoading = true;
          const response = await this.$processReq(
            paymentService.deleteGeneratedPayment(tenant.id)
          );
          await this.fetchPayment();
          this.isLoading = false;
          this.$root.$emit("snackbar", {
            text: response.data?.body.success,
            color: "success"
          });
        } catch (err) {
          this.isLoading = false;
          console.log("Error: Unable to delete invoice - ", err);
          return;
        }
      }
    },
    changeRow(row) {
      this.row = row;
      this.fetchPayment();
    },
    async exportRent() {
      this.isLoading = true;
      try {
        let data = {
          file_format: "xls",
          payment_month: this.getMonth(this.searchFilter.payment_month) || null,
          payment_year: this.searchFilter.payment_year,
          is_manual_payment: this.searchFilter.is_manual_payment
            ? true
            : undefined,
          is_missed: this.searchFilter.is_missed ? true : undefined,
          is_open_balance: this.searchFilter.is_open_balance ? true : undefined,
          is_late_payment: this.searchFilter.is_late_payment ? true : undefined,
          is_suspended: this.searchFilter.is_suspended ? true : undefined,
          status: this.searchFilter.status || undefined,
          property: this.searchFilter.property,
          ...this.setPaginationParams()
        };

        await this.$processReq(generatedReport.exportRent(data), false);
        this.isLoading = false;
        this.$root.$emit("snackbar", {
          text: this.$t("Report is being generated"),
          color: "success"
        });
      } catch (err) {
        this.isLoading = false;
      }
    },
    showDialog() {
      this.dialog = true;
    },
    getMonth(month) {
      if (!month) {
        return month;
      }
      if (Number(month) > 0 && Number(month) < 13) return month;

      return moment().month(month).format("M");
    },
    async notifyPayments() {
      this.isLoading = true;
      try {
        const params = {
          month: this.getMonth(this.searchFilter.payment_month) || null,
          year: this.searchFilter.payment_year
        };
        const response = await this.$processReq(
          paymentService.notifyPayments(params),
          false
        );
        this.isLoading = false;
        this.$root.$emit("snackbar", {
          text: this.$t(
            response.data?.body["Under Processing"] ||
              "Payment records are being notified"
          ),
          color: "success"
        });
      } catch (err) {
        this.isLoading = false;
      }
    },
    transformFilterParams() {
      let filters = { ...this.searchFilter };

      /* Update the params for the initial request 
      // Specially, when user navigates to the screen, 
      // And directly click print button
      */
      if (
        filters.payment_month &&
        filters.payment_year &&
        !(filters.payment_due_date_after || filters.payment_due_date_before)
      ) {
        const month = moment().month(filters.payment_month).format("M");
        const year = filters.payment_year;
        /* It's crucial to use the second constructor of 'moment' to make sure it's
         ** compatible on all browsers, especially on Safari.
         */
        const paymentFrom = moment(`${year}-${month}-01`, "YYYY-MM-DD").format(
          "YYYY-MM-DD"
        ); //start of month
        const paymentTo = moment(`${year}-${month}-01`, "YYYY-MM-DD")
          .endOf("month")
          .format("YYYY-MM-DD"); //end of month

        filters.payment_due_date_after = paymentFrom;
        filters.payment_due_date_before = paymentTo;
        delete filters.payment_month;
        delete filters.payment_year;
      }

      let allParams = {
        search: filters.search || null,
        tenant: filters.contract__tenant__id || null,
        contract: filters.contract__id || null,
        property: filters.property || null,
        report_profile: filters.report_profile || null,
        property_class: filters.property_class || null,
        status: filters.status || null,
        payment_due_date_before: filters.payment_due_date_before || null,
        payment_due_date_after: filters.payment_due_date_after || null,
        payment_date_before: filters.payment_date_before || null,
        payment_date_after: filters.payment_date_after || null,
        is_manual_payment: filters.is_manual_payment ?? null,
        is_suspended: filters.is_suspended || null
      };
      return Object.fromEntries(
        Object.entries(allParams).filter(([, value]) => value != null)
      );
    },
    async printRent() {
      // It's crucial to check the date values before sending the request
      if (
        this.searchFilter.payment_due_date_after ||
        this.searchFilter.payment_due_date_before ||
        (this.searchFilter.payment_month && this.searchFilter.payment_year)
      ) {
        this.isLoading = true;
        try {
          let data = {
            ...this.transformFilterParams(),
            file_format: "pdf",
            ...this.setPaginationParams()
          };
          await this.$processReq(generatedReport.printRent(data), false);
          this.isLoading = false;
          this.$root.$emit("snackbar", {
            text: this.$t("PDF is being generated"),
            color: "success"
          });
        } catch (err) {
          this.isLoading = false;
        }
      } else {
        this.$root.$emit("snackbar", {
          text: this.$t(
            "Kindly, select at least the month and year of the payment list"
          ),
          color: "error"
        });
      }
    },
    searchInput(search, load) {
      this.searchFilter = search;
      this.pagination.page = 1;
      this.searchPayment(load);
    },
    async fetchPayment(blockLoading) {
      this.isShowColors = false;
      try {
        this.menu = false;
        if (!blockLoading) {
          this.isLoading = true;
        }

        let params = {
          ...this.transformFilterParams()
        };

        // Params for getting Table data
        let tableParams = {
          ...params,
          ...this.setPaginationParams()
        };

        // Params for getting only Aggregations data
        let aggregationsParams = {
          ...params,
          show_only_aggregation: true
        };

        const [tableDataResponse, aggregationsResponse] = await Promise.all([
          this.$processReq(
            paymentService.getAuthorizedPaymentList(tableParams),
            false
          ),
          this.$processReq(
            paymentService.getAuthorizedPaymentList(aggregationsParams),
            false
          )
        ]);

        this.isLoading = false;
        const response = tableDataResponse.data.body;
        this.payments = response.results;
        this.aggregations = aggregationsResponse.data.body.results;
        this.searchFilter.payment_month = this.payments.last_payment_month;
        this.searchFilter.payment_year = this.payments.last_payment_year;

        this.setPagination(response.count);

        this.showAllPagination();
      } catch (err) {
        this.isLoading = false;
        this.$root.$emit("snackbar", {
          text: this.$t("Something went wrong while fetching results"),
          color: "error"
        });
        console.log("Error: Unable to get payment list - ", err);
      }
      this.isShowColors = true;
    },
    reformatAmount(amount) {
      return reformatAmountWithDecimals(amount);
    }
  }
};
</script>
<style>
.re-width-70 {
  width: 70px !important;
}
</style>

<style scoped>
.color-column-70 {
  margin: 0 !important;
  padding: 0 !important;
  min-width: 70px !important;
  max-width: 70px !important;
}
.actions {
  padding-bottom: 2px;
  height: 30px;
  width: 30px;
}
::v-deep .v-btn .v-btn__content .v-icon {
  color: inherit !important;
}

.orangeRow {
  background-color: orange !important;
}
.greenRow {
  background-color: lightgreen !important;
}
::v-deep .highlight-text-is-missed:hover {
  background-color: #302f37 !important;
}

::v-deep .text-bold th {
  font-weight: bold;
}
.black_color {
  background-color: black !important;
}
.red-colored-part {
  color: #f90808 !important;
  font-size: inherit !important;
}
.active-class span:first-child.red-colored-part {
  color: #f90808 !important;
}
table.v-table thead th span {
  font-size: inherit !important;
}
.normal-span {
  font-size: inherit !important;
}
</style>
