import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("config", {
      UserPermissions: "getUserPermissions",
    }),
  },

  methods: {
    hasPermission(name) {
      if (this.UserPermissions?.is_superuser) return true;
      return (
        this.UserPermissions?.all_permissions_full_names?.includes(name) ||
        false
      );
    },
  },
};
