<template>
  <div class="properties">
    <v-layout row wrap justify-center>
      <v-layout row wrap justify-center>
        <v-flex xs12 sm12 lg9>
          <v-layout class="pt-5" row wrap>
            <v-flex grow pa-1 xs12 sm5 md8>
              <v-layout align-start row wrap>
                <v-flex xs6 md4>
                  <h2 class="display-2 re-add-ml">{{ $t("Properties") }}</h2>
                </v-flex>
                <v-flex xs6 md8> </v-flex>
              </v-layout>
            </v-flex>
            <v-flex shrink pa-1 xs12 sm7 md4 class="re-rel-pos text-xs-right">
              <v-btn
                v-if="hasPermission('properties.add_properties')"
                dark
                @click="$router.push({ name: 'add_property' })"
                class="re-orangtbtn re-ml-4"
              >
                <v-icon>add</v-icon> {{ $t("Add Property") }}
              </v-btn>
              <v-btn fab small outline v-on="on" class="re-abs-pos">
                <v-icon>history</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex class="flex-wrap" xs12 pa-1>
              <v-breadcrumbs :items="breadItemsSetLang()" class="re-add-ml">
                <template v-slot:divider>
                  <v-icon>fiber_manual_record</v-icon>
                </template>
              </v-breadcrumbs>
            </v-flex>
          </v-layout>
          <table-loader v-if="isLoading" />
          <v-card v-if="!isLoading">
            <v-toolbar height="auto" class="re-toolbar re-wrap">
              <div class="re-flex">
                {{ $t("Rows Per Page") }}
                <v-radio-group v-model="row" row class="re-radios">
                  <v-radio :label="$t('All')" value="show-all"></v-radio>
                </v-radio-group>
              </div>
              <v-spacer></v-spacer>
              <v-text-field
                hide-details
                :placeholder="$t('Search')"
                v-model="searchFilter.search"
                single-line
              />
              <v-spacer />
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-width="200"
                offset-x
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-if="
                      hasPermission(
                        'reports.create_properties_units_report_pdf'
                      )
                    "
                    dark
                    class="re-fill"
                    @click="printProperties"
                  >
                    <img src="@/assets/printer.svg" />
                    {{ $t("Print") }}
                  </v-btn>
                  <!-- <v-btn dark class="re-full" @click="exportProperties">{{
                    $t("Export Properties")
                  }}</v-btn> -->
                  <v-btn
                    dark
                    class="re-orangtbtn re-full"
                    v-on="on"
                    v-bind="attrs"
                  >
                    {{ $t("Filter") }}<v-icon right>filter_list</v-icon>
                  </v-btn>
                </template>

                <v-card class="pb-4">
                  <v-list>
                    <v-list-tile avatar>
                      <v-list-tile-content>
                        <v-list-tile-title>{{
                          $t("Filter")
                        }}</v-list-tile-title>
                      </v-list-tile-content>

                      <v-list-tile-action>
                        <v-btn icon @click="menu = false">
                          <v-icon> close </v-icon>
                        </v-btn>
                      </v-list-tile-action>
                    </v-list-tile>
                  </v-list>

                  <v-divider></v-divider>

                  <v-list>
                    <v-list-tile>
                      <v-select
                        :label="$t('Unit Type')"
                        :items="unitTypes"
                        v-model="filter.unitType"
                        item-text="unit_type_name"
                        item-value="id"
                      />
                    </v-list-tile>

                    <v-list-tile>
                      <v-autocomplete
                        v-model="filter.profile"
                        :label="$t('Profile')"
                        clearable
                        item-text="profile_name"
                        item-value="id"
                        :items="profiles"
                        :no-data-text="`${
                          isLoadingProfiles
                            ? $t('Loading...')
                            : $t('No data available')
                        }`"
                        @input.native="fetchProfiles"
                      />
                    </v-list-tile>

                    <v-list-tile>
                      <v-autocomplete
                        v-model="filter.propertyClassification"
                        :label="$t('Property Classification')"
                        clearable
                        item-text="class_name"
                        item-value="id"
                        :items="propertyClassifications"
                        :no-data-text="`${
                          isLoadingClassifications
                            ? $t('Loading...')
                            : $t('No data available')
                        }`"
                        @input.native="fetchPropertyClassifications"
                      />
                    </v-list-tile>

                    <v-list-tile>
                      <date-picker
                        v-model="toDate"
                        classes="filter-width"
                        :label="$t('Occupancy at Date')"
                        :defaultValue="toDate"
                      />
                    </v-list-tile>

                    <v-list-tile>
                      <v-select
                        v-model="filter.status"
                        :label="$t('Status')"
                        item-text="label"
                        item-value="value"
                        :items="statuses"
                      />
                    </v-list-tile>

                    <v-list-tile>
                      <v-select
                        v-model="filter.isCashPayment"
                        :label="$t('Is Manual Payment Allowed')"
                        item-text="label"
                        item-value="value"
                        :items="cashPaymentFilterOptions"
                      />
                    </v-list-tile>
                  </v-list>

                  <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                      @click="
                        pagination.page = 1;
                        loadProperties(false);
                      "
                      dark
                      class="re-orangtbtn"
                      >{{ $t("Search") }}</v-btn
                    >
                    <v-btn
                      flat
                      @click="
                        pagination.page = 1;
                        resetAllFilters();
                      "
                      class="re-gray-btn re-box-shadow"
                      >{{ $t("Reset") }}</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-menu>
            </v-toolbar>
            <v-layout style="overflow: auto">
              <v-data-table
                :headers="headers"
                :items="properties"
                class="width100"
                :pagination.sync="sortingParams"
                :options.sync="pagination"
                :server-items-length="pagination.totalItems"
                :no-data-text="$t('No data available')"
                hide-actions
              >
                <template v-slot:items="props">
                  <td class="text-xs-left re-first__td d-sm-table-cell">
                    <div
                      @click.stop="
                        hasPermission('properties.change_properties') &&
                          $router.push({
                            name: 'edit_property',
                            params: { id: props.item.id }
                          })
                      "
                      style="width: 122px"
                      :style="{
                        cursor: hasPermission('properties.change_properties')
                          ? 'pointer'
                          : 'not-allowed'
                      }"
                      class="re-orange-color"
                    >
                      {{ props.item.property_name }}
                    </div>
                  </td>
                  <td class="text-xs-left re-first__td d-sm-table-cell">
                    <div style="width: 122px; padding: 10px 0px">
                      {{ props.item.address }}
                    </div>
                  </td>
                  <td class="p-0">
                    <div
                      v-if="props.item.total_contracts == 0"
                      class="text-xs-center"
                    >
                      -----
                    </div>
                    <div v-else>
                      <property-contracts
                        :total-contracts-count="props.item.total_contracts"
                        :property-id="props.item.id"
                        :isActivated="isActivated"
                        @handleNavigation="
                          val =>
                            handleNavigation(val.action, val.data, {
                              property_id: props.item.id,
                              propertyDetails: props.item
                            })
                        "
                      />
                    </div>
                  </td>
                  <td class="p-0">
                    <div
                      v-if="props.item.total_units == 0"
                      class="text-xs-center"
                    >
                      -----
                    </div>
                    <div v-else>
                      <property-units
                        :total-units-count="props.item.total_units"
                        :is_cash_payment_allowed="
                          props.item.is_cash_payment_allowed
                        "
                        :property="props.item.id"
                        :units-filters="unitsPopupFilters"
                        @handleNavigation="
                          val => handleUnitNavigation(val.action, val.data)
                        "
                        @createContract="
                          val =>
                            navigateToNewContract(
                              val.selectedUnits,
                              val.property
                            )
                        "
                      />
                    </div>
                  </td>

                  <td class="p-0">
                    <div
                      v-if="props.item.total_units == 0"
                      class="text-xs-center"
                    >
                      -----
                    </div>
                    <div v-else>
                      <v-btn dark class="re-show">{{
                        props.item.total_units
                      }}</v-btn>
                    </div>
                  </td>
                  <td class="p-0">
                    <div
                      v-if="props.item.total_units == 0"
                      class="text-xs-center"
                    >
                      -----
                    </div>
                    <div v-else>
                      <v-btn dark class="re-show">{{
                        props.item.rented_units
                      }}</v-btn>
                    </div>
                  </td>
                  <td class="p-0">
                    <div
                      v-if="props.item.total_units == 0"
                      class="text-xs-center"
                    >
                      -----
                    </div>
                    <div v-else>
                      <v-btn dark class="re-show">{{
                        props.item.vacant_units
                      }}</v-btn>
                    </div>
                  </td>
                </template>
              </v-data-table>
            </v-layout>
          </v-card>
          <div class="overflow-hidden text-xs-center pt-4">
            <v-pagination
              v-model="pagination.page"
              :length="pages"
              :total-visible="10"
              class="re-pagination"
              @input="loadProperties(false)"
            ></v-pagination>
          </div>
        </v-flex>
      </v-layout>
    </v-layout>
    <invoices-dialogue
      v-model="showInvoiceTab"
      :title="$t('Contract Invoices')"
      :selectedInvoices="selectedContractInvoices"
      :isDialogLoading="invoiceDialog"
      :isUpdatingInvices="isUpdatingInvices"
      :isSelectedPropertySupportsVat="isSelectedPropertySupportsVat"
      @updateInvoices="updateInvoices"
      @closeDialog="closeDialog"
    />
    <InvoiceNotificationListDialogue
      :showDialog="showInvoiceListDialog"
      :isDialogLoading="isLoadingInvoices"
      :invoices="notificationInvoices"
      @acceptDialog="resumeContract()"
      @cancelDialog="resetContractData()"
    />
    <template>
      <div class="text-xs-center">
        <confirmation-dialog
          v-model="alert"
          :label="$t('Confirmation')"
          :message="$t(`Are you sure that you want to ${action} the contract?`)"
          @acceptDialog="acceptDialog"
          @closeDialog="resetActionDetails"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { debounce } from "lodash";
import moment from "moment";
import { mapGetters } from "vuex";

import ConfirmationDialog from "../../components/dialog/ConfirmationDialog.vue";
import InvoiceNotificationListDialogue from "../../components/dialog/InvoiceNotificationListDialogue.vue";
import InvoicesDialogue from "../../components/dialog/InvoicesDialogue.vue";
import DatePicker from "../../components/picker/DatePicker.vue";
import PropertyContracts from "../../components/Properties/PropertyContracts.vue";
import PropertyUnits from "../../components/Properties/PropertyUnits.vue";
import { ContractEnums } from "../../enums";
import {
  formatDateForPicker,
  reformatInvoicesListWithDecimals
} from "../../helper";
import paginationMixin from "../../mixins/paginationMixin";
import contractServices from "../../services/plugins/contract";
import invoiceServices from "../../services/plugins/invoices.js";
import paymentService from "../../services/plugins/payment";
import profileService from "../../services/plugins/profile";
import propertyService from "../../services/plugins/properties";
import propertyClassificationService from "../../services/plugins/property_classification";
import unitTypeService from "../../services/plugins/unit_type";
import TableLoader from "../../views/loaders/TableLoader";

import breadItemsSetLang from "@/mixins/breadItemsSetLang";
import checkUserPermission from "@/mixins/checkUserPermission";
import generatedReport from "@/services/plugins/generated_report";

export default {
  components: {
    PropertyUnits,
    TableLoader,
    PropertyContracts,
    DatePicker,
    InvoicesDialogue,
    InvoiceNotificationListDialogue,
    ConfirmationDialog
  },
  name: "properties_list",
  props: ["on"],
  mixins: [breadItemsSetLang, paginationMixin, checkUserPermission],
  data() {
    return {
      menu: "",
      unitTypes: [],
      isLoading: false,
      properties: [],
      filter: {},
      toDate: null,
      unitsPopupFilters: {},
      propertyClassifications: [],
      profiles: [],
      fetchPropertyClassifications: null,
      fetchProfiles: null,
      fetchProperties: null,
      isLoadingClassifications: false,
      isLoadingProfiles: false,
      row: "",
      showInvoiceTab: false,
      generatedInvoices: [],
      missedInvoices: [],
      openBalanceInvoices: [],
      selectedContractInvoices: [],
      isSelectedPropertySupportsVat: false,
      invoiceDialog: false,
      isUpdatingInvices: false,
      notificationInvoices: [],
      showInvoiceListDialog: false,
      selectedContract: null,
      contractToResume: null,
      action: "",
      alert: false
    };
  },
  async created() {
    this.sortingParams.sortBy = "property_name";
    this.toDate = moment().format("YYYY-MM-DD");
    this.unitsPopupFilters = {
      occupancy_date: this.toDate
    };
    try {
      await this.loadProperties();
      const resp = await this.$processReq(
        unitTypeService.getUnitTypeList({
          limit: 1000
        }),
        false
      );
      this.unitTypes = resp.data.body.results;
    } catch (err) {
      console.log("Error: Unable to get unittype list - ", err);
    }
  },
  mounted() {
    this.fetchPropertyClassifications = debounce(
      this.loadPropertyClassifications,
      500
    );
    this.fetchProfiles = debounce(this.loadProfiles, 500);
    this.fetchProperties = debounce(this.loadProperties, 500);
    this.fetchPropertyClassifications();
    this.fetchProfiles();
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("Property Name"),
          value: "property_name",
          align: !this.$vuetify.rtl ? "left" : "right",
          sortable: true
        },
        {
          text: this.$t("Address"),
          align: !this.$vuetify.rtl ? "left" : "right",
          sortable: false
        },
        { text: this.$t("Contracts"), align: "center", sortable: false },
        { text: this.$t("Units"), align: "center", sortable: false },
        { text: this.$t("Total Units"), align: "center", sortable: false },
        { text: this.$t("Rented Units"), align: "center", sortable: false },
        { text: this.$t("Vacant Units"), align: "center", sortable: false }
      ];
    },
    statuses() {
      return [
        { label: this.$t("Occupied"), value: "occupied" },
        { label: this.$t("Vacant"), value: "vacant" }
      ];
    },
    cashPaymentFilterOptions() {
      return [
        { label: this.$t("All"), value: "all" },
        {
          label: this.$t("Allow Manual Payment"),
          value: "1"
        },
        {
          label: this.$t("Do Not Allow Manual Payment"),
          value: "0"
        }
      ];
    },
    ...mapGetters({ isActivated: "config/getIsActivated" })
  },
  watch: {
    "$i18n.locale": function () {
      this.loadProperties();
    },
    row() {
      this.loadProperties(false);
    },
    "searchFilter.search": function () {
      this.pagination.page = 1;
      this.fetchProperties(true);
    },
    order(newVal, oldVal) {
      if (oldVal !== undefined) {
        this.loadProperties(false);
      }
    }
  },
  methods: {
    async exportProperties() {
      this.isLoading = true;
      try {
        const data = {
          file_format: "xls",
          ...this.collectFilterParams(),
          ...this.setPaginationParams()
        };

        await this.$processReq(generatedReport.exportProperties(data), false);
        this.isLoading = false;
        this.$root.$emit("snackbar", {
          text: this.$t("Report is being generated"),
          color: "success"
        });
      } catch (err) {
        this.isLoading = false;
        this.$root.$emit("snackbar", {
          text: "Something went wrong while exporting csv",
          color: "error"
        });
      }
    },
    async printProperties() {
      this.isLoading = true;
      try {
        const data = {
          file_format: "pdf",
          ...this.collectFilterParams(),
          ...this.setPaginationParams()
        };

        await this.$processReq(generatedReport.printProperties(data), false);
        this.isLoading = false;
        this.$root.$emit("snackbar", {
          text: this.$t("PDF is being generated"),
          color: "success"
        });
      } catch (err) {
        this.isLoading = false;
      }
    },
    resetContractData() {
      this.showInvoiceListDialog = false;
      this.selectedContract = null;
    },
    async resumeContract() {
      this.isLoading = true;
      this.showInvoiceListDialog = false;
      try {
        await this.$processReq(
          contractServices.resumeContract(this.selectedContract)
        );
        this.loadProperties();
        this.$root.$emit("snackbar", {
          text: this.$t("Contract has been resumed"),
          color: "success"
        });
      } catch (error) {
        console.log("Error: Unable to resume contract - ", error);
      }
      this.selectedContract = null;
      this.isLoading = false;
    },
    async getInvoiceNotificationList(contract) {
      try {
        const params = {
          is_notifiable: 1
        };
        const resp = await this.$processReq(
          paymentService.getContractPayments(contract.id, params),
          false
        );

        this.notificationInvoices = reformatInvoicesListWithDecimals(
          resp.data.body || []
        );
        this.isLoading = false;
        if (this.notificationInvoices?.length) {
          this.showInvoiceListDialog = true;
        } else {
          this.resumeContract();
        }
      } catch (error) {
        console.log("Unable to fetch notifiable payments", error);
      }
    },
    closeDialog() {
      this.selectedContractInvoices = [];
      this.generatedInvoices = [];
      this.missedInvoices = [];
      this.openBalanceInvoices = [];
      this.isSelectedPropertySupportsVat = false;
    },
    async fetchGeneratedInvoices(contract) {
      try {
        let data = {
          is_open_balance: 0,
          is_missed: 0
        };
        const resp = await this.$processReq(
          invoiceServices.fetchSelectedInvoices(data, contract.id),
          false
        );

        const invoices = resp.data.body || [];
        this.generatedInvoices = reformatInvoicesListWithDecimals(invoices).map(
          invoice => {
            return {
              ...invoice,
              is_paid: invoice.status === "Paid" ? true : false,
              payment_date: formatDateForPicker(invoice.payment_date),
              allowed_date: formatDateForPicker(invoice.payment_date),
              bank: invoice.bank?.id || null,
              cash_treasury: invoice.cash_treasury?.id || null,
              is_payment_made_in_sequence: contract.is_payment_made_in_sequence,
              is_blocked: invoice.is_advanced_payment_while_contracting,
              is_generated: true
            };
          }
        );

        this.selectedContractInvoices = [
          ...this.openBalanceInvoices,
          ...this.missedInvoices,
          ...this.generatedInvoices
        ];
      } catch (err) {
        console.log("Enable to fetch normally generated invoices", err);
      }
    },
    async fetchOpenBalanceInvoices(contract) {
      try {
        let data = {
          is_open_balance: 1
        };
        const resp = await this.$processReq(
          invoiceServices.fetchSelectedInvoices(data, contract.id),
          false
        );

        const invoices = resp.data.body || [];
        this.openBalanceInvoices = reformatInvoicesListWithDecimals(
          invoices
        ).map(invoice => {
          return {
            ...invoice,
            is_paid: invoice.status === "Paid" ? true : false,
            payment_date: formatDateForPicker(invoice.payment_date),
            allowed_date: formatDateForPicker(invoice.payment_date),
            bank: invoice.bank?.id || null,
            cash_treasury: invoice.cash_treasury?.id || null,
            is_payment_made_in_sequence: contract.is_payment_made_in_sequence,
            is_blocked: invoice.is_advanced_payment_while_contracting
          };
        });
      } catch (err) {
        console.log("Enable to fetch open balance invoices", err);
      }
    },
    async fetchMissedInvoices(contract) {
      try {
        let data = {
          is_missed: 1
        };
        const resp = await this.$processReq(
          invoiceServices.fetchSelectedInvoices(data, contract.id),
          false
        );

        const invoices = resp.data.body || [];
        this.missedInvoices = reformatInvoicesListWithDecimals(invoices).map(
          invoice => ({
            ...invoice,
            is_paid: invoice.status === "Paid" ? true : false,
            payment_date: formatDateForPicker(invoice.payment_date),
            allowed_date: formatDateForPicker(invoice.payment_date),
            bank: invoice.bank?.id || null,
            cash_treasury: invoice.cash_treasury?.id || null,
            is_payment_made_in_sequence: contract.is_payment_made_in_sequence,
            is_blocked: invoice.is_advanced_payment_while_contracting
          })
        );
      } catch (err) {
        console.log("Enable to fetch missed invoices", err);
      }
    },
    async updateInvoices() {
      this.isUpdatingInvices = true;
      try {
        let invoices = this.selectedContractInvoices.map(invoice => {
          invoice = {
            ...invoice,
            status: invoice.is_paid ? "Paid" : "Expired"
          };

          delete invoice.is_paid;
          delete invoice.actual_paid_amount;
          delete invoice.allowed_date;
          delete invoice.is_blocked;
          return invoice;
        });

        invoices = invoices.filter(invoice => !invoice.is_generated);

        const data = {
          reason: ContractEnums.TenantContractInvoice,
          modified_payments: invoices
        };

        const response = await this.$processReq(
          invoiceServices.updateInvoices(data),
          false
        );
        this.$root.$emit("snackbar", {
          text: this.$t(
            `${response.data?.body?.success || "Payments has been updated"}`
          ),
          color: "success"
        });
        this.showInvoiceTab = false;
      } catch (err) {
        this.showInvoiceTab = false;
        console.log("Unable to update invoices invoices", err);
      }
      this.isUpdatingInvices = false;
      this.loadProperties();
    },
    async handleNavigation(action, data, property) {
      this.isLoading = true;
      if (action.value === "renew" || action.value === "terminate") {
        const param = {
          contract_id: data.item.id,
          action: action.id
        };
        try {
          await this.$processReq(
            contractServices.fetchContractValidation(param),
            false
          );
          this.isLoading = false;
        } catch (err) {
          console.log("Error: Unable to fetch contract validation - ", err);
          this.isLoading = false;
          return;
        }
      }
      if (action.value === "invoice") {
        this.invoiceDialog = true;
        this.isSelectedPropertySupportsVat =
          property.propertyDetails.is_vat_enabled;
        await this.fetchOpenBalanceInvoices(data.item);
        await this.fetchMissedInvoices(data.item);
        await this.fetchGeneratedInvoices(data.item);
        this.invoiceDialog = false;
        this.showInvoiceTab = true;
      }
      if (action.value === "suspend" || action.value === "resume") {
        this.alert = true;
        this.action = action.value;
        this.contractToResume = data.item;
        this.isLoading = false;
        return;
      }
      this.isLoading = false;
      if (action.value !== "invoice") {
        this.$router
          .push({
            name: action.route,
            params: {
              contract_id: data.item.id,
              property,
              tenant_id: data.item.tenant_id,
              tenant_name: data.item.tenant_name,
              is_suspended: data.item.is_suspended
            }
          })
          .catch(error => console.log(error));
      }
    },
    handleUnitNavigation(action, data) {
      this.$router
        .push({
          name: action.route,
          params: {
            id: data.item.id,
            unit: data.item
          }
        })
        .catch(error => console.log(error));
    },

    async navigateToNewContract(units, property) {
      if (!units.length) {
        this.$root.$emit("snackbar", {
          text: this.$t("Please, select at least one unit"),
          color: "error"
        });
      } else {
        const param = {
          units: units.join(","),
          action: 1
        };
        try {
          await this.$processReq(
            contractServices.fetchContractValidation(param),
            false
          );
          this.isLoading = false;
        } catch (err) {
          console.log("Error: Unable to fetch contract validation - ", err);
          this.isLoading = false;
          return;
        }
        this.$router
          .push({
            name: "add_contract",
            params: {
              units,
              property,
              goBack: true
            }
          })
          .catch(error => console.log(error));
      }
    },
    resetAllFilters() {
      this.filter = {};
      this.toDate = moment().format("YYYY-MM-DD");
      this.unitsPopupFilters = {
        occupancy_date: this.toDate
      };
      this.loadProperties();
    },
    async loadProperties(blockLoading) {
      try {
        this.menu = false;
        if (!blockLoading) {
          this.isLoading = true;
        }

        const params = {
          ...this.collectFilterParams(),
          ...this.setSortingParams(),
          ...this.setPaginationParams()
        };
        const list = await this.$processReq(
          propertyService.getPropertiesList(params),
          false
        );
        this.isLoading = false;
        const response = list.data.body;

        this.properties = response.results;

        this.setPagination(response.count);

        this.showAllPagination();
      } catch (err) {
        this.isLoading = false;
        this.$root.$emit("snackbar", {
          text: this.$t("Something went wrong while fetching results"),
          color: "error"
        });
        console.log(err);
      }
    },
    async loadPropertyClassifications(event) {
      try {
        this.isLoadingClassifications = true;

        let params = {
          limit: 10,
          offset: 0
        };

        if (event) {
          params = {
            ...params,
            search: event.target.value || ""
          };
        }
        const resp = await this.$processReq(
          propertyClassificationService.getPropertyClassificationList(params),
          false
        );

        this.propertyClassifications = resp.data.body.results;
        this.isLoadingClassifications = false;
      } catch (err) {
        this.isLoadingClassifications = false;
        console.log(
          "Error: Unable to get property classifications list - ",
          err
        );
      }
    },
    async loadProfiles(event) {
      try {
        this.isLoadingProfiles = true;

        let params = {
          limit: 10,
          offset: 0
        };

        if (event) {
          params = {
            ...params,
            search: event.target.value || ""
          };
        }
        const resp = await this.$processReq(
          profileService.getProfileList(params),
          false
        );

        this.profiles = resp.data.body.results;
        this.isLoadingProfiles = false;
      } catch (err) {
        this.isLoadingProfiles = false;
        console.log(
          "Error: Unable to get property classifications list - ",
          err
        );
      }
    },
    collectFilterParams() {
      let params = {
        is_cash_payment_allowed: this.filter.isCashPayment,
        unittype: this.filter.unitType,
        unit_status: this.filter.status,
        report_profile: this.filter.profile,
        property_class: this.filter.propertyClassification,
        occupancy_date: this.toDate
      };
      this.unitsPopupFilters = {
        unittype: this.filter.unitType,
        unit_status: this.filter.status,
        occupancy_date: this.toDate
      };
      if (this.filter.isCashPayment === "all") {
        delete params.is_cash_payment_allowed;
      }

      return params;
    },
    async acceptDialog() {
      try {
        this.isLoading = true;
        if (this.contractToResume.is_suspended && this.action === "resume") {
          if (
            this.contractToResume.allowed_pyament_methodes?.includes(
              "payment link"
            )
          ) {
            this.getInvoiceNotificationList(this.contractToResume);
            this.selectedContract = this.contractToResume.id;
            return;
          }
          await this.$processReq(
            contractServices.resumeContract(this.contractToResume.id)
          );
        } else if (!this.contractToResume.is_suspended) {
          await this.$processReq(
            contractServices.suspendContract(this.contractToResume.id)
          );
        } else {
          this.$root.$emit("snackbar", {
            text: this.$t(
              `You cannot suspend this contract as it is with the status`,
              { status: this.contractToResume.status }
            ),
            color: "error"
          });
          this.isLoading = false;
          return;
        }
        this.loadProperties();
        this.$root.$emit("snackbar", {
          text: this.$t(
            `Contract has been ${
              this.action === "suspend" ? "suspended" : "resumed"
            }`
          ),
          color: "success"
        });
        return;
      } catch (error) {
        console.log("Error: Unable to suspend contract - ", error);
      }
      this.resetActionDetails();
      this.isLoading = false;
    },
    resetActionDetails() {
      this.alert = false;
      this.action = "";
      this.contractToResume = null;
    }
  }
};
</script>

<style lang="css" scoped>
span {
  top: 5px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-top: 5px solid rgba(0, 0, 0, 0.87);
  left: 4px;
}
.expanded .triangle {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  top: -2px;
}
.re-show:after {
  display: none;
}
.bg-grey:nth-child(even) {
  background: #e9ebef;
}
.p-0 {
  padding: 0px !important;
}
img {
  padding-left: 6px !important;
  margin-right: 6px;
  filter: brightness(0) invert(1);
}
</style>
