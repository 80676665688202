<template>
  <div class="manual-generating-payment">
    <v-layout wrap justify-center>
      <v-flex xs12 sm12 lg10>
        <v-layout>
          <div class="heading">
            <h1 class="pa-1">
              {{ $t("Generate Invoices") }}
            </h1>
            <p class="pl-4">
              {{
                $t(
                  "Easily Generate Invoices by selecting the date of the desired period"
                )
              }}
            </p>
          </div>
        </v-layout>
        <v-layout>
          <v-flex class="flex-wrap" xs12 pa-1>
            <v-breadcrumbs :items="breadItemsSetLang()" class="pl-4">
              <template v-slot:divider>
                <v-icon>fiber_manual_record</v-icon>
              </template>
            </v-breadcrumbs>
          </v-flex>
        </v-layout>
        <table-loader v-if="isLoading" />
        <v-card v-if="!isLoading">
          <v-layout justify-space-between>
            <div class="filter-controls">
              <v-layout wrap>
                <v-menu
                  ref="datePickerMenu"
                  v-model="datePickerMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="selectedDate"
                      readonly
                      v-on="on"
                      class="re-datepicker re-custom-date-picker filter-type"
                    >
                      <template v-slot:prepend>
                        <img src="../assets/calendar.svg" />
                      </template>
                    </v-text-field>
                  </template>
                  <v-card>
                    <v-tabs grow>
                      <v-tab key="calendar">
                        <div slot>
                          {{ this.$t("Month") }}
                        </div>
                      </v-tab>
                      <v-tab key="timer">
                        <div slot>
                          {{ this.$t("Day") }}
                        </div>
                      </v-tab>
                      <v-tab-item key="month">
                        <v-date-picker
                          v-model="date"
                          type="month"
                          no-title
                          scrollable
                        />
                      </v-tab-item>
                      <v-tab-item key="date">
                        <v-date-picker
                          v-model="date"
                          type="date"
                          no-title
                          scrollable
                        />
                      </v-tab-item>
                    </v-tabs>
                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        flat
                        color="primary"
                        @click="datePickerMenu = false"
                      >
                        {{ $t("Cancel") }}
                      </v-btn>
                      <v-btn flat color="primary" @click="changeDate()">{{
                        $t("OK")
                      }}</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-menu>

                <v-autocomplete
                  v-model="selectedProfiles"
                  multiple
                  :label="$t('Profile')"
                  clearable
                  item-text="profile_name"
                  return-object
                  :items="profiles"
                  :loading="isLoadingProfiles"
                  :no-data-text="`${
                    isLoadingProfiles
                      ? $t('Loading...')
                      : $t('No data available')
                  }`"
                  @input.native="fetchProfiles"
                  @click:clear="fetchProfiles"
                  class="filter-type ml-5"
                />
                <v-autocomplete
                  v-model="selectedProperties"
                  multiple
                  :label="$t('Property')"
                  clearable
                  item-text="property_name"
                  return-object
                  :items="properties"
                  :loading="isLoadingProperties"
                  :no-data-text="`${
                    isLoadingProperties
                      ? $t('Loading...')
                      : $t('No data available')
                  }`"
                  @input.native="fetchProperties"
                  @click:clear="fetchProperties"
                  class="filter-type ml-5"
                >
                  <template v-slot:prepend-item>
                    <v-list-tile ripple @click="toggle">
                      <v-list-tile-action>
                        <v-checkbox v-model="selectAllProperties"></v-checkbox>
                      </v-list-tile-action>
                      <v-list-tile-content>
                        <v-list-tile-title>
                          {{
                            !isAllPropertiesSelected
                              ? "Select all"
                              : "Unselect all"
                          }}
                        </v-list-tile-title>
                      </v-list-tile-content>
                    </v-list-tile>
                  </template>
                </v-autocomplete>
                <v-btn class="showbtn" @click="applyFilters()">{{
                  $t("Show")
                }}</v-btn>
              </v-layout>
              <div class="right__div">
                <v-btn
                  v-if="
                    hasPermission(
                      'reports.create_generated_not_generated_payments_pdf_report'
                    )
                  "
                  class="printbtn"
                  @click="printPayment"
                  :disabled="!notGeneratedPaymentList.length"
                >
                  <img class="m-6 clr-white" src="../assets/printer.svg" />
                  {{ $t("Print") }}
                </v-btn>
                <v-btn
                  v-if="
                    hasPermission(
                      'payments.create_manual_payment_through_screen'
                    )
                  "
                  class="generatebtn"
                  :disabled="!notGeneratedPaymentList.length"
                  @click="showInvoiceAlert"
                >
                  {{ $t("Generate Invoices and Send Payment Links") }}
                </v-btn>
              </div>
            </div>
          </v-layout>
          <div class="filter-details">
            <v-layout>
              <div class="heading pt-0">
                {{ $t("Invoices to be generated:") }}
              </div>
            </v-layout>
            <v-layout>
              <span class="font-weight-bold pr-2"
                >{{ `${$t("Payment Due Date")}: ` }}
              </span>
              <span>{{ formatSelectedDate }}</span>
            </v-layout>
            <v-layout v-if="displaySelectedProfiles" pt-2>
              <span class="font-weight-bold pr-2">{{
                `${$t("Selected Profiles")}: `
              }}</span>
              <span>{{ displaySelectedProfiles }}</span>
            </v-layout>
            <v-layout v-if="displaySelectedProperties" pt-2>
              <span class="font-weight-bold pr-2">
                {{ `${$t("Selected Properties")}: ` }}
              </span>
              <span>{{ displaySelectedProperties }}</span>
            </v-layout>
          </div>
          <v-layout>
            <div class="reheading-like">
              {{ $t("Not Generated Payments") }}
            </div>
          </v-layout>
          <v-toolbar
            v-if="notGeneratedPaymentList.length"
            height="auto"
            class="re-toolbar re-wrap"
          >
            <div class="re-flex">
              {{ $t("Rows Per Page") }}:
              <v-radio-group v-model="row" row class="re-radios">
                <v-radio :label="$t('All')" value="show-all"></v-radio>
                <v-radio :label="$t('100')" value=""></v-radio>
              </v-radio-group>
            </div>
          </v-toolbar>
          <v-layout
            class="table-container"
            :class="{
              'overflow-data': notGeneratedPaymentList.length > 10
            }"
          >
            <v-data-table
              :headers="headers"
              :items="notGeneratedPaymentList"
              item-key="contract_id"
              :no-data-text="$t('No data available')"
              hide-actions
              :expand="expand"
              ref="notGeneratedTable"
              class="payments"
            >
              <template v-slot:headers="props">
                <tr>
                  <th
                    v-for="header in props.headers"
                    :key="header.text"
                    :class="`column text-xs-center re-width-${header.width}`"
                  >
                    {{ header.text }}
                  </th>
                </tr>
              </template>
              <template v-slot:items="props">
                <tr
                  :class="{
                    'bg-is-edited-invoice': confirmEditedInvoice(
                      props.item.currentInvoice
                    )
                  }"
                >
                  <td class="re-width-70" dense>
                    <ColorShow :item="props.item.currentInvoice" />
                  </td>
                  <td class="td-icon">
                    <v-btn
                      flat
                      icon
                      @click="props.expanded = !props.expanded"
                      v-if="props.item.previousInvoivces.length > 0"
                    >
                      <v-icon class="rotate-90-back" v-if="props.expanded"
                        >chevron_right</v-icon
                      >
                      <v-icon class="rotate-90" v-else>chevron_right</v-icon>
                    </v-btn>
                  </td>
                  <td class="text-xs-center re-width-70" dense>
                    {{ props.item.property_name }}
                  </td>
                  <td class="text-xs-center re-width-70" dense>
                    {{ props.item.units.join() }}
                  </td>
                  <td class="text-xs-center re-width-96" dense>
                    {{ props.item.contract_id }}
                  </td>
                  <td class="text-xs-center re-width-94" dense>
                    {{
                      props.item.currentInvoice.contract_start_date
                        ? defaultFormatted(props.item.contract_start_date)
                        : "-"
                    }}
                  </td>
                  <td class="text-xs-center re-width-94" dense>
                    {{
                      props.item.currentInvoice.contract_end_date
                        ? defaultFormatted(
                            props.item.currentInvoice.contract_end_date
                          )
                        : "-"
                    }}
                  </td>
                  <td class="text-xs-center re-width-123" dense>
                    {{
                      props.item.currentInvoice.payment_due_date
                        ? defaultFormatted(
                            props.item.currentInvoice.payment_due_date
                          )
                        : "-"
                    }}
                  </td>
                  <td class="text-xs-center re-width-123" dense>
                    {{
                      props.item.currentInvoice.contract_termination_date
                        ? defaultFormatted(
                            props.item.currentInvoice.contract_termination_date
                          )
                        : "-"
                    }}
                  </td>
                  <td class="text-xs-center re-width-94" dense>
                    {{ props.item.currentInvoice.contract_original_amount }}
                  </td>
                  <td class="text-xs-center re-width-94" dense>
                    {{ props.item.currentInvoice.payment_amount }}
                  </td>
                  <td class="text-xs-center re-width-94" dense>
                    {{ props.item.currentInvoice.payment_discount || "0.000" }}
                  </td>
                  <td class="text-xs-center re-width-94" dense>
                    {{
                      props.item.currentInvoice.payment_due_amount || "0.000"
                    }}
                  </td>
                  <td class="re-width-170">
                    <PriceEditor
                      v-model="props.item.currentInvoice.actual_paid_amount"
                      :invoice-item="props.item"
                      :isParent="true"
                      :maxAmount="
                        props.item.currentInvoice.contract_original_amount
                      "
                      :initialValue="
                        props.item.currentInvoice.actual_paid_amount
                      "
                      :isDisabled="
                        props.item.currentInvoice.is_grace_period ||
                        props.item.currentInvoice.is_missed
                      "
                      :data-vv-name="`actual_paid_amount_${props.item.currentInvoice.contract_id}_${props.item.currentInvoice.payment_due_date}`"
                      :error-messages="
                        errors.collect(
                          `actual_paid_amount_${props.item.currentInvoice.contract_id}_${props.item.currentInvoice.payment_due_date}`
                        )
                      "
                      @input="
                        addEditedPayment(
                          props.item.currentInvoice,
                          props.item.currentInvoice.contract_original_amount
                        )
                      "
                    />
                  </td>
                  <td
                    v-if="getIsCountrySupportsVat"
                    class="text-xs-center re-width-94"
                    dense
                  >
                    {{ props.item.currentInvoice.payment_vat_amount }}
                  </td>
                  <td class="text-xs-center re-width-94" dense>
                    {{ props.item.currentInvoice.payment_net_amount }}
                  </td>
                  <td class="text-xs-center re-width-94" dense>
                    {{ props.item.tenant_name }}
                  </td>
                  <td class="text-xs-center re-width-116" dense>
                    {{ props.item.tenant_phone_number }}
                  </td>
                  <td class="text-xs-center re-width-116" dense>
                    {{ props.item.tenant_civil_id_number }}
                  </td>
                </tr>
              </template>
              <template v-slot:expand="props">
                <v-card flat class="inner-payments-card">
                  <v-card-text>
                    <v-data-table
                      :headers="headers"
                      :item-key="`payment_${props.index}`"
                      :items="props.item.previousInvoivces"
                      hide-actions
                      hide-headers
                      class="payments"
                    >
                      <template v-slot:items="props">
                        <tr
                          :class="{
                            'bg-is-edited-invoice': confirmEditedInvoice(
                              props.item
                            )
                          }"
                        >
                          <td class="re-width-70" dense>
                            <ColorShow :item="props.item" />
                          </td>
                          <td class="text-xs-center re-width-52-22"></td>
                          <td class="text-xs-center re-width-70"></td>
                          <td class="text-xs-center re-width-70">
                            {{ props.item.units.join() }}
                          </td>
                          <td class="text-xs-center re-width-96" dense>
                            {{ props.item.contract_id }}
                          </td>
                          <td class="text-xs-center re-width-94" dense>
                            {{
                              props.item.contract_start_date
                                ? defaultFormatted(
                                    props.item.contract_start_date
                                  )
                                : "-"
                            }}
                          </td>
                          <td class="text-xs-center re-width-96" dense>
                            {{
                              props.item.contract_end_date
                                ? defaultFormatted(props.item.contract_end_date)
                                : "-"
                            }}
                          </td>
                          <td class="text-xs-center re-width-123" dense>
                            {{
                              props.item.payment_due_date
                                ? defaultFormatted(props.item.payment_due_date)
                                : "-"
                            }}
                          </td>
                          <td class="text-xs-center re-width-123" dense>
                            {{
                              props.item.contract_termination_date
                                ? defaultFormatted(
                                    props.item.contract_termination_date
                                  )
                                : "-"
                            }}
                          </td>

                          <td class="text-xs-center re-width-94" dense>
                            {{ props.item.contract_original_amount }}
                          </td>
                          <td class="text-xs-center re-width-94" dense>
                            {{ props.item.payment_amount }}
                          </td>
                          <td class="text-xs-center re-width-94" dense>
                            {{ props.item.payment_discount || "0.000" }}
                          </td>
                          <td class="text-xs-center re-width-94" dense>
                            {{ props.item.payment_due_amount || "0.000" }}
                          </td>
                          <td class="re-width-170">
                            <PriceEditor
                              v-model="props.item.actual_paid_amount"
                              :invoice-item="props.item"
                              :isParent="false"
                              :maxAmount="props.item.contract_original_amount"
                              :initialValue="props.item.actual_paid_amount"
                              :data-vv-name="`actual_paid_amount_${props.item.contract_id}_${props.item.payment_due_date}`"
                              :isDisabled="
                                props.item.is_grace_period ||
                                props.item.is_missed
                              "
                              :error-messages="
                                errors.collect(
                                  `actual_paid_amount_${props.item.contract_id}_${props.item.payment_due_date}`
                                )
                              "
                              @input="
                                addEditedPayment(
                                  props.item,
                                  props.item.contract_original_amount
                                )
                              "
                            />
                          </td>
                          <td
                            v-if="getIsCountrySupportsVat"
                            class="text-xs-center re-width-94"
                            dense
                          >
                            {{ props.item.payment_vat_amount }}
                          </td>
                          <td class="text-xs-center re-width-94" dense>
                            {{ props.item.payment_net_amount }}
                          </td>
                          <td class="text-xs-center re-width-94" dense>
                            {{ props.item.tenant_name }}
                          </td>
                          <td class="text-xs-center re-width-116" dense>
                            {{ props.item.tenant_phone_number }}
                          </td>
                          <td class="text-xs-center re-width-116" dense>
                            {{ props.item.tenant_civil_id_number }}
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </template>
            </v-data-table>
          </v-layout>
          <div class="overflow-hidden text-xs-center pt-4">
            <v-pagination
              v-model="pagination.page"
              :length="pages"
              :total-visible="10"
              class="re-pagination"
              @input="fetchPayments(false)"
            ></v-pagination>
          </div>
          <v-layout
            class="d-flex align-center justify-space-between edited-payments"
          >
            <div class="reheading-like">
              {{ $t("Edited Not Generated Payments") }}
            </div>
            <v-btn class="clearbtn" @click="clearEditedPayments()">{{
              $t("Clear")
            }}</v-btn>
          </v-layout>
          <v-layout
            class="table-container"
            :class="{
              'overflow-data': editedPaymentsList.length > 10
            }"
          >
            <v-data-table
              :headers="headers"
              :items="editedPaymentsList"
              item-key="edited-payment"
              :no-data-text="$t('No data available')"
              hide-actions
              ref="editedPaymentsTable"
              class="payments"
            >
              <template v-slot:headers="props">
                <tr>
                  <th
                    v-for="header in props.headers"
                    :key="header.text"
                    :class="`column text-xs-center re-width-${header.width}`"
                  >
                    {{ header.text }}
                  </th>
                </tr>
              </template>
              <template v-slot:items="props">
                <tr>
                  <td class="re-width-70" dense>
                    <ColorShow :item="props.item" />
                  </td>
                  <td class="td-icon">
                    <v-btn
                      flat
                      icon
                      @click="removeItemFromEditedPayments(props.item)"
                    >
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </td>
                  <td class="text-xs-center re-width-70" dense>
                    {{ props.item.property_name }}
                  </td>
                  <td class="text-xs-center re-width-70" dense>
                    {{ props.item.units.join() }}
                  </td>
                  <td class="text-xs-center re-width-96" dense>
                    {{ props.item.contract_id }}
                  </td>
                  <td class="text-xs-center re-width-94" dense>
                    {{
                      props.item.contract_start_date
                        ? defaultFormatted(props.item.contract_start_date)
                        : "-"
                    }}
                  </td>
                  <td class="text-xs-center re-width-94" dense>
                    {{
                      props.item.contract_end_date
                        ? defaultFormatted(props.item.contract_end_date)
                        : "-"
                    }}
                  </td>
                  <td class="text-xs-center re-width-123" dense>
                    {{
                      props.item.payment_due_date
                        ? defaultFormatted(props.item.payment_due_date)
                        : "-"
                    }}
                  </td>
                  <td class="text-xs-center re-width-123" dense>
                    {{
                      props.item.contract_termination_date
                        ? defaultFormatted(props.item.contract_termination_date)
                        : "-"
                    }}
                  </td>
                  <td class="text-xs-center re-width-94" dense>
                    {{ props.item.contract_original_amount }}
                  </td>
                  <td class="text-xs-center re-width-94" dense>
                    {{ props.item.payment_amount }}
                  </td>
                  <td class="text-xs-center re-width-94" dense>
                    {{ props.item.payment_discount || "0.000" }}
                  </td>
                  <td class="text-xs-center re-width-94" dense>
                    {{ props.item.payment_due_amount || "0.000" }}
                  </td>
                  <td class="re-width-170">
                    <v-text-field
                      v-model="props.item.actual_paid_amount"
                      v-validate="
                        `max_value: ${props.item.contract_original_amount}`
                      "
                      :name="`edited_actual_paid_amount_${props.index}`"
                      :data-vv-name="`edited_actual_paid_amount_${props.index}`"
                      class="re-custom-input input-max-width"
                      :disabled="true"
                    />
                    <span class="red-text">
                      {{
                        errors.collect(
                          `edited_actual_paid_amount_${props.index}`
                        ).length
                          ? $t(
                              "Actual paid amount should be less than or equal to the original amount"
                            )
                          : ""
                      }}</span
                    >
                  </td>
                  <td
                    v-if="getIsCountrySupportsVat"
                    class="text-xs-center re-width-94"
                    dense
                  >
                    {{ props.item.payment_vat_amount }}
                  </td>
                  <td class="text-xs-center re-width-94" dense>
                    {{ props.item.payment_net_amount }}
                  </td>
                  <td class="text-xs-center re-width-94" dense>
                    {{ props.item.tenant_name }}
                  </td>
                  <td class="text-xs-center re-width-116" dense>
                    {{ props.item.tenant_phone_number }}
                  </td>
                  <td class="text-xs-center re-width-116" dense>
                    {{ props.item.tenant_civil_id_number }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-layout>
          <v-layout>
            <div class="re-generated-total">
              <div>
                {{ $t("Totals") }}
              </div>
              <ul>
                <li>
                  <span
                    ><span>{{ $t("Count") }}</span
                    >{{
                      notGeneratedPaymentsTotalCount
                        ? notGeneratedPaymentsTotalCount
                        : "0"
                    }}</span
                  >
                </li>
                <li>
                  <span>
                    <span>{{ $t("Original Contract Amount") }}</span
                    >{{ notGeneratedPaymentsTotalOriginalAmount }}
                    {{ $t(getCurrencyCode) }}</span
                  >
                </li>
                <li>
                  <span>
                    <span>{{ $t("Paid Amount") }}</span>
                    {{ notGeneratedPaymentsTotalAmount }}
                    {{ $t(getCurrencyCode) }}
                  </span>
                </li>
                <li>
                  <span>
                    <span>{{ $t("Actual Paid Amount") }}</span>
                    {{ notGeneratedActualPaidAmount }}
                    {{ $t(getCurrencyCode) }}
                  </span>
                </li>
                <li>
                  <span>
                    <span>{{ $t("Net Amount") }}</span
                    >{{ notGeneratedPaymentsTotalNetAmount }}
                    {{ $t(getCurrencyCode) }}</span
                  >
                </li>
              </ul>
            </div>
          </v-layout>
          <color-details />
        </v-card>
      </v-flex>
    </v-layout>
    <template>
      <div class="text-xs-center">
        <confirmation-dialog
          v-model="alert"
          :label="$t('Confirmation')"
          :message="
            $t(
              'Manual payment records will be reset. Are you sure you want to continue'
            )
          "
          @acceptDialog="confirmDialog"
          @closeDialog="dialog = false"
        />
      </div>
    </template>
    <template>
      <div class="text-xs-center">
        <confirmation-dialog
          v-model="profileFilterAlert"
          :label="$t('Confirmation')"
          :message="
            $t(
              'Manual payment records will be reset. Are you sure you want to continue'
            )
          "
          @acceptDialog="acceptSelectedProfile()"
          @closeDialog="removeSelectedProfile()"
        />
      </div>
    </template>
    <template>
      <div class="text-xs-center">
        <confirmation-dialog
          v-model="propertyFilterAlert"
          :label="$t('Confirmation')"
          :message="
            $t(
              'Manual payment records will be reset. Are you sure you want to continue'
            )
          "
          @acceptDialog="acceptSelectedProperty()"
          @closeDialog="removeSelectedProperty()"
        />
      </div>
    </template>
    <template>
      <div class="text-xs-center">
        <confirmation-dialog
          v-model="editedInvoiceAlert"
          :label="$t('Confirmation')"
          :message="
            $t(
              'Edited payment records will be reset. Are you sure you want to continue'
            )
          "
          @acceptDialog="confirmEditedDialog"
          @closeDialog="editedInvoiceAlert = false"
        />
      </div>
    </template>

    <template>
      <div class="text-xs-center">
        <confirmation-dialog
          v-model="invoiceAlert"
          :label="$t('Confirmation')"
          :message="$t('Are you sure that you want to generate the invoices')"
          @acceptDialog="generatePayments"
          @closeDialog="dialog = false"
        />
      </div>
    </template>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import moment from "moment";
import { mapGetters } from "vuex";

import ColorDetails from "../components/ColorDetails";
import ColorShow from "../components/ColorDetails/ColorShow.vue";
import ConfirmationDialog from "../components/dialog/ConfirmationDialog.vue";
import PriceEditor from "../components/PriceEditor.vue";
import {
  defaultFormat,
  transformContractPrecalculatedPayments,
  checkMaxAllowedFractionDigits,
  reformatAmountWithDecimals,
  calculateVatAmount,
  calculateNetAmount
} from "../helper";
import invoices from "../services/plugins/invoices";
import TableLoader from "../views/loaders/TableLoader";
import "../assets/styles.css";

import breadItemsSetLang from "@/mixins/breadItemsSetLang";
import checkUserPermission from "@/mixins/checkUserPermission";
import colorMixin from "@/mixins/colorMixin";
import paginationMixin from "@/mixins/paginationMixin";
import generatedReport from "@/services/plugins/generated_report";
import profileService from "@/services/plugins/profile";
import propertyService from "@/services/plugins/properties";

export default {
  name: "ManualGeneratingPayments",
  components: {
    TableLoader,
    ConfirmationDialog,
    ColorDetails,
    ColorShow,
    PriceEditor
  },
  mixins: [breadItemsSetLang, colorMixin, paginationMixin, checkUserPermission],
  data() {
    return {
      isLoading: false,
      dialog: false,
      alert: false,
      invoiceAlert: false,
      profileFilterAlert: false,
      tempProfiles: null,
      isProfileEditedManually: false,
      propertyFilterAlert: false,
      tempProperties: null,
      isPropertyEditedManually: false,
      date: new Date().toISOString().slice(0, 7),
      selectedDate: new Date().toISOString().slice(0, 7),
      datePickerMenu: false,
      expand: true,
      notGeneratedActualPaidAmount: reformatAmountWithDecimals(0),
      notGeneratedPaymentsTotalAmount: reformatAmountWithDecimals(0),
      notGeneratedPaymentsTotalCount: 0,
      notGeneratedPaymentsTotalOriginalAmount: reformatAmountWithDecimals(0),
      notGeneratedPaymentsTotalNetAmount: reformatAmountWithDecimals(0),
      notGeneratedPaymentList: [],
      row: "",
      selectAllProperties: false,
      profiles: [],
      properties: [],
      selectedProperties: [],
      selectedProfiles: [],
      isLoadingProfiles: false,
      isLoadingProperties: false,
      editedInvoiceAlert: false,
      fetchProperties: null,
      fetchProfiles: null,
      editedPaymentsList: []
    };
  },
  provide() {
    return {
      parentValidator: this.$validator
    };
  },
  watch: {
    "$i18n.locale": function () {
      this.$validator.localize(this.$i18n.locale, this.dictionary);
    },
    row() {
      this.fetchPayments(false);
    },
    notGeneratedPaymentList: {
      deep: true,
      handler() {
        this.updateGrandActualPaidAmountTotal();
        this.updateGrandNetAmountTotal();
      }
    },
    isAllPropertiesSelected() {
      this.isAllPropertiesSelected
        ? (this.selectAllProperties = true)
        : (this.selectAllProperties = false);
    },
    selectedProfiles(val, oldVal) {
      if (
        !(this.notGeneratedPaymentList.length || this.editedPaymentsList.length)
      )
        return;

      if (this.isProfileEditedManually) {
        this.isProfileEditedManually = false;
        return;
      }
      const diff = [
        ...val.filter(x => !oldVal.includes(x)),
        ...oldVal.filter(x => !val.includes(x))
      ];

      this.tempProfiles = diff;
      if (this.tempProfiles.length) this.profileFilterAlert = true;
    },
    selectedProperties(val, oldVal) {
      if (
        !(this.notGeneratedPaymentList.length || this.editedPaymentsList.length)
      )
        return;

      if (this.isPropertyEditedManually) {
        this.isPropertyEditedManually = false;
        return;
      }

      const diff = [
        ...val.filter(x => !oldVal.includes(x)),
        ...oldVal.filter(x => !val.includes(x))
      ];

      this.tempProperties = diff;
      if (this.tempProperties.length) this.propertyFilterAlert = true;
    },
    "notGeneratedPaymentList.length"() {
      this.$validator.localize(this.$i18n.locale, this.dictionary);
    }
  },
  mounted() {
    this.setDefaultPagination(100);
    this.fetchProperties = debounce(this.loadProperties, 500);
    this.fetchProfiles = debounce(this.loadProfiles, 500);
    this.loadProperties();
    this.loadProfiles();
    this.$validator.localize("en", this.dictionary);
  },
  computed: {
    headers() {
      return [
        {
          text: "",
          align: "center",
          sortable: false,
          width: "70"
        },
        {
          text: "",
          align: "center",
          sortable: false,
          width: "52-22"
        },
        {
          text: this.$t("Property"),
          align: "center",
          sortable: false,
          width: "70"
        },
        {
          text: this.$t("Unit"),
          align: "center",
          sortable: false,
          width: "70"
        },
        {
          text: this.$t("Contract No."),
          align: "center",
          sortable: false,
          width: "96"
        },
        {
          text: this.$t("Start Date"),
          align: "center",
          sortable: false,
          width: "94"
        },
        {
          text: this.$t("End Date"),
          align: "center",
          sortable: false,
          width: "96"
        },
        {
          text: this.$t("Due Date"),
          align: "center",
          sortable: false,
          width: "123"
        },
        {
          text: this.$t("Termination"),
          align: "center",
          sortable: false,
          width: "123"
        },
        {
          text: this.$t("Original AMT"),
          align: "center",
          sortable: false,
          width: "94"
        },
        {
          text: this.$t("Payment AMT"),
          align: "center",
          sortable: false,
          width: "94"
        },
        {
          text: this.$t("Discount"),
          align: "center",
          sortable: false,
          width: "94"
        },
        {
          text: this.$t("Paid AMT"),
          align: "center",
          sortable: false,
          width: "94"
        },
        {
          text: this.$t("Actual Paid AMT"),
          align: "center",
          sortable: false,
          width: "170"
        },
        this.getIsCountrySupportsVat
          ? {
              text: this.$t("Vat Amount"),
              align: "center",
              sortable: false,
              width: "94"
            }
          : null,
        {
          text: this.$t("Net Amount"),
          align: "center",
          sortable: false,
          width: "94"
        },
        {
          text: this.$t("Tenant Name"),
          align: "center",
          sortable: false,
          width: "94"
        },
        {
          text: this.$t("Phone"),
          align: "center",
          sortable: false,
          width: "116"
        },
        {
          text: this.$t("Civil ID"),
          align: "center",
          sortable: false,
          width: "116"
        }
      ].filter(item => item !== null);
    },
    isAllPropertiesSelected() {
      return this.selectedProperties.length === this.properties.length;
    },
    displaySelectedProfiles() {
      return this.selectedProfiles.map(e => e.profile_name).join(", ");
    },
    displaySelectedProperties() {
      return this.selectedProperties.map(e => e.property_name).join(", ");
    },
    formatSelectedDate() {
      const defaultLastDay = moment(this.selectedDate)
        .endOf("month")
        .format("DD");

      return `${this.selectedDate.slice(0, 4)}-${this.selectedDate.slice(
        5,
        7
      )}-${this.selectedDate.slice(8, 10) || defaultLastDay}`;
    },
    ...mapGetters("config", [
      "getCurrencyCode",
      "getDecimalCount",
      "getIsCountrySupportsVat"
    ]),
    dictionary() {
      const actualPaidMessages = {};
      const messagesCollection = {
        required: () => this.$t("This field is required"),
        max_value: (fName, params) =>
          `${this.$t(
            "Actual paid amount should be less than or equal to the original amount"
          )} ${params[0]}`,
        min_value: (fName, params) =>
          `${this.$t("Actual paid amount should not be less than")} ${
            params[0]
          }`,
        decimal: () =>
          this.$t("Decimal value can contain upto") +
          ` (${this.getDecimalCount}) ` +
          this.$t("digits")
      };
      this.notGeneratedPaymentList.forEach(contractEl => {
        actualPaidMessages[
          `actual_paid_amount_${contractEl.currentInvoice.contract_id}_${contractEl.currentInvoice.payment_due_date}`
        ] = {
          ...messagesCollection
        };
        contractEl.previousInvoivces.forEach(prevInvoice => {
          actualPaidMessages[
            `actual_paid_amount_${prevInvoice.contract_id}_${prevInvoice.payment_due_date}`
          ] = {
            ...messagesCollection
          };
        });
      });

      return {
        custom: {
          actual_paid_amount: {
            ...messagesCollection
          },
          ...actualPaidMessages
        }
      };
    }
  },
  methods: {
    resetTables() {
      this.resetNotGeneratedPayments();
      this.editedPaymentsList = [];
    },
    acceptSelectedProfile() {
      this.resetTables();
      this.profileFilterAlert = false;
    },
    removeSelectedProfile() {
      this.tempProfiles.forEach(tempProf => {
        let index = this.selectedProfiles.findIndex(
          profile => profile.id === tempProf.id
        );
        if (index !== -1) {
          this.selectedProfiles.splice(index, 1);
        } else {
          this.selectedProfiles.push(tempProf);
        }
      });
      this.profileFilterAlert = false;
      this.isProfileEditedManually = true;
    },
    acceptSelectedProperty() {
      this.resetTables();
      this.propertyFilterAlert = false;
    },
    removeSelectedProperty() {
      this.tempProperties.forEach(tempProperty => {
        let index = this.selectedProperties.findIndex(
          property => property.id === tempProperty.id
        );
        if (index !== -1) {
          this.selectedProperties.splice(index, 1);
        } else {
          this.selectedProperties.push(tempProperty);
        }
      });
      this.propertyFilterAlert = false;
      this.isPropertyEditedManually = true;
    },
    defaultFormatted(value) {
      return defaultFormat(value);
    },
    closeDialog() {
      this.dialog = false;
    },
    showInvoiceAlert() {
      this.invoiceAlert = true;
    },
    changeDate() {
      this.datePickerMenu = false;
      if (
        !(this.notGeneratedPaymentList.length || this.editedPaymentsList.length)
      )
        this.selectedDate = this.date;
      else this.alert = true;
    },
    confirmDialog() {
      this.selectedDate = this.date;
      this.resetTables();
    },
    confirmEditedDialog() {
      this.editedInvoiceAlert = false;
      this.clearEditedPaymentsAndFetchPayments();
    },
    applyFilters() {
      if (this.editedPaymentsList.length) {
        this.editedInvoiceAlert = true;
      } else {
        this.clearEditedPaymentsAndFetchPayments();
      }
    },
    clearEditedPayments() {
      const tempEditedList = [...this.editedPaymentsList];
      tempEditedList.forEach(editedInvoice =>
        this.removeItemFromEditedPayments(editedInvoice)
      );
    },
    clearEditedPaymentsAndFetchPayments() {
      this.clearEditedPayments();
      this.pagination.page = 1;
      this.fetchPayments();
    },
    reshapeContractInvoicesList(contract) {
      const { contract_precalculated_payments, ...rest } = contract;
      let newContract = { ...rest };
      let newPaymentList = [...contract_precalculated_payments].reverse();
      let currentInvoice = { ...rest, ...newPaymentList.shift() };
      let previousInvoivces = newPaymentList.map(invoice => ({
        ...invoice,
        ...rest
      }));
      if (this.editedPaymentsList.length) {
        const index = this.editedPaymentsList.findIndex(
          e =>
            e.contract_id === contract.contract_id &&
            e.payment_due_date === currentInvoice.payment_due_date
        );
        if (index !== -1) {
          currentInvoice.actual_paid_amount =
            this.editedPaymentsList[index].actual_paid_amount;
        }
      }
      previousInvoivces.forEach(previousInvoice => {
        const index = this.editedPaymentsList.findIndex(
          e =>
            e.contract_id === previousInvoice.contract_id &&
            e.payment_due_date === previousInvoice.payment_due_date
        );
        if (index !== -1) {
          previousInvoice.actual_paid_amount =
            this.editedPaymentsList[index].actual_paid_amount;
        }
      });
      newContract = { ...newContract, currentInvoice, previousInvoivces };
      return newContract;
    },

    expandAll() {
      let expandObject = {};
      for (const contract of this.notGeneratedPaymentList) {
        if (contract.previousInvoivces.length > 0)
          expandObject[contract.contract_id] = true;
      }
      this.$refs.notGeneratedTable.expanded = expandObject;
    },

    async fetchPayments(showLoading) {
      if (this.row === "") {
        this.setDefaultPagination(100);
      }
      try {
        if (!showLoading) {
          this.isLoading = true;
        }

        this.resetNotGeneratedPayments();
        const selected_date = this.formatSelectedDate;
        const notgeneratedParams = {
          ...this.setPaginationParams(),
          selected_date,
          profile_id: this.selectedProfiles.map(e => e.id).join(),
          property_id: this.selectedProperties.map(e => e.id).join()
        };

        const notGeneratedPaymentResp = await this.$processReq(
          invoices.fetchNotGeneratedPaymentsList(notgeneratedParams),
          false
        );

        const notGeneratedPaymentList =
          notGeneratedPaymentResp.data.body.results.data;
        this.notGeneratedPaymentsTotalCount =
          notGeneratedPaymentResp.data.body.results.total_invoices_count;
        this.notGeneratedPaymentsTotalAmount = reformatAmountWithDecimals(
          notGeneratedPaymentResp.data.body.results.total_actual_paid_amount ||
            0
        );
        this.notGeneratedPaymentsTotalOriginalAmount =
          reformatAmountWithDecimals(
            notGeneratedPaymentResp.data.body.results
              .total_original_contract_amount || 0
          );
        this.notGeneratedPaymentsTotalNetAmount = reformatAmountWithDecimals(
          notGeneratedPaymentResp.data.body.results.toatal_net_amount || 0
        );

        this.notGeneratedPaymentList = notGeneratedPaymentList.map(contract => {
          const reformattedPaymentList = transformContractPrecalculatedPayments(
            contract.contract_precalculated_payments
          );
          let newContract = {
            ...contract,
            contract_precalculated_payments: reformattedPaymentList
          };

          return this.reshapeContractInvoicesList(newContract);
        });
        this.setPagination(notGeneratedPaymentResp.data.body.count);
        this.showAllPagination();
        this.isLoading = false;
        setTimeout(() => {
          if (!this.isLoading && this.$refs.notGeneratedTable) {
            this.expandAll();
          }
        }, 500);
      } catch (err) {
        this.isLoading = false;
        console.log("Error: Unable to fetch payment list - ", err);
      }
    },

    async generatePayments() {
      if (await this.$validator.validateAll()) {
        try {
          this.isLoading = true;

          const contracts_payments_data_list = this.editedPaymentsList.map(
            invoice => ({
              contract_id: invoice.contract_id,
              paid_amount: invoice.payment_due_amount,
              amount: invoice.actual_paid_amount,
              is_missed: String(invoice.is_missed ? 1 : 0),
              payment_due_date: invoice.payment_due_date,
              paid_amount_before_discount: invoice.payment_amount,
              discount: invoice.payment_discount,
              vat_amount: invoice.payment_vat_amount,
              net_amount: invoice.payment_net_amount
            })
          );

          const payment_due_date = this.formatSelectedDate;
          const params = {
            payment_due_date,
            contracts_payments_data_list,
            profile_id: this.selectedProfiles.map(e => e.id).join(),
            property_id: this.selectedProperties.map(e => e.id).join()
          };

          await this.$processReq(invoices.createPayments(params), false);

          this.$root.$emit("snackbar", {
            text: this.$t("Your request is in progress"),
            color: "info"
          });

          this.editedPaymentsList = [];
          this.resetNotGeneratedPayments();

          this.isLoading = false;
        } catch (err) {
          this.isLoading = false;
          console.log("Error: Unable to generate payment - ", err);
        }
      } else {
        this.$root.$emit("snackbar", {
          text: this.$t(
            "Actual paid amount should be less than or equal to the maximum contract amount"
          ),
          color: "error"
        });
      }
    },
    resetNotGeneratedPayments() {
      this.notGeneratedPaymentList = [];
      this.notGeneratedPaymentsTotalAmount = reformatAmountWithDecimals(0);
      this.notGeneratedPaymentsTotalCount = 0;
      this.notGeneratedPaymentsTotalOriginalAmount =
        reformatAmountWithDecimals(0);
      this.notGeneratedPaymentsTotalNetAmount = reformatAmountWithDecimals(0);
    },
    async printPayment() {
      this.isLoading = true;
      try {
        const contracts_payments_data_list = this.editedPaymentsList.map(
          invoice => ({
            contract_id: invoice.contract_id,
            paid_amount: invoice.payment_due_amount,
            amount: invoice.actual_paid_amount,
            is_missed: String(invoice.is_missed ? 1 : 0),
            payment_due_date: invoice.payment_due_date,
            paid_amount_before_discount: invoice.payment_amount,
            discount: invoice.payment_discount
          })
        );

        const selected_date = this.formatSelectedDate;
        const data = {
          selected_date,
          contracts_payments_data_list,
          profile_id: this.selectedProfiles.map(e => e.id).join(),
          property_id: this.selectedProperties.map(e => e.id).join()
        };

        const resp = await this.$processReq(
          generatedReport.printPayments(data),
          false
        );

        this.isLoading = false;
        this.$root.$emit("snackbar", {
          text: resp.data?.body,
          color: "success"
        });
      } catch (err) {
        this.isLoading = false;
        console.log("Error: Unable to print payment - ", err);
      }
    },
    async loadProperties(event) {
      try {
        this.isLoadingProperties = true;

        let params = {
          limit: 10,
          offset: 0,
          profile: this.selectedProfiles
        };

        if (event) {
          params = {
            ...params,
            search: event?.target?.value || ""
          };
        }
        const resp = await this.$processReq(
          propertyService.getPropertiesList(params),
          false
        );

        this.properties = [
          ...this.selectedProperties,
          ...resp.data.body.results
        ];
        this.isLoadingProperties = false;
      } catch (err) {
        this.isLoadingProperties = false;
        console.log("Error: Unable to get properties list - ", err);
      }
    },
    async loadProfiles(event) {
      try {
        this.isLoadingProfiles = true;

        let params = {
          limit: 10,
          offset: 0
        };

        if (event) {
          params = {
            ...params,
            search: event.target.value || ""
          };
        }
        const resp = await this.$processReq(
          profileService.getProfileList(params),
          false
        );

        this.profiles = [...this.selectedProfiles, ...resp.data.body.results];
        this.isLoadingProfiles = false;
      } catch (err) {
        this.isLoadingProfiles = false;
        console.log("Error: Unable to get contract list - ", err);
      }
    },
    toggle() {
      if (this.isAllPropertiesSelected) {
        this.selectedProperties = [];
        this.selectAllProperties = false;
      } else {
        this.selectedProperties = [...this.properties];
        this.selectAllProperties = true;
      }
    },
    addEditedPayment(invoice, originalAmount) {
      let isValidAmount = true;
      if (
        parseFloat(invoice.actual_paid_amount) > parseFloat(originalAmount) ||
        parseFloat(invoice.actual_paid_amount) < 1 ||
        !parseFloat(invoice.actual_paid_amount) ||
        !checkMaxAllowedFractionDigits(invoice.actual_paid_amount)
      ) {
        isValidAmount = false;
      }
      const updatedInvoice = this.updateInvoiceAmounts(
        invoice,
        invoice.actual_paid_amount
      );
      this.setOriginalInvoiceDetails(updatedInvoice);

      const index = this.editedPaymentsList.findIndex(
        e =>
          e.contract_id === updatedInvoice.contract_id &&
          e.payment_due_date === updatedInvoice.payment_due_date
      );

      if (!isValidAmount) {
        if (index !== -1) {
          this.editedPaymentsList.splice(index, 1);
        }
        return;
      }

      if (index === -1) {
        this.editedPaymentsList.push(updatedInvoice);
      } else {
        this.editedPaymentsList.splice(index, 1, updatedInvoice);
      }
    },
    removeItemFromEditedPayments(invoice) {
      const index = this.editedPaymentsList.findIndex(
        e =>
          e.contract_id === invoice.contract_id &&
          e.payment_due_date === invoice.payment_due_date
      );
      if (index !== -1) {
        this.editedPaymentsList.splice(index, 1);
        /**
         * It's crusial to reset the
         * [actual paid amount, payment_vat_amount, payment_net_amount] values
         * To the original values based on [invoice.payment_due_amount] value
         * after the corresponding edited invoice had been removed.
         */
        const originalInvoice = this.updateInvoiceAmounts(
          invoice,
          invoice.payment_due_amount
        );
        this.setOriginalInvoiceDetails(originalInvoice);
      }
    },
    confirmEditedInvoice(invoice) {
      const isEdited = this.editedPaymentsList.some(
        paymentEl =>
          paymentEl.contract_id === invoice.contract_id &&
          paymentEl.payment_due_date === invoice.payment_due_date
      );
      return isEdited;
    },
    setOriginalInvoiceDetails(updatedInvoice) {
      this.notGeneratedPaymentList = this.notGeneratedPaymentList.map(
        contract => {
          if (updatedInvoice.contract_id === contract.contract_id) {
            if (
              updatedInvoice.payment_due_date ===
              contract.currentInvoice.payment_due_date
            ) {
              return {
                ...contract,
                currentInvoice: updatedInvoice
              };
            } else {
              return {
                ...contract,
                previousInvoivces: contract.previousInvoivces.map(prevInv => {
                  if (
                    updatedInvoice.payment_due_date === prevInv.payment_due_date
                  ) {
                    return updatedInvoice;
                  } else return prevInv;
                })
              };
            }
          } else return contract;
        }
      );
    },
    /**
     * A specific function for updating invoice
     * [actual paid amount, payment_vat_amount, payment_net_amount] values
     *  based on the second argument value.
     */
    updateInvoiceAmounts(invoice, amount) {
      const actAmount = amount;
      const newVatAmount = invoice.property_is_vat_enabled
        ? calculateVatAmount(actAmount)
        : reformatAmountWithDecimals(0);
      const newNetAmount = calculateNetAmount(actAmount, newVatAmount);
      return {
        ...invoice,
        actual_paid_amount: actAmount,
        payment_vat_amount: newVatAmount,
        payment_net_amount: newNetAmount
      };
    },
    updateGrandActualPaidAmountTotal() {
      const newCalculatedActualPaidAmount = this.notGeneratedPaymentList.reduce(
        (result, contract) => {
          const contractNewTotalActualPaidAmount =
            parseFloat(contract.currentInvoice.actual_paid_amount) +
            contract.previousInvoivces.reduce(
              (prevInvoicesTotal, invoice) =>
                prevInvoicesTotal + parseFloat(invoice.actual_paid_amount),
              0
            );
          return result + contractNewTotalActualPaidAmount;
        },
        0
      );
      this.notGeneratedActualPaidAmount = reformatAmountWithDecimals(
        newCalculatedActualPaidAmount
      );
    },
    updateGrandNetAmountTotal() {
      const totalCalculatedNetAmount = this.notGeneratedPaymentList.reduce(
        (result, contract) => {
          const totalNetAmount =
            parseFloat(contract.currentInvoice.payment_net_amount) +
            contract.previousInvoivces.reduce(
              (prevInvoicesTotal, invoice) =>
                prevInvoicesTotal + parseFloat(invoice.payment_net_amount),
              0
            );
          return result + totalNetAmount;
        },
        0
      );
      this.notGeneratedPaymentsTotalNetAmount = reformatAmountWithDecimals(
        totalCalculatedNetAmount
      );
    }
  }
};
</script>

<style scoped lang="scss">
img {
  margin: 6px;
}
.ml-5 {
  margin-left: 5px !important;
}
.re-width-61 {
  min-width: 61px !important;
  max-width: 61px !important;
}
.re-width-70 {
  min-width: 70px !important;
  max-width: 70px !important;
}
.re-width-74 {
  min-width: 74px !important;
  max-width: 74px !important;
}
.re-width-85 {
  min-width: 85px !important;
  max-width: 85px !important;
}
.re-width-77 {
  min-width: 77px !important;
  max-width: 77px !important;
}
.re-width-51 {
  min-width: 51px !important;
  max-width: 51px !important;
}
.re-width-55 {
  min-width: 55px !important;
  max-width: 55px !important;
}
.re-width-52-22 {
  min-width: 52.22px !important;
  max-width: 52.22px !important;
}
.re-width-90 {
  min-width: 90px !important;
  max-width: 90px !important;
}
.re-width-52 {
  min-width: 52px !important;
  max-width: 52px !important;
}
.re-width-88 {
  min-width: 88px !important;
  max-width: 88px !important;
}
.re-width-94 {
  min-width: 94px !important;
  max-width: 94px !important;
}
.re-width-96 {
  min-width: 96px !important;
  max-width: 96px !important;
}
.re-width-100 {
  min-width: 100px !important;
  max-width: 100px !important;
}
.re-width-105 {
  min-width: 105px !important;
  max-width: 105px !important;
}
.re-width-116 {
  min-width: 116px !important;
  max-width: 116px !important;
}
.re-width-123 {
  min-width: 123px !important;
  max-width: 123px !important;
}
.re-width-125 {
  min-width: 125px !important;
  max-width: 125px !important;
}
.re-width-170 {
  min-width: 170px !important;
  max-width: 170px !important;
}

.text-xs-center {
  padding: 0 12px !important;
}
.text-white >>> .v-text-field__slot input {
  color: white !important;
}
.inner-payments-card {
  padding: 0px !important;
}
.td-icon {
  padding: 0px !important;
  margin: 0px !important;
}
.input-max-width {
  max-width: 123px !important;
  margin: auto;
}
.rotate-90 {
  transform: rotate(90deg);
}
.rotate-90-back {
  transform: rotate(-90deg);
}
.table-container {
  box-shadow: 0 3px 1px -2px RGB(0 0 0 / 20%), 0 2px 2px 0 RGB(0 0 0 / 14%),
    0 1px 5px 0 RGB(0 0 0 / 12%);
}
::v-deep .highlight-text-is-missed:hover {
  background-color: #302f37 !important;
}
::v-deep .default-color:hover {
  background-color: #ffffff !important;
}
::v-deep .highlight-text-is-suspended:hover {
  background-color: #f0252b !important;
}

::v-deep .highlight-text-open-balance:hover {
  background-color: #0a82be !important;
}
::v-deep .higlight-text-is-grace:hover {
  background-color: #f77d1a !important;
}
.clearbtn {
  min-width: max-content;
  min-height: max-content;
  color: #fff !important;
  background-color: #f57829 !important;

  font-weight: 500;
  font-size: 14px;
}
.edited-payments {
  padding-bottom: 1rem;
  .reheading-like {
    padding: 0;
  }
}
.payments {
  .re-custom-input {
    border-radius: 20px;
  }
  ::v-deep .v-table td {
    margin: 0 !important;
    padding: 0 !important;
  }
  ::v-deep .v-table th {
    margin: 0 !important;
    padding: 0 !important;
  }
  ::v-deep .v-input__slot {
    border-radius: 20px;
    background: #f2f2f2 !important;
    &:before {
      border: none;
    }
  }
}
.filter-type {
  max-width: 300px;
}
.re-datepicker {
  max-height: 64px;
}
.v-window-item {
  text-align: center;

  .v-picker {
    box-shadow: none;
  }
}
.filter-details {
  background: #e9ebef;
  border-radius: 15px;
  padding: 1rem;
  .heading {
    font-size: 1.5rem;
    font-weight: bold;
    padding-bottom: 1rem;
  }
}
</style>
