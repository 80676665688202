<template>
  <div class="add-tenant">
    <v-layout class="pt-5">
      <v-flex grow pa-1 xs6>
        <h2 class="display-2 pl-4">{{ $t("Edit Tenant") }}</h2>
      </v-flex>
      <v-flex shrink pa-1 xs6 text-xs-right>
        <v-btn @click="globalGoBack" dark class="mt-4 r-m-end"
          >← {{ $t("Back") }}</v-btn
        >
      </v-flex>
    </v-layout>
    <v-layout class="r-p-24px">
      <v-flex class="flex-wrap" xs12 pa-1>
        <v-breadcrumbs :items="breadItemsSetLang()">
          <template v-slot:divider>
            <v-icon>fiber_manual_record</v-icon>
          </template>
        </v-breadcrumbs>
      </v-flex>
    </v-layout>
    <v-layout row wrap justify-center>
      <v-layout row wrap justify-center>
        <v-flex xs12 sm12 lg9>
          <v-card class="pb-4" v-if="!isLoading">
            <v-card-title>
              <h3>{{ $t("Edit Tenant") }}</h3>
            </v-card-title>
            <v-card-text>
              <v-form>
                <v-select
                  v-model="tenant.type"
                  :items="tenantTypes"
                  item-text="label"
                  item-value="value"
                  :label="$t('Tenant Type')"
                  class="mb-2"
                  @change="isMarried = false"
                />

                <v-text-field
                  v-model="tenant.tenant_name_en"
                  v-validate="'required|alpha_spaces'"
                  :label="$t('Tenant Name (EN)')"
                  class="mb-2"
                  data-vv-name="tenant_name"
                  :error-messages="errors.collect('tenant_name')"
                />

                <v-text-field
                  v-model="tenant.tenant_name_ar"
                  v-validate="'required'"
                  :label="$t('Tenant Name (AR)')"
                  class="mb-2"
                  data-vv-name="tenant_name_ar"
                  :error-messages="errors.collect('tenant_name_ar')"
                />

                <v-text-field
                  v-if="checkCompanySelected('RESIDENTIAL')"
                  v-model="tenant.tenant_info.civil_id_number"
                  v-validate="`required|alpha_num`"
                  :label="$t('Civil ID Number')"
                  class="mb-4"
                  key="civil_id_number"
                  data-vv-name="civil_id_number"
                  :error-messages="errors.collect('civil_id_number')"
                />

                <v-text-field
                  v-if="
                    checkCompanySelected('LIMITED_LIABILITY_COMPANY') ||
                    checkCompanySelected('LISTED_COMPANY')
                  "
                  v-model="tenant.tenant_info.civil_id_number"
                  v-validate="`alpha_num`"
                  :label="$t('Civil ID')"
                  class="mb-4"
                  key="civil_id"
                  data-vv-name="civil_id"
                  :error-messages="errors.collect('civil_id')"
                />

                <v-layout
                  v-if="checkCompanySelected('RESIDENTIAL')"
                  class="align-center mb-3"
                >
                  <span class="re-upload__span">{{
                    $t("Upload Civil ID (Front)")
                  }}</span>
                  <input
                    type="file"
                    id="uploadCivilIdFront"
                    style="display: none"
                    ref="uploadCivilIdFront"
                    accept="image/*"
                    @change="
                      event => uploadImage(event, 'civil_id_front', true)
                    "
                  />
                  <v-btn
                    @click="$refs.uploadCivilIdFront.click()"
                    class="re-custom-file-input"
                    >{{ $t("Upload") }}</v-btn
                  >
                  <span
                    v-if="
                      tenant.tenant_info.civil_id_front &&
                      tenant.tenant_info.civil_id_front.tenant_image
                    "
                  >
                    {{
                      getImageName(
                        tenant.tenant_info.civil_id_front.tenant_image
                      )
                    }}
                    <v-btn
                      flat
                      icon
                      color="blue"
                      @click="downloadImage(tenant.tenant_info.civil_id_front)"
                    >
                      <v-icon>download</v-icon>
                    </v-btn>
                  </span>
                </v-layout>

                <v-layout
                  v-if="checkCompanySelected('RESIDENTIAL')"
                  class="align-center"
                >
                  <span class="re-upload__span">{{
                    $t("Upload Civil ID (Back)")
                  }}</span>
                  <input
                    type="file"
                    id="uploadCivilIdBack"
                    style="display: none"
                    ref="uploadCivilIdBack"
                    accept="image/*"
                    @change="event => uploadImage(event, 'civil_id_back', true)"
                  />
                  <v-btn
                    @click="$refs.uploadCivilIdBack.click()"
                    class="re-custom-file-input"
                    >{{ $t("Upload") }}</v-btn
                  >
                  <span
                    v-if="
                      tenant.tenant_info.civil_id_back &&
                      tenant.tenant_info.civil_id_back.tenant_image
                    "
                  >
                    {{
                      getImageName(
                        tenant.tenant_info.civil_id_back.tenant_image
                      )
                    }}
                    <v-btn
                      flat
                      icon
                      color="blue"
                      @click="downloadImage(tenant.tenant_info.civil_id_back)"
                    >
                      <v-icon>download</v-icon>
                    </v-btn>
                  </span>
                </v-layout>
                <v-text-field
                  v-if="
                    checkCompanySelected('LIMITED_LIABILITY_COMPANY') ||
                    checkCompanySelected('LISTED_COMPANY')
                  "
                  v-model="tenant.tenant_info.commercial_registration_number"
                  v-validate="'required|alpha_num'"
                  :label="$t('Commercial Registration Number')"
                  class="mb-4"
                  data-vv-name="commercial_registration_number"
                  key="commercial_registration_number"
                  :error-messages="
                    errors.collect('commercial_registration_number')
                  "
                />
                <v-text-field
                  v-if="checkCompanySelected('RESIDENTIAL')"
                  v-model="tenant.tenant_info.passport_number"
                  v-validate="`alpha_num`"
                  :label="$t('Passport Number')"
                  class="mb-4"
                  data-vv-name="tenant_passport_number"
                  key="tenant_passport_number"
                  :error-messages="errors.collect('tenant_passport_number')"
                />

                <v-layout
                  class="align-center"
                  v-if="checkCompanySelected('RESIDENTIAL')"
                >
                  <span class="re-upload__span">{{
                    $t("Upload Passport")
                  }}</span>
                  <input
                    type="file"
                    id="uploadPassport"
                    style="display: none"
                    ref="uploadPassport"
                    accept="image/*"
                    @change="event => uploadImage(event, 'passport', true)"
                  />
                  <v-btn
                    class="re-custom-file-input"
                    @click="$refs.uploadPassport.click()"
                    >{{ $t("Upload") }}</v-btn
                  >
                  <span
                    v-if="
                      tenant.tenant_info.passport &&
                      tenant.tenant_info.passport.tenant_image
                    "
                  >
                    {{ getImageName(tenant.tenant_info.passport.tenant_image) }}
                    <v-btn
                      flat
                      icon
                      color="blue"
                      @click="downloadImage(tenant.tenant_info.passport)"
                    >
                      <v-icon>download</v-icon>
                    </v-btn>
                  </span>
                </v-layout>

                <v-autocomplete
                  v-if="checkCompanySelected('RESIDENTIAL')"
                  v-model="tenant.tenant_info.nationality"
                  v-validate="'required'"
                  :label="$t('Nationality')"
                  class="mb-4"
                  item-text="name"
                  item-value="name"
                  data-vv-name="nationality"
                  key="nationality"
                  :items="countries"
                  :error-messages="errors.collect('nationality')"
                />

                <vue-phone-number-input
                  v-model="tenant.phone"
                  name="tenat_phone_number"
                  :translations="translations"
                  :only-countries="phoneInputPreferredCountries"
                  :default-country-code="
                    tenant.tenant_info.country_code
                      ? tenant.tenant_info.country_code.replace(/[0-9]/g, '')
                      : defaultCountryCode
                  "
                  required
                  :error="!isValid"
                  :error-message="$t('This field is required')"
                  @update="updateCountryCode($event, true)"
                  @phone-number-focused="isTouched = true"
                />
                <span
                  v-if="!tenant.phone && isTouched"
                  class="red-text"
                  style="display: flex"
                  >{{ $t("Phone number is required") }}</span
                >
                <v-text-field
                  v-model="tenant.tenant_info.email"
                  v-validate="'required|email'"
                  :label="$t('Email Address')"
                  class="mb-2"
                  data-vv-name="email"
                  :error-messages="errors.collect('email')"
                />

                <v-select
                  v-model="tenant.preferred_language"
                  :label="$t('Select Notification Language')"
                  class="mb-4"
                  item-value="value"
                  item-text="label"
                  :items="language"
                />

                <v-layout
                  v-if="
                    checkCompanySelected('LIMITED_LIABILITY_COMPANY') ||
                    checkCompanySelected('LISTED_COMPANY')
                  "
                  class="align-center mb-3"
                >
                  <span class="re-upload__span">{{
                    $t("Upload Commercial Registration")
                  }}</span>
                  <input
                    type="file"
                    id="uploadCommercialRegistration"
                    style="display: none"
                    ref="uploadCommercialRegistration"
                    accept="image/*"
                    @change="
                      event =>
                        uploadImage(event, 'commercial_registration', true)
                    "
                  />
                  <v-btn
                    @click="$refs.uploadCommercialRegistration.click()"
                    class="re-custom-file-input"
                    >{{ $t("Upload") }}</v-btn
                  >
                  <span
                    v-if="
                      tenant.tenant_info.commercial_registration &&
                      tenant.tenant_info.commercial_registration.tenant_image
                    "
                  >
                    {{
                      getImageName(
                        tenant.tenant_info.commercial_registration.tenant_image
                      )
                    }}
                    <v-btn
                      flat
                      icon
                      color="blue"
                      @click="
                        downloadImage(
                          tenant.tenant_info.commercial_registration
                        )
                      "
                    >
                      <v-icon>download</v-icon>
                    </v-btn>
                  </span>
                </v-layout>

                <v-layout
                  v-if="checkCompanySelected('LISTED_COMPANY')"
                  class="align-center mb-3"
                >
                  <span class="re-upload__span">{{
                    $t("Upload Authorized Signatory Confirmation")
                  }}</span>
                  <input
                    type="file"
                    id="uploadAuthorizedSignatoryConfirmation"
                    style="display: none"
                    ref="uploadAuthorizedSignatoryConfirmation"
                    accept="image/*"
                    @change="
                      event =>
                        uploadImage(
                          event,
                          'authorized_signatory_confirmation',
                          true
                        )
                    "
                  />
                  <v-btn
                    @click="$refs.uploadAuthorizedSignatoryConfirmation.click()"
                    class="re-custom-file-input"
                    >{{ $t("Upload") }}</v-btn
                  >
                  <span
                    v-if="
                      tenant.tenant_info.authorized_signatory_confirmation &&
                      tenant.tenant_info.authorized_signatory_confirmation
                        .tenant_image
                    "
                  >
                    {{
                      getImageName(
                        tenant.tenant_info.authorized_signatory_confirmation
                          .tenant_image
                      )
                    }}
                    <v-btn
                      flat
                      icon
                      color="blue"
                      @click="
                        downloadImage(
                          tenant.tenant_info.authorized_signatory_confirmation
                        )
                      "
                    >
                      <v-icon>download</v-icon>
                    </v-btn>
                  </span>
                </v-layout>

                <v-layout
                  v-if="
                    checkCompanySelected('LIMITED_LIABILITY_COMPANY') ||
                    checkCompanySelected('LISTED_COMPANY')
                  "
                  class="align-center mb-3"
                >
                  <span class="re-upload__span">{{
                    $t("Upload Trade Licence")
                  }}</span>
                  <input
                    type="file"
                    id="uploadTradeLicence"
                    style="display: none"
                    ref="uploadTradeLicence"
                    accept="image/*"
                    @change="event => uploadImage(event, 'trade_licence', true)"
                  />
                  <v-btn
                    @click="$refs.uploadTradeLicence.click()"
                    class="re-custom-file-input"
                    >{{ $t("Upload") }}</v-btn
                  >
                  <span
                    v-if="
                      tenant.tenant_info.trade_licence &&
                      tenant.tenant_info.trade_licence.tenant_image
                    "
                  >
                    {{
                      getImageName(
                        tenant.tenant_info.trade_licence.tenant_image
                      )
                    }}
                    <v-btn
                      flat
                      icon
                      color="blue"
                      @click="downloadImage(tenant.tenant_info.trade_licence)"
                    >
                      <v-icon>download</v-icon>
                    </v-btn>
                  </span>
                </v-layout>

                <v-layout
                  v-if="
                    checkCompanySelected('LIMITED_LIABILITY_COMPANY') ||
                    checkCompanySelected('LISTED_COMPANY')
                  "
                  class="align-center mb-3"
                >
                  <span class="re-upload__span">{{
                    $t("Upload Authorized Signatory Name")
                  }}</span>
                  <input
                    type="file"
                    id="uploadAuthorizedSignatoryName"
                    style="display: none"
                    ref="uploadAuthorizedSignatoryName"
                    accept="image/*"
                    @change="
                      event =>
                        uploadImage(event, 'authorized_signatory_name', true)
                    "
                  />
                  <v-btn
                    @click="$refs.uploadAuthorizedSignatoryName.click()"
                    class="re-custom-file-input"
                    >{{ $t("Upload") }}</v-btn
                  >
                  <span
                    v-if="
                      tenant.tenant_info.authorized_signatory_name &&
                      tenant.tenant_info.authorized_signatory_name.tenant_image
                    "
                  >
                    {{
                      getImageName(
                        tenant.tenant_info.authorized_signatory_name
                          .tenant_image
                      )
                    }}
                    <v-btn
                      flat
                      icon
                      color="blue"
                      @click="
                        downloadImage(
                          tenant.tenant_info.authorized_signatory_name
                        )
                      "
                    >
                      <v-icon>download</v-icon>
                    </v-btn>
                  </span>
                </v-layout>

                <v-layout
                  v-if="
                    checkCompanySelected('LIMITED_LIABILITY_COMPANY') ||
                    checkCompanySelected('LISTED_COMPANY')
                  "
                  class="align-center mb-3"
                >
                  <span class="re-upload__span">{{
                    $t("Upload Attachment 1")
                  }}</span>
                  <input
                    type="file"
                    id="uploadAttachment1"
                    style="display: none"
                    ref="uploadAttachment1"
                    accept="image/*"
                    @change="event => uploadImage(event, 'attachment1', true)"
                  />
                  <v-btn
                    @click="$refs.uploadAttachment1.click()"
                    class="re-custom-file-input"
                    >{{ $t("Upload") }}</v-btn
                  >
                  <span
                    v-if="
                      tenant.tenant_info.attachment1 &&
                      tenant.tenant_info.attachment1.tenant_image
                    "
                  >
                    {{
                      getImageName(tenant.tenant_info.attachment1.tenant_image)
                    }}
                    <v-btn
                      flat
                      icon
                      color="blue"
                      @click="downloadImage(tenant.tenant_info.attachment1)"
                    >
                      <v-icon>download</v-icon>
                    </v-btn>
                  </span>
                </v-layout>

                <v-layout
                  v-if="
                    checkCompanySelected('LIMITED_LIABILITY_COMPANY') ||
                    checkCompanySelected('LISTED_COMPANY')
                  "
                  class="align-center mb-3"
                >
                  <span class="re-upload__span">{{
                    $t("Upload Attachment 2")
                  }}</span>
                  <input
                    type="file"
                    id="uploadAttachment2"
                    style="display: none"
                    ref="uploadAttachment2"
                    accept="image/*"
                    @change="event => uploadImage(event, 'attachment2', true)"
                  />
                  <v-btn
                    @click="$refs.uploadAttachment2.click()"
                    class="re-custom-file-input"
                    >{{ $t("Upload") }}</v-btn
                  >
                  <span
                    v-if="
                      tenant.tenant_info.attachment2 &&
                      tenant.tenant_info.attachment2.tenant_image
                    "
                  >
                    {{
                      getImageName(tenant.tenant_info.attachment2.tenant_image)
                    }}
                    <v-btn
                      flat
                      icon
                      color="blue"
                      @click="downloadImage(tenant.tenant_info.attachment2)"
                    >
                      <v-icon>download</v-icon>
                    </v-btn>
                  </span>
                </v-layout>

                <v-layout
                  v-if="
                    checkCompanySelected('LIMITED_LIABILITY_COMPANY') ||
                    checkCompanySelected('LISTED_COMPANY')
                  "
                  class="align-center mb-3"
                >
                  <span class="re-upload__span">{{
                    $t("Upload Attachment 3")
                  }}</span>
                  <input
                    type="file"
                    id="uploadAttachment3"
                    style="display: none"
                    ref="uploadAttachment3"
                    accept="image/*"
                    @change="event => uploadImage(event, 'attachment3', true)"
                  />
                  <v-btn
                    @click="$refs.uploadAttachment3.click()"
                    class="re-custom-file-input"
                    >{{ $t("Upload") }}</v-btn
                  >
                  <span
                    v-if="
                      tenant.tenant_info.attachment3 &&
                      tenant.tenant_info.attachment3.tenant_image
                    "
                  >
                    {{
                      getImageName(tenant.tenant_info.attachment3.tenant_image)
                    }}
                    <v-btn
                      flat
                      icon
                      color="blue"
                      @click="downloadImage(tenant.tenant_info.attachment3)"
                    >
                      <v-icon>download</v-icon>
                    </v-btn>
                  </span>
                </v-layout>

                <!-- Married -->
                <v-checkbox
                  v-if="checkCompanySelected('RESIDENTIAL')"
                  :label="$t('Married?')"
                  v-model="isMarried"
                  color="grey darken-1"
                />

                <div class="pl-4 re-married" v-show="isMarried">
                  <v-text-field
                    v-model="tenant.spouse_info.civil_id_number"
                    v-validate="`${isMarried ? 'required|' : ''}alpha_num`"
                    :label="$t('Civil ID Number')"
                    class="mb-4"
                    data-vv-name="spouse.civil_id_number"
                    :error-messages="errors.collect('spouse.civil_id_number')"
                  />

                  <v-layout class="align-center mb-2 ml-3">
                    <span class="re-upload__span">{{
                      $t("Upload Civil ID (Front)")
                    }}</span>
                    <input
                      type="file"
                      id="myUpload"
                      style="display: none"
                      ref="uploadSpouseCivilIdFront"
                      accept="image/*"
                      @change="event => uploadImage(event, 'civil_id_front')"
                    />
                    <v-btn
                      @click="$refs.uploadSpouseCivilIdFront.click()"
                      class="re-custom-file-input"
                      >{{ $t("Upload") }}</v-btn
                    >
                    <span
                      v-if="
                        tenant.spouse_info.civil_id_front &&
                        tenant.spouse_info.civil_id_front.tenant_image
                      "
                    >
                      {{
                        getImageName(
                          tenant.spouse_info.civil_id_front.tenant_image
                        )
                      }}
                      <v-btn
                        flat
                        icon
                        color="blue"
                        @click="
                          downloadImage(tenant.spouse_info.civil_id_front)
                        "
                      >
                        <v-icon>download</v-icon>
                      </v-btn>
                    </span>
                  </v-layout>

                  <v-layout class="align-center mb-2 ml-3">
                    <span class="re-upload__span">{{
                      $t("Upload Civil ID (Back)")
                    }}</span>
                    <input
                      type="file"
                      id="myUpload"
                      style="display: none"
                      ref="uploadSpouseCivilIdBack"
                      accept="image/*"
                      @change="event => uploadImage(event, 'civil_id_back')"
                    />
                    <v-btn
                      class="re-custom-file-input"
                      @click="$refs.uploadSpouseCivilIdBack.click()"
                      >{{ $t("Upload") }}</v-btn
                    >
                    <span
                      v-if="
                        tenant.spouse_info.civil_id_back &&
                        tenant.spouse_info.civil_id_back.tenant_image
                      "
                    >
                      {{
                        getImageName(
                          tenant.spouse_info.civil_id_back.tenant_image
                        )
                      }}
                      <v-btn
                        flat
                        icon
                        color="blue"
                        @click="downloadImage(tenant.spouse_info.civil_id_back)"
                      >
                        <v-icon>download</v-icon>
                      </v-btn>
                    </span>
                  </v-layout>

                  <v-text-field
                    v-model="tenant.spouse_info.passport_number"
                    v-validate="`alpha_num`"
                    :label="$t('Passport Number')"
                    class="mb-4"
                    data-vv-name="spouse.passport_number"
                    :error-messages="errors.collect('spouse.passport_number')"
                  />

                  <v-layout class="align-center mb-3 ml-3">
                    <span class="re-upload__span">{{
                      $t("Upload Passport")
                    }}</span>
                    <input
                      type="file"
                      id="myUpload"
                      style="display: none"
                      ref="uploadSpousePassport"
                      accept="image/*"
                      @change="event => uploadImage(event, 'passport')"
                    />
                    <v-btn
                      @click="$refs.uploadSpousePassport.click()"
                      class="re-custom-file-input re-ml-55"
                    >
                      {{ $t("Upload") }}</v-btn
                    >
                    <span
                      v-if="
                        tenant.spouse_info.passport &&
                        tenant.spouse_info.passport.tenant_image
                      "
                    >
                      {{
                        getImageName(tenant.spouse_info.passport.tenant_image)
                      }}
                      <v-btn
                        flat
                        icon
                        color="blue"
                        @click="downloadImage(tenant.spouse_info.passport)"
                      >
                        <v-icon>download</v-icon>
                      </v-btn>
                    </span>
                  </v-layout>

                  <v-autocomplete
                    v-model="tenant.spouse_info.nationality"
                    v-validate="`${isMarried ? 'required' : ''}`"
                    :label="$t('Nationality')"
                    class="mb-4"
                    item-text="name"
                    item-value="name"
                    data-vv-name="spouse.nationality"
                    :items="countries"
                    :loading="isLoadingCountries"
                    :no-data-text="`${
                      isLoadingCountries
                        ? $t('Loading...')
                        : $t('No data available')
                    }`"
                    :error-messages="errors.collect('spouse.nationality')"
                  />
                  <vue-phone-number-input
                    v-model="tenant.spouse_phone"
                    :translations="translations"
                    :only-countries="phoneInputPreferredCountries"
                    :error="!isValidSpouse"
                    :error-message="$t('This field is required')"
                    :default-country-code="
                      tenant.spouse_info.country_code
                        ? tenant.spouse_info.country_code.replace(/[0-9]/g, '')
                        : defaultCountryCode
                    "
                    @update="updateCountryCode($event, false)"
                    @phone-number-focused="spousePhone = true"
                  />

                  <v-text-field
                    v-model="tenant.spouse_info.email"
                    v-validate="`${isMarried ? 'email' : ''}`"
                    :label="$t('Email Address')"
                    class="mb-2"
                    data-vv-name="spouse_email"
                    :error-messages="errors.collect('spouse_email')"
                  />
                  <v-layout class="align-center mb-3 ml-3">
                    <span class="re-upload__span">{{
                      $t("Upload Mariage Certificate")
                    }}</span>
                    <input
                      type="file"
                      id="myUpload"
                      style="display: none"
                      ref="uploadMariageCertificate"
                      accept="image/*"
                      @change="
                        event => uploadImage(event, 'marriage_certificate')
                      "
                    />
                    <v-btn
                      @click="$refs.uploadMariageCertificate.click()"
                      class="re-custom-file-input re-ml-55"
                    >
                      {{ $t("Upload") }}</v-btn
                    >
                    <span
                      v-if="
                        tenant.spouse_info.marriage_certificate &&
                        tenant.spouse_info.marriage_certificate.tenant_image
                      "
                    >
                      {{
                        getImageName(
                          tenant.spouse_info.marriage_certificate.tenant_image
                        )
                      }}
                      <v-btn
                        flat
                        icon
                        color="blue"
                        @click="
                          downloadImage(tenant.spouse_info.marriage_certificate)
                        "
                      >
                        <v-icon>download</v-icon>
                      </v-btn>
                    </span>
                  </v-layout>
                </div>
              </v-form>
            </v-card-text>
          </v-card>
          <vue-skeleton-loader
            rounded
            class="width100"
            type="rect"
            v-if="isLoading"
            :width="'100%'"
            :height="'800px'"
            animation="fade"
          />
        </v-flex>
      </v-layout>
    </v-layout>
    <div class="text-xs-center mt-4">
      <v-btn
        dark
        class="re-orangtbtn"
        :disabled="isUploadingFile"
        @click="saveTenantDetails"
        >{{ $t("Save") }}</v-btn
      >
      <v-btn @click="$router.push({ name: 'tenant_management' })" dark>{{
        $t("Cancel")
      }}</v-btn>
    </div>
  </div>
</template>

<script>
import { isEmpty } from "lodash";
import cloneDeep from "lodash/cloneDeep";
import { mapGetters } from "vuex";

import tenantService from "../../services/plugins/tenant";
import tenant from "../../services/plugins/tenant";

import breadItemsSetLang from "@/mixins/breadItemsSetLang";
import checkUserPermission from "@/mixins/checkUserPermission";

export default {
  name: "edit-tenant",
  $_veeValidate: {
    validator: "new"
  },
  mixins: [breadItemsSetLang, checkUserPermission],
  data() {
    return {
      isMarried: false,
      countries: [],
      isEdit: false,
      isTouched: false,
      spousePhone: false,
      isLoading: false,
      isUploadingFile: false,
      tenant: {
        preferred_language: "ar",
        tenant_info: {},
        spouse_info: {},
        type: "RESIDENTIAL"
      },
      isValid: true,
      isValidSpouse: true,
      isLoadingCountries: false
    };
  },
  async created() {
    const params = this.$route.params;
    this.$validator.localize("en", this.dictionary);
    if (params.id) {
      this.isEdit = true;

      await this.fetchTenatDetails(params.id);
    }
    await this.fetchCountriesList();
  },
  computed: {
    language() {
      return [
        {
          label: this.$t("Arabic"),
          value: "ar"
        },
        {
          label: this.$t("English"),
          value: "en"
        }
      ];
    },
    dictionary() {
      return {
        custom: {
          tenant_name: {
            required: () => this.$t("Tenant name is Required")
          },
          tenant_name_ar: {
            required: () => this.$t("Tenant name(AR) is required")
          },
          civil_id_number: {
            required: () => this.$t("Tenant civil id is required"),
            alpha_num: () =>
              this.$t("Tenant civil id cannot contain special characters")
          },
          civil_id: {
            required: () => this.$t("Tenant civil id is required"),
            alpha_num: () =>
              this.$t("Tenant civil id cannot contain special characters")
          },
          commercial_registration_number: {
            required: () => this.$t("Commercial registration is required")
          },
          nationality: {
            required: () => this.$t("Tenant nationality is required")
          },
          tenant_passport_number: {
            required: () => this.$t("Passport number is required"),
            alpha_num: () =>
              this.$t("Passport number cannot contain special characters")
          },
          "spouse.civil_id_number": {
            required: () => this.$t("Spouse civil id is required")
          },
          "spouse.nationality": {
            required: () => this.$t("Spouse nationality is required")
          },
          email: {
            required: () => this.$t("The email field is required")
          }
        }
      };
    },
    translations() {
      return {
        countrySelectorLabel: this.$t("Country code"),
        countrySelectorError: "Country Code Not Found",
        phoneNumberLabel: this.$t("Phone number"),
        example: "Example :"
      };
    },
    tenantTypes() {
      const tenantTypes = [
        {
          label: this.$t("Residential"),
          value: "RESIDENTIAL",
          permission: "tenants.add_residentialtenant"
        },
        {
          label: this.$t("Limited Liability Company"),
          value: "LIMITED_LIABILITY_COMPANY",
          permission: "tenants.add_limitedliabilitycompanytenant"
        },
        {
          label: this.$t("Listed Company"),
          value: "LISTED_COMPANY",
          permission: "tenants.add_listedcompanytenant"
        },
      ];
      return tenantTypes.filter((type) => this.hasPermission(type.permission));
    },
    ...mapGetters("config", {
      defaultCountryCode: "getDefaultPhoneCountryCode",
      phoneInputPreferredCountries: "getPhoneInputPreferredCountries"
    })
  },
  watch: {
    isMarried() {
      if (!this.isMarried) {
        this.tenant.spouse_info = {};
      }
    },
    "$i18n.locale": function () {
      this.$validator.localize(this.$i18n.locale, this.dictionary);
    }
  },
  methods: {
    checkCompanySelected(conditionalCompany) {
      if (this.tenant.type === conditionalCompany) return true;
      return false;
    },
    upload() {
      this.$refs.myUpload.click();
    },
    getImageName(imageUrl) {
      if (imageUrl) {
        let url = new URL(imageUrl);

        // eslint-disable-next-line no-useless-escape
        url = url.pathname.replace(/^.*(\\|\/|\:)/, "");
        return url;
      }

      return "";
    },
    async saveTenantDetails() {
      try {
        if (!this.tenant.phone) {
          this.isTouched = true;
        } else {
          this.isTouched = false;
        }

        if (!this.tenant.spouse_phone) {
          this.spousePhone = true;
        } else {
          this.spousePhone = false;
        }

        if ((await this.$validator.validateAll()) && this.isValid) {
          this.isLoading = true;
          const formData = this.transformRequest(this.tenant);
          if (this.isEdit) {
            await this.$processReq(
              tenantService.updateTenant(formData, this.tenant.id),
              true
            );
          } else {
            if (this.checkCompanySelected("RESIDENTIAL")) {
              await this.$processReq(
                tenantService.saveResidentialTenant(formData),
                true
              );
            } else if (this.checkCompanySelected("LIMITED_LIABILITY_COMPANY")) {
              await this.$processReq(
                tenantService.saveLimitedLiabiltyCompanyTenant(formData),
                true
              );
            } else if (this.checkCompanySelected("LISTED_COMPANY")) {
              await this.$processReq(
                tenantService.saveListedCompanyTenant(formData),
                true
              );
            }
          }

          this.$router.push({ name: "tenant_management" });
          this.isLoading = false;
        }
      } catch (err) {
        this.isLoading = false;
        console.log("Error: Unable to update and save tenant - ", err);
      }
    },
    async fetchTenatDetails(id) {
      try {
        this.isLoading = true;
        const response = await this.$processReq(
          tenantService.fetchTenantById(id),
          false
        );

        let res = cloneDeep(response.data.body);
        if (res.spouse_info) {
          this.isMarried = true;
          res = {
            ...res,
            spouse_phone: res.spouse_info.phone_number
          };
        } else {
          res.spouse_info = {};
        }

        res = {
          ...res,
          phone: res.tenant_info.phone_number
        };
        this.tenant = res;
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
        console.log("Error: Unable to fetch tenant by ID - ", err);
      }
    },
    async uploadImage(e, name, isTenant) {
      try {
        this.isUploadingFile = true;
        const file = e.target.files[0];

        const formData = new FormData();
        formData.append("tenant_image", file);

        const response = await this.$processReq(
          tenantService.uploadImages(formData),
          false
        );
        this.$root.$emit("snackbar", {
          text: this.$t("File Uploaded Successfully"),
          color: "success"
        });
        this.isUploadingFile = false;

        const tenant = cloneDeep(this.tenant);

        if (isTenant) {
          tenant.tenant_info = {
            ...tenant.tenant_info,
            [name]: response.data.body
          };
        } else {
          tenant.spouse_info = {
            ...tenant.spouse_info,
            [name]: response.data.body
          };
        }

        this.tenant = tenant;
      } catch (err) {
        this.isUploadingFile = false;
        this.$root.$emit("snackbar", {
          text: this.$t("Something went wrong while uploading file"),
          color: "error"
        });
        console.log("Error: Unable to Upload image - ", err);
      }
    },
    updateCountryCode(event, isTenant) {
      if (isTenant) {
        const tenant_info = this.tenant.tenant_info;

        tenant_info.country_code = event.countryCode;
        tenant_info.phone_number = event.formattedNumber;

        this.tenant = {
          ...this.tenant,
          tenant_info
        };
        this.isValid = event.isValid;
      } else {
        const spouse_info = this.tenant.spouse_info;

        spouse_info.country_code = event.countryCode;
        spouse_info.phone_number = event.formattedNumber;

        this.tenant = {
          ...this.tenant,
          spouse_info
        };
        this.tenant.spouse_info.country_code = event.countryCode;
        if (event.formattedNumber) {
          this.isValidSpouse = event.isValid;
        } else {
          this.isValidSpouse = true;
        }
      }
    },
    transformRequest(tenant) {
      const formData = new FormData();

      formData.append("type", tenant.type);
      formData.append("tenant_name", tenant.tenant_name_en);
      formData.append("tenant_name_ar", tenant.tenant_name_ar);
      formData.append("tenant_name_en", tenant.tenant_name_en);
      formData.append("preferred_language", tenant.preferred_language);

      if (this.checkCompanySelected("RESIDENTIAL")) {
        formData.append(
          "tenant_civil_id_number",
          tenant.tenant_info.civil_id_number
        );
        formData.append("is_married", this.isMarried);
        if (tenant.tenant_info.civil_id_front) {
          formData.append(
            "tenant_civil_id_front",
            tenant.tenant_info.civil_id_front.id
          );
        }
        if (tenant.tenant_info.civil_id_back) {
          formData.append(
            "tenant_civil_id_back",
            tenant.tenant_info.civil_id_back.id
          );
        }
        formData.append(
          "tenant_passport",
          tenant.tenant_info.passport ? tenant.tenant_info.passport.id : ""
        );
        formData.append(
          "tenant_passport_number",
          tenant.tenant_info.passport_number || ""
        );
        formData.append("tenant_nationality", tenant.tenant_info.nationality);
        if (tenant.tenant_info.phone_number) {
          formData.append(
            "tenant_country_code",
            tenant.tenant_info.country_code
          );
          formData.append(
            "tenant_phone_number",
            tenant.tenant_info.phone_number
          );
        }
        formData.append("tenant_email", tenant.tenant_info.email || "");
      }
      if (
        this.checkCompanySelected("LISTED_COMPANY") &&
        tenant.tenant_info.authorized_signatory_confirmation
      ) {
        formData.append(
          "authorized_signatory_confirmation",
          tenant.tenant_info.authorized_signatory_confirmation.id
        );
      }
      if (
        this.checkCompanySelected("LIMITED_LIABILITY_COMPANY") ||
        this.checkCompanySelected("LISTED_COMPANY")
      ) {
        const tenant_civil_id_value = tenant.tenant_info.civil_id_number ?? "";
        formData.append("civil_id_number", tenant_civil_id_value);
        if (tenant.tenant_info.phone_number) {
          formData.append("country_code", tenant.tenant_info.country_code);
          formData.append("phone_number", tenant.tenant_info.phone_number);
        }
        formData.append("email", tenant.tenant_info.email || "");
        formData.append(
          "commercial_registration_number",
          tenant.tenant_info.commercial_registration_number
        );
        if (tenant.tenant_info.commercial_registration) {
          formData.append(
            "commercial_registration",
            tenant.tenant_info.commercial_registration.id
          );
        }
        if (tenant.tenant_info.trade_licence)
          formData.append("trade_licence", tenant.tenant_info.trade_licence.id);
        if (tenant.tenant_info.authorized_signatory_name)
          formData.append(
            "authorized_signatory_name",
            tenant.tenant_info.authorized_signatory_name.id
          );
        if (tenant.tenant_info.attachment1) {
          formData.append("attachment1", tenant.tenant_info.attachment1.id);
        }
        if (tenant.tenant_info.attachment2) {
          formData.append("attachment2", tenant.tenant_info.attachment2.id);
        }
        if (tenant.tenant_info.attachment3) {
          formData.append("attachment3", tenant.tenant_info.attachment3.id);
        }
      }

      if (!isEmpty(tenant.spouse_info)) {
        formData.append(
          "spouse_civil_id_number",
          tenant.spouse_info.civil_id_number
        );
        if (tenant.spouse_info.civil_id_front) {
          formData.append(
            "spouse_civil_id_front",
            tenant.spouse_info.civil_id_front.id
          );
        }
        if (tenant.spouse_info.marriage_certificate) {
          formData.append(
            "spouse_marriage_certificate",
            tenant.spouse_info.marriage_certificate.id
          );
        }
        if (tenant.spouse_info.civil_id_back) {
          formData.append(
            "spouse_civil_id_back",
            tenant.spouse_info.civil_id_back.id
          );
        }
        formData.append(
          "spouse_passport",
          tenant.spouse_info.passport ? tenant.spouse_info.passport.id : ""
        );
        formData.append(
          "spouse_passport_number",
          tenant.spouse_info.passport_number || ""
        );
        formData.append("spouse_nationality", tenant.spouse_info.nationality);
        if (tenant.spouse_info.phone_number) {
          formData.append(
            "spouse_country_code",
            tenant.spouse_info.country_code
          );
          formData.append(
            "spouse_phone_number",
            tenant.spouse_info.phone_number
          );
        }
        formData.append("spouse_email", tenant.spouse_info.email || "");
      }

      return formData;
    },
    async downloadImage(image) {
      try {
        this.isLoading = true;
        var link = document.createElement("a");

        const resp = await tenant.downloadImage(image.id);
        var blob = new Blob([resp.data], { type: "image/*" });
        link.href = window.URL.createObjectURL(blob);
        link.target = "_blank";

        // eslint-disable-next-line no-useless-escape
        const attachementUrl = image.tenant_image.replace(/^.*(\\|\/|\:)/, "");

        link.download = attachementUrl;

        if (attachementUrl.split("?")?.length > 0) {
          link.download = attachementUrl.split("?")[0];
        }

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log("Error: Unable to download image - ", error);
      }
    },
    async fetchCountriesList() {
      try {
        this.isLoadingCountries = true;
        const response = await this.$processReq(
          tenantService.getCountriesList(),
          false
        );
        this.countries = response.data.body;
        this.isLoadingCountries = false;
      } catch (error) {
        this.isLoadingCountries = false;
        console.log("Error: Unable to load coutries list - ", error);
      }
    }
  }
};
</script>

<style scoped>
.phone-input-error-message {
  color: #ff4500;
  font-size: 12px;
}
.phone-error {
  min-height: 21px;
}
.vue-phone-number-input {
  direction: ltr;
}
div >>> .v-text-field__details {
  padding-bottom: 5px;
}
a {
  color: white;
}
</style>
