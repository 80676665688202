<template>
  <v-toolbar class="re-toolbar re-wrap">
    <div class="re-flex">
      {{ $t("Rows Per Page") }}
      <v-radio-group v-model="row" class="re-radios" @change="changeRow">
        <v-radio :label="$t('All')" value="show-all"></v-radio>
      </v-radio-group>
    </div>
    <v-spacer></v-spacer>
    <v-text-field
      hide-details
      :placeholder="$t('Search')"
      single-line
      v-model="search.search"
      @input="inputSearch"
    />
    <v-spacer></v-spacer>
    <v-btn
      v-if="hasPermission('payments.notify_unpaid_payments')"
      dark
      class="re-fill"
      @click="showDialog"
    >
      {{ $t("Notify Payments") }}
    </v-btn>
    <v-btn
      v-if="hasPermission('reports.create_rent_details_report_pdf')"
      dark
      class="re-fill"
      @click="printRent"
    >
      <img src="@/assets/printer.svg" />
      {{ $t("Print") }}
    </v-btn>
    <!-- <v-btn dark class="re-full" @click="exportRent">{{
      $t("Export Rent Details")
    }}</v-btn> -->
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-x
      class="re-filter"
    >
      <template v-slot:activator="{ on }">
        <v-btn dark class="re-orangtbtn re-full" v-on="on">
          {{ $t("Filter") }} <v-icon right>filter_list</v-icon>
        </v-btn>
      </template>

      <v-card class="pb-4">
        <v-list>
          <v-list-tile avatar>
            <v-list-tile-content>
              <v-list-tile-title>{{ $t("Filter") }}</v-list-tile-title>
            </v-list-tile-content>

            <v-list-tile-action>
              <v-btn icon @click="menu = false">
                <v-icon> close </v-icon>
              </v-btn>
            </v-list-tile-action>
          </v-list-tile>
        </v-list>

        <v-divider></v-divider>

        <v-list>
          <v-list-tile>
            <v-select
              v-model="isDateFilterSetToMonthAndYear"
              item-text="label"
              item-value="value"
              :label="$t('Date Filter Type')"
              :items="dateFilterTypes"
            />
          </v-list-tile>

          <v-list-tile v-show="isDateFilterSetToMonthAndYear">
            <v-select
              v-model="search.payment_month"
              :label="$t('Month')"
              :items="months"
            />
          </v-list-tile>

          <v-list-tile v-show="isDateFilterSetToMonthAndYear">
            <v-text-field :label="$t('Year')" v-model="search.payment_year" />
          </v-list-tile>

          <v-list-tile v-if="!isDateFilterSetToMonthAndYear">
            <date-picker
              v-model="search.payment_due_date_after"
              :changed="search.payment_due_date_after"
              classes="filter-width"
              :label="$t('Due Date From')"
            />
          </v-list-tile>

          <v-list-tile v-if="!isDateFilterSetToMonthAndYear">
            <date-picker
              v-model="search.payment_due_date_before"
              :changed="search.payment_due_date_before"
              classes="filter-width"
              :label="$t('Due Date To')"
            />
          </v-list-tile>

          <v-list-tile>
            <date-picker
              v-model="search.payment_date_after"
              :changed="search.payment_date_after"
              classes="filter-width"
              :label="$t('Payment Date From')"
            />
          </v-list-tile>

          <v-list-tile>
            <date-picker
              v-model="search.payment_date_before"
              :changed="search.payment_date_before"
              classes="filter-width"
              :label="$t('Payment Date To')"
            />
          </v-list-tile>

          <v-list-tile>
            <v-autocomplete
              v-model="search.property"
              :label="$t('Property name')"
              item-text="property_name"
              item-value="id"
              :items="properties"
              :no-data-text="`${
                isLoadingProperties ? $t('Loading...') : $t('No data available')
              }`"
              @input.native="fetchProperties"
            />
          </v-list-tile>
        </v-list>

        <v-list>
          <v-list-tile>
            <v-select
              v-model="search.status"
              item-text="label"
              item-value="value"
              :label="$t('Status')"
              :items="statuses"
            />
          </v-list-tile>
          <v-list-tile>
            <v-autocomplete
              v-model="search.report_profile"
              :label="$t('Profile')"
              clearable
              item-text="profile_name"
              item-value="id"
              :items="profiles"
              :no-data-text="`${
                isLoadingProfiles ? $t('Loading...') : $t('No data available')
              }`"
              @input.native="fetchProfiles"
            />
          </v-list-tile>
          <v-list-tile>
            <v-autocomplete
              v-model="search.property_class"
              :label="$t('Property Classification')"
              clearable
              item-text="class_name"
              item-value="id"
              :items="propertyClassifications"
              :no-data-text="`${
                isLoadingClassifications
                  ? $t('Loading...')
                  : $t('No data available')
              }`"
              @input.native="fetchPropertyClassifications"
            />
          </v-list-tile>
          <v-list-tile>
            <v-autocomplete
              v-model="search.contract__tenant__id"
              :label="$t('Tenant')"
              clearable
              item-text="tenant_name"
              item-value="id"
              :items="tenants"
              :no-data-text="`${
                isLoadingTenantsOptions
                  ? $t('Loading...')
                  : $t('No data available')
              }`"
              @input.native="fetchTenants"
            />
          </v-list-tile>
        </v-list>
        <v-list>
          <v-list-tile>
            <v-autocomplete
              v-model="search.contract__id"
              :label="$t('Contract')"
              clearable
              item-text="id"
              item-value="id"
              :no-data-text="`${
                isLoadingContractsOptions
                  ? $t('Loading...')
                  : $t('No data available')
              }`"
              :items="contracts"
              @input.native="fetchContracts"
            >
              <template v-slot:selection="data">
                <span>
                  {{ $t("Contract ID") }}: {{ data.item.id }},
                  {{ $t("from date") }}:
                  {{ defaultFormatted(data.item.contract_start_date) }}
                  {{ $t("to date") }}:
                  {{ defaultFormatted(data.item.contract_end_date) }}
                </span>
              </template>

              <template v-slot:item="data">
                <span>
                  {{ $t("Contract ID") }}: {{ data.item.id }},
                  {{ $t("from date") }}:
                  {{ defaultFormatted(data.item.contract_start_date) }}
                  {{ $t("to date") }}:
                  {{ defaultFormatted(data.item.contract_end_date) }}
                </span>
              </template>
            </v-autocomplete>
          </v-list-tile>
        </v-list>
        <v-list>
          <v-list-tile>
            <v-select
              v-model="search.is_manual_payment"
              :label="$t('Payment Type')"
              item-text="label"
              item-value="value"
              :items="isManualFilterOptions"
            />
          </v-list-tile>
        </v-list>
        <v-list>
          <v-list-tile>
            <v-checkbox
              v-model="search.is_suspended"
              :label="$t('Is Suspended')"
              :false-value="null"
              true-value="True"
            />
          </v-list-tile>
        </v-list>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn dark class="re-orangtbtn" @click="searchClick">
            {{ $t("Search") }}
          </v-btn>
          <v-btn flat @click="resetClick" class="re-gray-btn re-box-shadow">{{
            $t("Reset")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </v-toolbar>
</template>

<script>
import { isEmpty, debounce } from "lodash";
import moment from "moment";

import DatePicker from "../../components/picker/DatePicker.vue";
import { defaultFormat } from "../../helper";
import { getMonthLabels } from "../../lib/date_time";
import contractService from "../../services/plugins/contract";
import profileService from "../../services/plugins/profile";
import propertiesService from "../../services/plugins/properties";
import propertyClassificationService from "../../services/plugins/property_classification";
import tenantService from "../../services/plugins/tenant";

import checkUserPermission from "@/mixins/checkUserPermission";

export default {
  props: [
    "limit",
    "page",
    "totalCount",
    "payment",
    "printRent",
    "exportRent",
    "showDialog"
  ],
  mixins: [checkUserPermission],
  components: {
    DatePicker
  },
  data: () => ({
    row: "",
    search: {},
    menu: false,
    months: getMonthLabels(),
    properties: "",
    propertyClassifications: [],
    profiles: [],
    tenants: [],
    contracts: [],

    isLoadingProperties: false,
    isLoadingProfiles: false,
    isLoadingClassifications: false,
    isDateFilterSetToMonthAndYear: true,
    isLoadingTenantsOptions: false,
    isLoadingContractsOptions: false,

    fetchProperties: null,
    fetchPropertyClassifications: null,
    fetchProfiles: null,
    fetchTenants: null,
    fetchContracts: null
  }),
  created() {
    this.fetchProperties = debounce(this.loadProperties, 500);
    this.fetchPropertyClassifications = debounce(
      this.loadPropertyClassifications,
      500
    );
    this.fetchProfiles = debounce(this.loadProfiles, 500);
    this.fetchTenants = debounce(this.loadTenants, 500);
    this.fetchContracts = debounce(this.loadContracts, 500);
    this.fetchProperties();
    this.fetchPropertyClassifications();
    this.fetchProfiles();
    this.fetchTenants();
    this.fetchContracts();
  },
  watch: {
    payment() {
      if (this.payment && this.payment.last_payment_month) {
        this.search.payment_month = moment()
          .month(this.payment.last_payment_month)
          .format("MMM");
        this.search.payment_year = this.payment.last_payment_year;
      }
    }
  },
  computed: {
    statuses() {
      return [
        { label: this.$t("All"), value: null },
        { label: this.$t("Paid"), value: "Paid" },
        { label: this.$t("Pending"), value: "Pending,Expired" }
      ];
    },
    dateFilterTypes() {
      return [
        { label: this.$t("Month & Year"), value: true },
        {
          label: this.$t("Due date from To date"),
          value: false
        }
      ];
    },
    isManualFilterOptions() {
      return [
        {
          label: this.$t("Manual"),
          value: true
        },
        {
          label: this.$t("Link"),
          value: false
        },
        {
          label: this.$t("Both manual and link"),
          value: ""
        }
      ];
    }
  },
  methods: {
    changeRow() {
      this.$emit("changeRow", this.row);
    },
    inputSearch() {
      const params = this.checkAndGetFilters();
      if (params) {
        this.$emit("search", params);
      }
    },
    resetClick() {
      this.search = {};
      this.isDateFilterSetToMonthAndYear = true;
      this.$emit("loadSearch", this.search);
      this.menu = false;
    },
    searchClick() {
      const params = this.checkAndGetFilters();
      if (params) {
        this.$emit("loadSearch", params);
        this.menu = false;
      }
    },
    async loadProperties(event) {
      try {
        this.isLoadingProperties = true;
        let params = {
          limit: 10,
          offset: 0
        };
        if (event) {
          params = {
            ...params,
            search: event.target.value
          };
        } else {
          params.search = "";
        }

        const response = await this.$processReq(
          propertiesService.getPropertiesList(params),
          false
        );

        this.properties = response.data.body.results;
        this.isLoadingProperties = false;
      } catch (err) {
        this.isLoadingProperties = false;
        console.log("Error: Unable to get properties list - ", err);
      }
    },
    async loadPropertyClassifications(event) {
      try {
        this.isLoadingClassifications = true;

        let params = {
          limit: 10,
          offset: 0
        };

        if (event) {
          params = {
            ...params,
            search: event.target.value || ""
          };
        }
        const resp = await this.$processReq(
          propertyClassificationService.getPropertyClassificationList(params),
          false
        );

        this.propertyClassifications = resp.data.body.results;
        this.isLoadingClassifications = false;
      } catch (err) {
        this.isLoadingClassifications = false;
        console.log(
          "Error: Unable to get property classifications list - ",
          err
        );
      }
    },
    async loadProfiles(event) {
      try {
        this.isLoadingProfiles = true;

        let params = {
          limit: 10,
          offset: 0
        };

        if (event) {
          params = {
            ...params,
            search: event.target.value || ""
          };
        }
        const resp = await this.$processReq(
          profileService.getProfileList(params),
          false
        );

        this.profiles = resp.data.body.results;
        this.isLoadingProfiles = false;
      } catch (err) {
        this.isLoadingProfiles = false;
        console.log(
          "Error: Unable to get property classifications list - ",
          err
        );
      }
    },
    async loadTenants(event) {
      try {
        this.isLoadingTenantsOptions = true;
        let params = {
          limit: 10,
          offset: 0
        };

        if (event) {
          params = {
            ...params,
            search: event.target.value || ""
          };
        }

        const resp = await this.$processReq(
          tenantService.getTenantsList(params),
          false
        );

        this.tenants = resp.data.body.results;
        this.isLoadingTenantsOptions = false;
      } catch (err) {
        this.isLoadingTenantsOptions = false;
        console.log("Error: Unable to get tenant list - ", err);
      }
    },
    async loadContracts(event) {
      try {
        this.isLoadingContractsOptions = true;
        let params = {
          limit: 10,
          offset: 0
        };

        if (event) {
          params = {
            ...params,
            id: event.target.value || ""
          };
        }
        const res = await this.$processReq(
          contractService.fetchContractListMiniDetails(params),
          false
        );

        this.contracts = res.data.body.results;
        this.isLoadingContractsOptions = false;
      } catch (err) {
        this.isLoadingContractsOptions = false;
        console.log("Error: Unable to fetch contracts - ", err);
      }
    },
    checkAndGetFilters() {
      let flag = false;

      if (isEmpty(this.search)) {
        flag = true;
      }

      if (
        this.isDateFilterSetToMonthAndYear &&
        !(this.search.payment_month && this.search.payment_year)
      ) {
        flag = true;
      }

      if (
        !this.isDateFilterSetToMonthAndYear &&
        !(
          this.search.payment_due_date_after ||
          this.search.payment_due_date_before
        )
      ) {
        flag = true;
      }

      if (flag) {
        let messageText = "";
        if (this.isDateFilterSetToMonthAndYear) {
          messageText = this.$t(
            "Kindly, select the month and year of the payment list"
          );
        } else {
          messageText = this.$t(
            "Kindly, select at least Due date from or Due date to of the payment list filters"
          );
        }

        this.$root.$emit("snackbar", {
          text: messageText,
          color: "error"
        });
        return null;
      }

      const { payment_month, payment_year, ...rest } = { ...this.search };
      let searchParams = {};
      if (this.isDateFilterSetToMonthAndYear) {
        const month = moment().month(payment_month).format("M");
        /* It's crucial to use the second constructor of 'moment' to make sure it's
         ** compatible on all browsers, especially on Safari.
         */
        const paymentFrom = moment(
          `${payment_year}-${month}-01`,
          "YYYY-MM-DD"
        ).format("YYYY-MM-DD"); //start of month
        const paymentTo = moment(`${payment_year}-${month}-01`, "YYYY-MM-DD")
          .endOf("month")
          .format("YYYY-MM-DD"); //end of month
        searchParams = {
          ...rest,
          payment_due_date_after: paymentFrom,
          payment_due_date_before: paymentTo
        };
      } else {
        searchParams = {
          ...rest
        };
      }
      if (searchParams.is_manual_payment === "") {
        delete searchParams.is_manual_payment;
      }
      return searchParams;
    },
    defaultFormatted(value) {
      return defaultFormat(value);
    }
  }
};
</script>

<style scoped>
img {
  margin-right: 6px;
  padding-left: 6px;
  filter: brightness(0) invert(1);
}
</style>
